<template>
  <div class="bl_area capture_area">
    <form
      id="frm_sr"
      @submit.prevent
    >
      <div class="content_box beforetab">
        <table class="tbl_search">
          <colgroup>
            <col style="width:120px">
            <col>
            <col style="width:120px">
            <col style="width:200px">
          </colgroup>
          <tbody>
            <tr>
              <th>S/R No.</th>
              <td>
                <select
                  v-model="regParams.srNo"
                  class="wid200"
                  @change="changeSrNo($event)"
                >
                  <option v-for="(vo, idx) in regParams.srList" :key="'srNo_' + idx" :value="vo.srNo">{{ vo.srNo }}</option>
                </select>
                <a class="button sm ml2" @click="fnCommonBtnAction('LOG')">Log {{ $t('btn.CMBTK011') }}</a>
                <a v-if="usrCtrCd !== 'CN'" :class="regParams.cnclYn === 'Y'?'disabled button sm ml2':'button sm ml2'" @click="fnCommonBtnAction('PBL')">Part S/R+</a>
                <a v-if="usrCtrCd !== 'CN'" :class="regParams.cnclYn === 'Y'?'disabled button sm ml2':'button sm ml2'" @click="fnCommonBtnAction('NBL')">Normal S/R+</a>
              </td>
              <th>Booking No.</th>
              <td>
                <span class="span_bkg" v-if="regParams.addBkgNoList.length === 0">{{ detailBkgNo }}</span>
                <span v-if="regParams.addBkgNoList.length > 0" class="position_relative span_tooltip span_bkg">
                  <a class="txt_underline" @click="$ekmtcCommon.showTooltip($event)">{{ detailBkgNo }} <span v-if="regParams.addBkgNoList.length > 0">외 {{ regParams.addBkgNoList.length }}건</span></a>
                  <div class="tooltip_wrap short position_absolute"><!-- tooltip_wrap   -->
                    <div class="cont"><!-- cont -->
                      <button
                        type="button"
                        class="close"
                        @click="$ekmtcCommon.hideTooltip($event)"
                      >close</button>
                      <ul class="bul_list_sm t2">
                        <li>
                          <p v-for="(vo, idx) in regParams.addBkgNoList" :key="'bkgNoList_' + idx">{{ vo }}</p>
                        </li>
                      </ul>
                    </div><!-- cont -->
                  </div><!-- tooltip_wrap // -->
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="content_box mt10">
        <div class="booking_call_wrap">
          <!-- 불러오기 -->
          <div class="call_search">
            <input v-show="vueMode"
                   type="radio"
                   id="templateCd_1"
                   v-model="templateCd"
                   value="A"
            />
            <label for="templateCd_1">{{ $t("msg.CSBK100.505") }}</label>
            <!-- 불러오기 -->
            <input
              v-model="templateNo"
              type="text"
              placeholder="S/R No."
              @keypress.enter="templateEnter"
              @input="templateNo = templateNo.toUpperCase()"
              @click="templateCd ='A'"
            />
            <button
              type="button"
              class="search_btn01"
              @click.prevent="templateSearch()"
              @click="templateCd ='A'"
            >
              <span class="offscreen">{{ $t("msg.CSBK100.121") }}</span>
              <!-- 부킹템플릿 불러오기 -->
            </button>
            <input v-show="vueMode"
                   type="radio"
                   id="templateCd_2"
                   v-model="templateCd"
                   value="B"
            />
            <label v-show="vueMode" for="templateCd_2" style="margin-left:20px;">Template</label>
            <select v-show="vueMode"
                    id="templateList"
                    style="width: 200px; margin-left:20px;"
                    v-model="templateNo_2"
                    @click="templateCd ='B'"
            >
              <option value="">
                {{ this.$t("msg.CSBL200.078") }}
              </option>
              <option
                v-for="vo in templateList"
                :key="vo.tplRno"
                :value="vo.tplRno"
              >
                {{ vo.tplNm }}
              </option>
            </select>
            <a class="button blue sh ml15" @click.prevent="setTemplateInfo()">{{
              $t("btn.CMBTK010")
            }}</a>
            <a v-show="vueMode" class="button blue sh" style="margin-left:5px;" @click.prevent="deleteTemplate()">{{
              $t("art.CMBA660.007")
            }}</a>
            <!-- 삭제 -->
          </div>
        </div>
        <!-- 적용 -->
      </div>

      <div class="content_box mt10">
        <h2 class="content_title">{{ $t('msg.CSBK100.084') }}</h2><!-- 부킹/서류 담당자 -->
        <table class="tbl_col">
          <colgroup>
            <col style="width:25%">
            <col style="width:25%">
            <col style="width:25%">
            <col style="width:25%">
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('msg.CSBK100.129') }}</th> <!-- 담당자 -->
              <th>{{ $t('msg.CSBK100.148') }}</th> <!-- 전화번호 -->
              <th>FAX</th>
              <th>{{ $t('msg.CSBK100.149') }}</th> <!-- 휴대전화번호 -->
            </tr>
            <tr>
              <td>
                <input
                  id="bizPicNm"
                  v-model="regParams.bizPicNm"
                  type="text"
                  maxlength="30"
                >
              </td>
              <td>
                <div class="tbl_form">
                  <span class="dv col_3">
                    <e-input-number
                      id="bizPicTelPlcNo"
                      v-model="regParams.bizPicTelPlcNo"
                      :is-phone="true"
                      maxlength="4"
                      :disabled="userHkSg === 'Y'"
                    >
                    </e-input-number>
                  </span>
                  <span class="dv col_7">
                    <e-input-number
                      id="bizPicTelOfcNo"
                      v-model="regParams.bizPicTelOfcNo"
                      :is-phone="true"
                      maxlength="12"
                    >
                    </e-input-number>
                  </span>
                </div>
              </td>
              <td>
                <div class="tbl_form">
                  <span class="dv col_3">
                    <e-input-number
                      id="bizPicFaxPlcNo"
                      v-model="regParams.bizPicFaxPlcNo"
                      :is-phone="true"
                      maxlength="4"
                      :disabled="userHkSg === 'Y'"
                    >
                    </e-input-number>
                  </span>
                  <span class="dv col_7">
                    <e-input-number
                      id="bizPicFaxOfcNo"
                      v-model="regParams.bizPicFaxOfcNo"
                      :is-phone="true"
                      maxlength="12"
                    >
                    </e-input-number>
                  </span>
                </div>
              </td>
              <td>
                <div class="tbl_form">
                  <span class="dv col_3">
                    <e-input-number
                      id="bizPicMbpBsnNo"
                      v-model="regParams.bizPicMbpBsnNo"
                      :is-phone="true"
                      maxlength="4"
                      :disabled="userHkSg === 'Y'"
                    >
                    </e-input-number>
                  </span>
                  <span class="dv col_7">
                    <e-input-number
                      id="bizPicMbpOfcNo"
                      v-model="regParams.bizPicMbpOfcNo"
                      :is-phone="true"
                      maxlength="12"
                    >
                    </e-input-number>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <th>email</th>
              <td colspan="3">
                <input
                  id="bizPicEmlAddr"
                  v-model="regParams.bizPicEmlAddr"
                  type="text"
                  maxlength="100"
                  :placeholder="$t('msg.CSBK100.152')"
                >
              </td>
            </tr>
          </tbody>
        </table>
        <p class="txt_desc">{{ $t('msg.CSBK100.150') }}</p>

        <div class="flex_box mt20">
          <h2 class="content_title">{{ $t('msg.CSBL200.182') }}</h2> <!-- Shipping Information -->
        </div>

        <booking-schedule-info
          ref="schdInfo"
          :bkg-no="regParams.bkgNo"
          save-flag="PICKUP"
        />

        <table class="tbl_row mt20 tbl_btm_none">
          <colgroup>
            <col style="width:170px">
            <col>
            <col style="width:170px">
            <col>
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('msg.CSBL200.183') }}</th><!-- Place of Receipt -->
              <td>
                <div class="tbl_form div_plc">
                  <span class="dv col_3">
                    <select
                      v-model="porPlcNmType"
                      @change="changeInputType($event, 'porPlcNm')"
                    >
                      <option value="select">Select</option>
                      <option value="input">Input</option>
                    </select>
                  </span>
                  <span class="dv col_7 span_plc">
                    <select
                      v-if="porPlcNmType === 'select'"
                      id="porPlcNm"
                      v-model="regParams.porPlcNm"
                      @change="checkValidationInfo('porPlcNm')"
                    >
                      <option v-for="(vo, idx) in plcEnmCodes[regParams.porPlcCd]" :key="'porPlcNm_' + idx" :value="vo.plcEnm">{{ vo.plcEnm }}</option>
                    </select>
                    <e-trans-string-check-cnee-input
                      v-if="porPlcNmType === 'input'"
                      :id="'porPlcNm'"
                      v-model="regParams.porPlcNm"
                      :is-upper-case="true"
                      :is-required="true"
                      :message-required="$t('msg.CSBK100.147')"
                      maxlength="50"
                      :is-jp-pop-msg="true"
                      @blur="checkJPplcPopMsg"
                    />
                  </span>
                </div>
              </td>
              <th>{{ $t('msg.CSBL200.184') }}</th> <!-- Port of Loading -->
              <td>
                <div class="tbl_form div_plc">
                  <span class="dv col_3">
                    <select
                      v-model="polPortNmType"
                      @change="changeInputType($event, 'polPortNm')"
                    >
                      <option value="select">Select</option>
                      <option value="input">Input</option>
                    </select>
                  </span>
                  <span class="dv col_7 span_plc">
                    <select
                      v-if="polPortNmType === 'select'"
                      id="polPortNm"
                      v-model="regParams.polPortNm"
                      @change="checkValidationInfo('polPortNm')"
                    >
                      <option v-for="(vo, idx) in plcEnmCodes[regParams.polPortCd]" :key="'polPortNm_' + idx" :value="vo.plcEnm">{{ vo.plcEnm }}</option>
                    </select>
                    <e-trans-string-check-cnee-input
                      v-if="polPortNmType === 'input'"
                      :id="'polPortNm'"
                      v-model="regParams.polPortNm"
                      :is-upper-case="true"
                      :is-required="true"
                      :message-required="$t('msg.CSBK100.147')"
                      maxlength="50"
                      :is-jp-pop-msg="true"
                      @blur="checkJPplcPopMsg"
                    />
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <th>{{ $t('msg.CSBL200.185') }}</th> <!-- Port of Discharge -->
              <td>
                <div class="tbl_form div_plc">
                  <span class="dv col_3">
                    <select
                      v-model="podPortNmType"
                      @change="changeInputType($event, 'podPortNm')"
                    >
                      <option value="select">Select</option>
                      <option value="input">Input</option>
                    </select>
                  </span>
                  <span class="dv col_7 span_plc">
                    <select
                      v-if="podPortNmType === 'select'"
                      id="podPortNm"
                      v-model="regParams.podPortNm"
                      @change="checkValidationInfo('podPortNm')"
                    >
                      <option v-for="(vo, idx) in plcEnmCodes[regParams.podPortCd]" :key="'podPortNm_' + idx" :value="vo.plcEnm">{{ vo.plcEnm }}</option>
                    </select>
                    <e-trans-string-check-cnee-input
                      v-if="podPortNmType === 'input'"
                      :id="'podPortNm'"
                      v-model="regParams.podPortNm"
                      :is-upper-case="true"
                      :is-required="true"
                      :message-required="$t('msg.CSBK100.147')"
                      maxlength="50"
                      :is-jp-pop-msg="true"
                      @blur="checkJPplcPopMsg"
                    />
                  </span>
                </div>
              </td>
              <th>{{ $t('msg.CSBL200.186') }}</th> <!-- Place of Delivery -->
              <td>
                <div class="tbl_form div_plc">
                  <span class="dv col_3">
                    <select
                      v-model="dlyPlcNmType"
                      @change="changeInputType($event, 'dlyPlcNm')"
                    >
                      <option value="select">Select</option>
                      <option value="input">Input</option>
                    </select>
                  </span>
                  <span class="dv col_7 span_plc">
                    <select
                      v-if="dlyPlcNmType === 'select'"
                      id="dlyPlcNm"
                      v-model="regParams.dlyPlcNm"
                    >
                      <option v-for="(vo, idx) in plcEnmCodes[regParams.dlyPlcCd]" :key="'dlyPlcNm_' + idx" :value="vo.plcEnm">{{ vo.plcEnm }}</option>
                    </select>
                    <e-trans-string-check-cnee-input
                      v-if="dlyPlcNmType === 'input'"
                      :id="'dlyPlcNm'"
                      v-model="regParams.dlyPlcNm"
                      maxlength="50"
                      :is-upper-case="true"
                      :is-required="true"
                      :message-required="$t('msg.CSBK100.147')"
                      :is-jp-pop-msg="true"
                      @blur="checkJPplcPopMsg"
                    />
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <th>{{ $t('msg.CSBK100.006') }}</th>
              <td>
                <input
                  v-model="regParams.obrdDtTemp"
                  class="col_5"
                  type="text"
                  readonly
                >
                <a
                  class="button sm ml2"
                  @click="onBoardDatePop()"
                >{{ $t('btn.CMBTK004') }}</a>
              </td>
              <th>Final destination</th>
              <td>
                <e-trans-string-check-cnee-input
                  :id="'lstDestPlcNm'"
                  v-model="regParams.lstDestPlcNm"
                  :is-upper-case="true"
                  maxlength="50"
                />
              </td>
            </tr>
            <tr>
              <th>{{ $t('msg.CSBL200.228') }}</th>
              <td>
                <select
                  v-model="regParams.polShtmCd"
                  id="polShtmCd"
                  class="col_3"
                  @change="fnChangeTerm()"
                  disabled
                >
                  <option v-for="(vo, idx) in commonCodes['01033']" :key="'polShtmCd_' + idx" :value="vo.cd">{{ vo.cdNm }}</option>
                </select>
                <span class="slash">/</span>
                <select
                  v-model="regParams.podShtmCd"
                  id="podShtmCd"
                  class="col_3"
                  disabled
                >
                  <option v-for="(vo, idx) in commonCodes['01033']" :key="'podShtmCd_' + idx" :value="vo.cd">{{ vo.cdNm }}</option>
                </select>
              </td>
              <!-- <th>{{ $t('msg.CSBL200.226') }}</th>
              <td>
                <select
                  v-model="regParams.blTypCd"
                  id="blTypCd"
                  class="col_5"
                  @change="changeBlTypCd($event)"
                >
                  <option value="06">ORIGINAL B/L</option>
                  <option value="02">SURRENDER B/L</option>
                  <option value="03">{{ $t('msg.ONEX070G040.031') }}</option>
                </select>
              </td> -->
              <th>{{ $t('msg.CSBL200.227') }}</th>
              <td>
                <select
                  v-model="regParams.frtPncCd"
                  class="col_5"
                >
                  <option v-for="(vo, idx) in commonCodes['01032']" :key="'frtPncCd_' + idx" :value="vo.cd">{{ vo.cdNm }}</option>
                </select>
              </td>
            </tr>
            <tr v-if="lang === 'JPN'">
              <th>{{ $t('msg.CSBL200.229') }}</th>
              <td>
                <div class="tbl_form">
                  <span class="dv col_5">
                    <select
                      v-model="regParams.issCtrCd"
                      id="issCtrCd"
                      @change="changeCtrCd($event, 'issPlcList')"
                    >
                      <option value="">{{ $t('msg.CSBK100.062') }}</option>
                      <option v-for="(vo, idx) in ctrCdList" :key="'issCtrCd_' + idx" :value="vo.ctrCd">{{ vo.ctrEnm }}</option>
                    </select>
                  </span>
                  <span class="dv col_5">
                    <select
                      v-model="regParams.issPlcCd"
                      id="issPlcCd"
                    >
                      <option value="">{{ $t('msg.CSBK100.062') }}</option>
                      <option v-for="(vo, idx) in issPlcList" :key="'issPlcCd_' + idx" :value="vo.plcCd">{{ vo.plcEnm }}</option>
                    </select>
                  </span>
                </div>
              </td>
              <th>{{ $t('msg.CSBL200.230') }}</th>
              <td>
                <div class="tbl_form">
                  <span class="dv col_5">
                    <select
                      v-model="regParams.frtPayCtrCd"
                      id="frtPayCtrCd"
                      @change="changeCtrCd($event, 'frtPayPlcList')"
                    >
                      <option value="">{{ $t('msg.CSBK100.062') }}</option>
                      <option v-for="(vo, idx) in ctrCdList" :key="'frtPayCtrCd_' + idx" :value="vo.ctrCd">{{ vo.ctrEnm }}</option>
                    </select>
                  </span>
                  <span class="dv col_5">
                    <select
                      v-model="regParams.frtPayPlcCd"
                      id="frtPayPlcCd"
                    >
                      <option value="">{{ $t('msg.CSBK100.062') }}</option>
                      <option v-for="(vo, idx) in frtPayPlcList" :key="'frtPayPlcCd_' + idx" :value="vo.plcCd">{{ vo.plcEnm }}</option>
                    </select>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td class="td_noline" colspan="2" v-show="!blTypeVisibleContry.includes(auth.userCtrCd)"></td>
              <th class="line_bottom" v-show="blTypeVisibleContry.includes(auth.userCtrCd)">B/L type</th>
              <td class="line_bottom" v-show="blTypeVisibleContry.includes(auth.userCtrCd)">
                <div class="tbl_form">
                  <div v-for="(item, index) in commonCodes['CS105']" :key="index" style="float:left;">
                    <input type="radio" :id="'blTypCd'+index" name="blTypCd" v-model="regParams.blTypCd" :value="item.cd" :disabled="item.cd === '03'">
                    <label :for="'blTypCd'+index" class="mr20">{{ item.cdNm }}</label>
                  </div>
                </div>
              </td>
              <th class="line_bottom">S/C No.</th>
              <td class="line_bottom">{{ regParams.frtAppNo }}</td>
            </tr>
          </tbody>
        </table>

        <h2 class="content_title">{{ $t('msg.CSBL200.231') }}</h2><!-- Shipper/Consignee/Notify Information -->

        <table class="tbl_col">
          <colgroup>
            <col width="36%">
            <col width="14%">
            <col width="14%">
            <col width="14%">
            <col width="22%">
          </colgroup>
          <tbody>
            <tr>
              <th class="text_left">
                {{ $t('msg.NEWB020G010.043') }}
                <select
                  class="wid150 ml20 bg_white"
                  @change="changeLatestInfo($event, 'shpr')"
                >
                  <option value="">{{ $t('msg.CSBL200_M1.019') }}</option>
                  <option v-for="(vo, idx) in cstEnmCodes['SHPR']" :key="'shpr_' + idx" :value="vo.cstNm">{{ vo.cstNm }}</option>
                </select>
              </th>
              <th>{{ $t('msg.CSBL200.188') }}</th> <!-- 지역 -->
              <th>{{ $t('msg.CSBL200.189') }}</th> <!-- City(시/군) -->
              <th>{{ $t('msg.CSBL200.190') }}</th> <!-- State/Province(도) -->
              <th>{{ $t('msg.CSBL200.191') }}</th> <!-- Postal Code(우편번호) -->
            </tr>
            <tr>
              <td>
                <e-trans-address-input :id="'shprCstEnm'"
                                       ref="shprCstEnm"
                                       form-id="frm_sr"
                                       v-model="regParams.shprCstEnm"
                                       :is-required="true"
                                       :is-char="true"
                                       :max-length="50"
                                       :message-required="$t('msg.CSBK100.147')"
                />
              </td>
              <td>
                <select
                  v-model="regParams.actShprCstCtrCd"
                  id="actShprCstCtrCd"
                  @change="fnChangeActCatId(regParams.actShprCstCtrCd);fnAddInfoStateSet('ACT')"
                  @input="checkErrorVisible('actShprCstCtrCd')"
                >
                  <option value="">{{ $t('msg.CSBK100.062') }}</option>
                  <option v-for="(vo, idx) in ctrCdIccList" :key="'actCst_' + idx" :value="vo.ctrCd">{{ vo.ctrEnm }}</option>
                </select>
              </td>
              <td>
                <e-trans-string-check-cnee-input
                  :id="'actShprCstCityNm'"
                  v-model="regParams.actShprCstCityNm"
                  maxlength="100"
                  @input="checkErrorVisible('actShprCstCityNm')"
                />
              </td>
              <td>
                <e-trans-string-check-cnee-input
                  v-show="regParams.actShprCstCtrCd !== 'IN'"
                  :id="'actShprStateNm'"
                  v-model="regParams.actShprStateNm"
                  maxlength="35"
                  @input="checkErrorVisible('actShprStateNm')"
                />
                <select
                  v-if="regParams.actShprCstCtrCd === 'IN'"
                  id="actShprStateCd"
                  v-model="regParams.actShprStateCd"
                  @input="checkErrorVisible('actShprStateCd')"
                >
                  <option value="">{{ $t('msg.CSBK100.062') }}</option>
                  <option v-for="(vo, idx) in indiaCityList" :key="'inAct_' + idx" :value="vo.cd">{{ vo.cdNm }}</option>
                </select>
              </td>
              <td>
                <e-trans-string-check-cnee-input :id="'actShprPostNo'"
                                                 v-model="regParams.actShprPostNo"
                                                 maxlength="6"
                                                 @input="checkErrorVisible('actShprPostNo')"
                />
              </td>
            </tr>
            <tr>
              <td rowspan="2" class="text_left td_shpr">
                <e-trans-address-textarea :id="'shprCstAddr'"
                                          ref="shprCstAddr"
                                          :max-line="5"
                                          :column-length="50"
                                          v-model="regParams.shprCstAddr"
                                          @input="checkShpperLOI"
                />
                <div v-if="isShipperLOIPlc && isShipperLOI" class="shp_msg">
                  <span v-if="$ekmtcCommon.isEmpty(saveShipperLOI)" class="shp_msg_txt">{{ $t('msg.CSBL200.222') }}</span>
                  <div class="d_inline_flex">
                    <a class="button sm mt5" @click="fnCommonBtnAction('TRD_SHIPPER')">{{ $t('msg.CSBL200.272') }}</a>
                    <a v-if="$ekmtcCommon.isNotEmpty(saveShipperLOI)" class="btn_doc mt5" @click.prevent="fnCommonBtnAction('TRD_SHIPPER_FILE')">Shipper LOI 첨부 확인</a>
                  </div>
                </div>
              </td>
              <th>{{ $t('msg.CMBK100_M1.023_1') }}</th> <!-- 담당자 -->
              <th>{{ $t('msg.KMTC118.003') }}</th> <!-- 전화 -->
              <th>{{ $t('msg.CSBL200.187') }}</th> <!-- Fax -->
              <th>{{ $t('msg.NEWB020G010.003') }}</th> <!-- Email -->
            </tr>
            <tr>
              <td class="border_left">
                <e-trans-string-check-cnee-input :id="'actShprCstPicNm'"
                                                 v-model="regParams.actShprCstPicNm"
                                                 maxlength="100"
                                                 @input="checkErrorVisible('actShprCstPicNm')"
                />
              </td>
              <td>
                <e-trans-string-check-cnee-input :id="'actShprCstTelNo'"
                                                 v-model="regParams.actShprCstTelNo"
                                                 maxlength="30"
                                                 @input="checkErrorVisible('actShprCstTelNo')"
                                                 :number-only="regParams.polCtrCd === 'KR' && regParams.podCtrCd === 'JP'"
                />
              </td>
              <td>
                <e-trans-string-check-cnee-input :id="'actShprCstFaxNo'"
                                                 v-model="regParams.actShprCstFaxNo"
                                                 maxlength="30"
                                                 @input="checkErrorVisible('actShprCstFaxNo')"
                                                 :number-only="regParams.polCtrCd === 'KR' && regParams.podCtrCd === 'JP'"
                />
              </td>
              <td>
                <input
                  v-model="regParams.actShprCstEmlAddr"
                  id="actShprCstEmlAddr"
                  type="text"
                  @input="checkErrorVisible('actShprCstEmlAddr')"
                  @blur="checkValidationEmail('actShprCstEmlAddr')"
                >
              </td>
            </tr>

            <tr>
              <th class="text_left">
                {{ $t('msg.NEWB020G010.044') }}
                <select
                  class="wid150 ml20 bg_white"
                  @change="changeLatestInfo($event, 'cne')"
                >
                  <option value="">{{ $t('msg.CSBL200_M1.019') }}</option>
                  <option v-for="(vo, idx) in cstEnmCodes['CNE']" :key="'cne_' + idx" :value="vo.cstNm">{{ vo.cstNm }}</option>
                </select>
                <span class="ml10 color_black font_light">
                  <input
                    type="checkbox"
                    id="order"
                    @click="changeCneCstEnmToOrder"
                  >
                  <label for="order"><span>To Order</span></label>
                </span>
              </th>
              <th>{{ $t('msg.CSBL200.188') }}</th> <!-- 지역 -->
              <th>{{ $t('msg.CSBL200.189') }}</th> <!-- City(시/군) -->
              <th>{{ $t('msg.CSBL200.190') }}</th> <!-- State/Province(도) -->
              <th>{{ $t('msg.CSBL200.191') }}</th> <!-- Postal Code(우편번호) -->
            </tr>
            <tr>
              <td>
                <e-trans-address-input :id="'cneCstEnm'"
                                       ref="cneCstEnm"
                                       form-id="frm_sr"
                                       v-model="regParams.cneCstEnm"
                                       :is-required="true"
                                       :is-char="true"
                                       :max-length="50"
                                       :message-required="$t('msg.CSBK100.147')"
                />
              </td>
              <td>
                <select
                  v-model="regParams.cneCstCtrCd"
                  id="cneCstCtrCd"
                  @change="fnChangeCneCatId(regParams.cneCstCtrCd);fnAddInfoStateSet('CNE')"
                  @input="checkErrorVisible('cneCstCtrCd')"
                >
                  <option value="">{{ $t('msg.CSBK100.062') }}</option>
                  <option v-for="(vo, idx) in ctrCdList" :key="'cneCst_' + idx" :value="vo.ctrCd">{{ vo.ctrEnm }}</option>
                </select>
              </td>
              <td>
                <e-trans-string-check-cnee-input :id="'cneCstCityNm'"
                                                 v-model="regParams.cneCstCityNm"
                                                 maxlength="100"
                                                 @input="checkErrorVisible('cneCstCityNm')"
                />
              </td>
              <td>
                <e-trans-string-check-cnee-input :id="'cneCstStateNm'"
                                                 v-show="regParams.cneCstCtrCd !== 'IN'"
                                                 v-model="regParams.cneCstStateNm"
                                                 maxlength="35"
                                                 @input="checkErrorVisible('cneCstStateNm')"
                />
                <select
                  v-show="regParams.cneCstCtrCd === 'IN'"
                  v-model="regParams.cneCstStateCd"
                  id="cneCstStateCd"
                  @change="changeStateCd('cneCstStateCd', 'cneCstStateNm')"
                  @input="checkErrorVisible('cneCstStateCd')"
                >
                  <option value="">{{ $t('msg.CSBK100.062') }}</option>
                  <option v-for="(vo, idx) in indiaCityList" :key="'inCne_' + idx" :value="vo.cd">{{ vo.cdNm }}</option>
                </select>
              </td>
              <td>
                <e-trans-string-check-cnee-input :id="'cneCstPostNo'"
                                                 v-model="regParams.cneCstPostNo"
                                                 maxlength="6"
                                                 @input="checkErrorVisible('cneCstPostNo')"
                />
              </td>
            </tr>
            <tr>
              <td rowspan="2">
                <e-trans-address-textarea :id="'cneCstAddr'"
                                          ref="cneCstAddr"
                                          :max-line="5"
                                          :column-length="50"
                                          v-model="regParams.cneCstAddr"
                />
              </td>
              <th>{{ $t('msg.CMBK100_M1.023_1') }}</th> <!-- 담당자 -->
              <th>{{ $t('msg.KMTC118.003') }}</th> <!-- 전화 -->
              <th>{{ $t('msg.CSBL200.187') }}</th> <!-- Fax -->
              <th>{{ $t('msg.NEWB020G010.003') }}</th> <!-- Email -->
            </tr>
            <tr>
              <td class="border_left">
                <e-trans-string-check-cnee-input :id="'cneCstPicNm'"
                                                 v-model="regParams.cneCstPicNm"
                                                 maxlength="100"
                                                 @input="checkErrorVisible('cneCstPicNm')"
                />
              </td>
              <td>
                <e-trans-string-check-cnee-input :id="'cneCstTelNo'"
                                                 v-model="regParams.cneCstTelNo"
                                                 maxlength="30"
                                                 @input="checkErrorVisible('cneCstTelNo')"
                                                 :number-only="(regParams.polCtrCd === 'KR' && regParams.podCtrCd === 'JP') || regParams.podPortCd === 'DMM'"
                />
                <span v-if="regParams.podPortCd === 'DMM'" class="shp_msg_txt" style="padding: 0; letter-spacing: 0;">
                  {{ $t('msg.CSBK100.557') }}
                </span>
              </td>
              <td>
                <e-trans-string-check-cnee-input :id="'cneCstFaxNo'"
                                                 v-model="regParams.cneCstFaxNo"
                                                 maxlength="30"
                                                 @input="checkErrorVisible('cneCstFaxNo')"
                                                 :number-only="regParams.polCtrCd === 'KR' && regParams.podCtrCd === 'JP'"
                />
              </td>
              <td>
                <input
                  v-model="regParams.cneCstEmlAddr"
                  id="cneCstEmlAddr"
                  class="col_9"
                  type="text"
                  @input="checkErrorVisible('cneCstEmlAddr')"
                  @blur="checkValidationEmail('cneCstEmlAddr')"
                >
                <span class="position_relative d_inline_block ml5">
                  <button
                    class="tbl_icon help btn_tooltip"
                  >
                    도움말
                  </button>
                  <div class="tooltip_wrap short position_absolute text_left div_tooltip"><!-- tooltip_wrap   -->
                    <div class="cont"><!-- cont -->
                      <ul class="bul_list_sm t2">
                        <li>
                          {{ $t('msg.CSBL200.232') }}
                        </li>
                      </ul>
                    </div><!-- cont -->
                  </div><!-- tooltip_wrap // -->
                </span>
              </td>
            </tr>

            <tr>
              <th class="text_left">
                {{ $t('msg.NEWB020G010.045') }}
                <select
                  class="wid150 ml20 bg_white"
                  @change="changeLatestInfo($event, 'ntif')"
                >
                  <option value="">{{ $t('msg.CSBL200_M1.019') }}</option>
                  <option v-for="(vo, idx) in cstEnmCodes['NTIF']" :key="'ntif_' + idx" :value="vo.cstNm">{{ vo.cstNm }}</option>
                </select>
                <span class="ml10 color_black font_light">
                  <input
                    type="checkbox"
                    id="consi"
                    @click="copyConsignee"
                  >
                  <label for="consi"><span>{{ $t('msg.NEWB020G010.018') }}</span></label>
                </span>
              </th>
              <th>{{ $t('msg.CSBL200.188') }}</th> <!-- 지역 -->
              <th>{{ $t('msg.CSBL200.189') }}</th> <!-- City(시/군) -->
              <th>{{ $t('msg.CSBL200.190') }}</th> <!-- State/Province(도) -->
              <th>{{ $t('msg.CSBL200.191') }}</th> <!-- Postal Code(우편번호) -->
            </tr>
            <tr>
              <td>
                <e-trans-address-input :id="'ntifCstEnm'"
                                       ref="ntifCstEnm"
                                       form-id="frm_sr"
                                       v-model="regParams.ntifCstEnm"
                                       :is-required="true"
                                       :is-char="true"
                                       :max-length="50"
                                       :message-required="$t('msg.CSBK100.147')"
                />
              </td>
              <td>
                <select
                  v-model="regParams.ntifCstCtrCd"
                  id="ntifCstCtrCd"
                  @change="fnChangeNotiCatId(regParams.ntifCstCtrCd);fnAddInfoStateSet('NTIF')"
                  @input="checkErrorVisible('ntifCstCtrCd')"
                >
                  <option value="">{{ $t('msg.CSBK100.062') }}</option>
                  <option v-for="(vo, idx) in ctrCdList" :key="'ntifCst_' + idx" :value="vo.ctrCd">{{ vo.ctrEnm }}</option>
                </select>
              </td>
              <td>
                <e-trans-string-check-cnee-input :id="'ntifCstCityNm'"
                                                 v-model="regParams.ntifCstCityNm"
                                                 maxlength="100"
                                                 @input="checkErrorVisible('ntifCstCityNm')"
                />
              </td>
              <td>
                <e-trans-string-check-cnee-input :id="'ntifCstStateNm'"
                                                 v-show="regParams.ntifCstCtrCd !== 'IN'"
                                                 v-model="regParams.ntifCstStateNm"
                                                 maxlength="35"
                                                 @input="checkErrorVisible('ntifCstStateNm')"
                />
                <select
                  v-show="regParams.ntifCstCtrCd === 'IN'"
                  v-model="regParams.ntifCstStateCd"
                  id="ntifCstStateCd"
                  @change="changeStateCd('ntifCstStateCd', 'ntifCstStateNm')"
                  @input="checkErrorVisible('ntifCstStateCd')"
                >
                  <option value="">{{ $t('msg.CSBK100.062') }}</option>
                  <option v-for="(vo, idx) in indiaCityList" :key="'inNtif_' + idx" :value="vo.cd">{{ vo.cdNm }}</option>
                </select>
              </td>
              <td>
                <e-trans-string-check-cnee-input :id="'ntifCstPostNo'"
                                                 v-model="regParams.ntifCstPostNo"
                                                 maxlength="6"
                                                 @input="checkErrorVisible('ntifCstPostNo')"
                />
              </td>
            </tr>
            <tr>
              <td rowspan="2">
                <e-trans-address-textarea :id="'ntifCstAddr'"
                                          ref="ntifCstAddr"
                                          :max-line="5"
                                          :column-length="50"
                                          v-model="regParams.ntifCstAddr"
                />
              </td>
              <th>{{ $t('msg.CMBK100_M1.023_1') }}</th> <!-- 담당자 -->
              <th>{{ $t('msg.KMTC118.003') }}</th> <!-- 전화 -->
              <th>{{ $t('msg.CSBL200.187') }}</th> <!-- Fax -->
              <th>{{ $t('msg.NEWB020G010.003') }}</th> <!-- Email -->
            </tr>
            <tr>
              <td class="border_left">
                <e-trans-string-check-cnee-input :id="'ntifCstPicNm'"
                                                 v-model="regParams.ntifCstPicNm"
                                                 maxlength="100"
                                                 @input="checkErrorVisible('ntifCstPicNm')"
                />
              </td>
              <td>
                <e-trans-string-check-cnee-input :id="'ntifCstTelNo'"
                                                 v-model="regParams.ntifCstTelNo"
                                                 maxlength="30"
                                                 @input="checkErrorVisible('ntifCstTelNo')"
                                                 :number-only="regParams.polCtrCd === 'KR' && regParams.podCtrCd === 'JP'"
                />
              </td>
              <td>
                <e-trans-string-check-cnee-input :id="'ntifCstFaxNo'"
                                                 v-model="regParams.ntifCstFaxNo"
                                                 maxlength="30"
                                                 @input="checkErrorVisible('ntifCstFaxNo')"
                                                 :number-only="regParams.polCtrCd === 'KR' && regParams.podCtrCd === 'JP'"
                />
              </td>
              <td>
                <input
                  v-model="regParams.ntifCstEmlAddr"
                  id="ntifCstEmlAddr"
                  class="col_9"
                  type="text"
                  @input="checkErrorVisible('ntifCstEmlAddr')"
                  @blur="checkValidationEmail('ntifCstEmlAddr')"
                >
                <span class="position_relative d_inline_block ml5">
                  <button class="tbl_icon help btn_tooltip">도움말</button>
                  <div class="tooltip_wrap short position_absolute text_left div_tooltip"><!-- tooltip_wrap   -->
                    <div class="cont"><!-- cont -->
                      <ul class="bul_list_sm t2">
                        <li>
                          {{ $t('msg.CSBL200.232') }}
                        </li>
                      </ul>
                    </div><!-- cont -->
                  </div><!-- tooltip_wrap // -->
                </span>
              </td>
            </tr>
          </tbody>
        </table>

        <table
          v-if="isCstId"
          class="tbl_row mt10"
        >
          <colgroup>
            <col style="width:170px">
            <col>
            <col style="width:180px">
            <col>
            <col style="width:150px">
            <col>
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('msg.CSBL200.233') }}</th> <!-- Shipper Company ID -->
              <td>
                <div class="tbl_form">
                  <span class="dv col_4">
                    <select
                      v-model="regParams.actShprIdCatCd"
                      id="actShprIdCatCd"
                      @change="fnChangeCatId($event, 'ACT')"
                    >
                      <option value="">select</option>
                      <option v-for="(vo, idx) in actCstIdList" :key="'actShprCatCd_' + idx" :value="vo.cstIdCatCd">{{ vo.cstIdCatNm }}</option>
                    </select>
                  </span>
                  <span class="dv col_6">
                    <e-trans-string-check-cnee-input :id="'actShprIdNo'"
                                                     v-model="regParams.actShprIdNo"
                                                     maxlength="200"
                    />
                  </span>
                </div>
              </td>
              <th>{{ $t('msg.CSBL200.234') }}</th> <!-- Consignee Company ID -->
              <td>
                <div class="tbl_form">
                  <span class="dv col_4">
                    <select
                      v-model="regParams.cneIdCatCd"
                      id="cneIdCatCd"
                      @change="fnChangeCatId($event, 'CNE')"
                    >
                      <option value="">select</option>
                      <option v-for="(vo, idx) in cneCstIdList" :key="'cneCatCd_' + idx" :value="vo.cstIdCatCd">{{ vo.cstIdCatNm }}</option>
                    </select>
                  </span>
                  <span class="dv col_6">
                    <e-trans-string-check-cnee-input :id="'cneIdNo'"
                                                     v-model="regParams.cneIdNo"
                                                     maxlength="200"
                    />
                  </span>
                </div>
              </td>
              <th>{{ $t('msg.CSBL200.235') }}</th> <!-- Notify Company ID -->
              <td>
                <div class="tbl_form">
                  <span class="dv col_4">
                    <select
                      v-model="regParams.ntifIdCatCd"
                      id="ntifIdCatCd"
                      @change="fnChangeCatId($event, 'NTIF')"
                    >
                      <option value="">select</option>
                      <option v-for="(vo, idx) in notiCstIdList" :key="'ntifCatCd_' + idx" :value="vo.cstIdCatCd">{{ vo.cstIdCatNm }}</option>
                    </select>
                  </span>
                  <span class="dv col_6">
                    <e-trans-string-check-cnee-input :id="'ntifIdNo'"
                                                     v-model="regParams.ntifIdNo"
                                                     maxlength="200"
                    />
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <table
          v-if="regParams.polCtrCd === 'ID'"
          class="tbl_row mt10"
        >
          <colgroup>
            <col width="170px;">
            <col>
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('msg.CSBL200.236') }}</th> <!-- Exporter's Tax ID -->
              <td>
                <div class="col_4">
                  <!--                  <e-trans-string-check-cnee-input :id="'etTaxNo'"-->
                  <!--                                                   v-model="regParams.etTaxNo"-->
                  <!--                                                   maxlength="30"-->
                  <!--                  />-->
                  <e-input-number
                    v-model="regParams.etTaxNo"
                    id="etTaxNo"
                    :is-comma="false"
                    :is-phone="true"
                    maxlength="16"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <table
          v-if="regParams.podCtrCd === 'ID' || regParams.podCtrCd === 'JO' || regParams.podCtrCd === 'TZ'"
          class="tbl_row mt10"
        >
          <colgroup>
            <col width="170px;">
            <col>
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('msg.CSBL200.237') }}</th> <!-- Importer's Tax ID -->
              <td>
                <div class="col_4">
                  <!--                  <e-trans-string-check-cnee-input :id="'idTaxNo'"-->
                  <!--                                                   v-model="regParams.idTaxNo"-->
                  <!--                                                   maxlength="30"-->
                  <!--                  />-->
                  <e-input-number
                    v-model="regParams.idTaxNo"
                    id="idTaxNo"
                    :is-comma="false"
                    :is-phone="true"
                    :maxlength="16"
                    @blur="checkValidationInfo('idTaxNo')"
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div
          v-if="regParams.podCtrCd === 'VN'"
          class="mt10"
        >
          <table class="tbl_row mt10">
            <colgroup>
              <col width="170px;">
              <col>
              <col width="170px;">
              <col>
            </colgroup>
            <tbody>
              <tr>
                <th>{{ $t('msg.CSBL200.237') }}</th> <!-- Importer's Tax ID -->
                <td colspan="3">
                  <div class="d_flex">
                    <e-trans-string-check-cnee-input :id="'idTaxNo'"
                                                     class="col_5"
                                                     v-model="regParams.idTaxNo"
                                                     maxlength="30"
                    />
                    <input
                      v-model="regParams.wstCgoYn"
                      id="wstCgoYn"
                      type="checkbox"
                      true-value="Y"
                      false-value="N"
                      @click="changeWstCgoYn"
                    >
                    <label for="wstCgoYn">{{ $t('msg.CSBL200.238') }}</label> <!-- WASTE/SCRAP Cargo -->
                  </div>
                </td>
              </tr>
              <tr v-if="regParams.wstCgoYn === 'Y'">
                <th>{{ $t('msg.CSBL200.239') }}</th> <!-- License No -->
                <td>
                  <e-trans-string-check-cnee-input :id="'imLicNo'"
                                                   v-model="regParams.imLicNo"
                                                   maxlength="20"
                  />
                </td>
                <th>{{ $t('msg.CSBL200.240') }}</th> <!-- Bank Guarantee No -->
                <td>
                  <e-trans-string-check-cnee-input :id="'imBnkGurNo'"
                                                   v-model="regParams.imBnkGurNo"
                                                   maxlength="20"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          v-if="regParams.polCtrCd === 'IN' || regParams.podCtrCd === 'IN' || regParams.inTsYn === 'Y'"
          class="mt10"
        >
          <h2 class="content_title">{{ $t('msg.CSBL200.241') }}</h2> <!-- INDIA Info -->

          <table class="tbl_row mt10">
            <colgroup>
              <col width="170px">
              <col>
              <col width="210px">
              <col>
            </colgroup>
            <tbody>
              <tr>
                <th>{{ regParams.polCtrCd === 'IN' ? $t('msg.CSBL200.192') : $t('msg.CSBL200.193') }}</th>
                <td>
                  <e-trans-string-check-cnee-input :id="'iecNo'"
                                                   v-model="regParams.iecNo"
                                                   maxlength="10"
                                                   @input="setIndiaInfo()"
                  />
                </td>
                <th>{{ $t('msg.CSBL200.242') }} <span class="txt">({{ $t('msg.CSBK100.062') }})</span></th> <!-- FWDR/NVOCC PAN No -->
                <td>
                  <e-trans-string-check-cnee-input :id="'panNo'"
                                                   v-model="regParams.panNo"
                                                   maxlength="10"
                  />
                </td>
              </tr>
              <tr v-if="regParams.polCtrCd === 'IN'">
                <th>{{ $t('msg.ONEX050P190.015') }}</th> <!-- Invoice Value -->
                <td colspan="3">
                  <select
                    v-model="regParams.invValCurCd"
                    id="invValCurCd"
                    class="col_5"
                    @change="checkErrorVisible('invValCurCd')"
                  >
                    <option value="">select</option>
                    <option v-for="(vo, idx) in commonCodes['00003']" :key="'invValCurCd_' + idx" :value="vo.cd">{{ vo.cd }}</option>
                  </select>
                  <e-input-number
                    v-model="regParams.invValExp"
                    id="invValExp"
                    class="inp_space50_2 ml3"
                    :is-comma="true"
                    :point="3"
                    maxlength="18"
                    @input="checkErrorVisible('invValExp')"
                  >
                  </e-input-number>
                </td>
              </tr>
              <tr v-if="regParams.podCtrCd === 'IN' || regParams.inTsYn === 'Y'">
                <th>{{ $t('msg.CSBL200.243') }}</th> <!-- Notify's PAN No -->
                <td>
                  <e-trans-string-check-cnee-input :id="'ntifPanNo'"
                                                   v-model="regParams.ntifPanNo"
                                                   maxlength="10"
                                                   @input="setIndiaInfo()"
                  />
                </td>
                <th>{{ $t('msg.ONEX050P190.015') }}</th> <!-- Invoice Value -->
                <td>
                  <select
                    v-model="regParams.invValCurCd"
                    id="invValCurCd"
                    class="col_5"
                    @change="checkErrorVisible('invValCurCd')"
                  >
                    <option value="">select</option>
                    <option v-for="(vo, idx) in commonCodes['00003']" :key="'invValCurCd_' + idx" :value="vo.cd">{{ vo.cd }}</option>
                  </select>
                  <e-input-number
                    v-model="regParams.invValExp"
                    id="invValExp"
                    class="inp_space50_2 ml3"
                    :is-comma="true"
                    :point="3"
                    maxlength="18"
                    @input="checkErrorVisible('invValExp')"
                  >
                  </e-input-number>
                </td>
              </tr>
              <tr v-if="regParams.podCtrCd === 'IN' && regParams.podPortCd === 'NSA'">
                <th>{{ $t('msg.CSBL200.244') }}</th> <!-- GST No -->
                <td>
                  <e-trans-string-check-cnee-input :id="'gstNo'"
                                                   v-model="regParams.gstNo"
                                                   maxlength="20"
                                                   @input="setIndiaInfo()"
                  />
                </td>
                <th>{{ $t('msg.MAIN100.202') }}</th> <!-- E-Mail -->
                <td>
                  <input
                    v-model="regParams.iecPicEmlAddr"
                    id="iecPicEmlAddr"
                    type="text"
                    maxlength="100"
                    @input="checkErrorVisible('iecPicEmlAddr')"
                    @blur="checkValidationEmail('iecPicEmlAddr')"
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div
          v-if="regParams.podCtrCd === 'EG'"
          class="mt10"
        >
          <table class="tbl_row mt10">
            <colgroup>
              <col width="170px;">
              <col>
              <col width="170px;">
              <col>
            </colgroup>
            <tbody>
              <tr>
                <th>ACID No</th> <!-- Importer's Tax ID -->
                <td colspan="2">
                  <e-trans-string-check-cnee-input :id="'acidNo'"
                                                   v-model="regParams.acidNo"
                                                   maxlength="19"
                                                   :is-upper-case="true"
                  />
                </td>
                <th>{{ $t('msg.CSBL200.237') }}</th> <!-- Importer's Tax ID -->
                <td colspan="2">
                  <e-trans-string-check-cnee-input :id="'cneAcidNo'"
                                                   v-model="regParams.cneAcidNo"
                                                   maxlength="9"
                                                   :is-upper-case="true"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div v-if="regParams.podCtrCd === 'EG'">
          <p class="txt_desc">{{ $t('msg.CSBL200_M.040') }}</p>
          <p class="txt_desc">{{ $t('msg.CSBL200_M.041') }}</p>
          <p class="txt_desc">{{ $t('msg.CSBL200_M.042') }}</p>
        </div>

        <div v-if="regParams.jpPodCount > 0">
          <p class="txt_desc">{{ $t('msg.CSBL200_M.001') }}</p>
        </div>

        <div v-if="regParams.cnPodfCount > 0 && regParams.shaPodTsCount === 0">
          <p class="txt_desc">{{ $t('msg.CSBL200_M.002') }}</p>
        </div>

        <div v-if="(regParams.polPortCd === 'PKG' || regParams.polPortCd === 'PKW') && regParams.cnPodCount < 1">
          <p class="txt_desc">{{ $t('msg.CSBL200_M.003') }}</p>
        </div>

        <div v-if="regParams.polCtrCd === 'ID'">
          <p class="txt_desc">{{ $t('msg.CSBL200_M.004') }}</p>
        </div>

        <div v-if="regParams.polCtrCd === 'JP' && regParams.podCtrCd === 'VN'">
          <p class="txt_desc">{{ $t('msg.CSBL200_M.027') }}</p>
          <p class="txt_desc">{{ $t('msg.CSBL200_M.028') }}</p>
          <p class="txt_desc">{{ $t('msg.CSBL200_M.029') }}<br />{{ $t('msg.CSBL200_M.030') }}</p>
          <p class="txt_desc">{{ $t('msg.CSBL200_M.031') }}</p>
          <p class="txt_desc">{{ $t('msg.CSBL200_M.032') }}</p>
          <p class="txt_desc">{{ $t('msg.CSBL200_M.033') }}</p>
          <p class="txt_desc">{{ $t('msg.CSBL200_M.034') }}</p>
          <br />
        </div>
        <div v-else-if="regParams.podCtrCd === 'VN'">
          <p class="txt_desc">{{ $t('msg.CSBL200_M.005') }}</p>
          <p class="txt_desc">{{ $t('msg.CSBL200_M.006') }}</p>
        </div>

        <div v-if="regParams.polCtrCd === 'JP' && regParams.podCtrCd === 'ID'">
          <p class="txt_desc_normal">{{ $t('msg.CSBL200_M.027') }}</p>
          <p class="txt_desc">{{ $t('msg.CSBL200_M.007') }}</p>
        </div>
        <div v-else-if="regParams.podCtrCd === 'ID'">
          <p class="txt_desc">{{ $t('msg.CSBL200_M.007') }}</p>
        </div>
        <div v-else-if="regParams.podCtrCd === 'JO' || regParams.podCtrCd === 'TZ'">
          <p class="txt_desc">{{ $t('msg.CSBL200_M.044') }}</p>
        </div>

        <div v-else-if="regParams.podPortCd === 'DMM'">
          <p class="txt_desc">{{ $t('msg.CSBL200_M.039') }}</p>
        </div>

        <div v-if="regParams.polCtrCd === 'IN'">
          <p class="txt_desc">
            {{ $t('msg.CSBL200_M.008') }}<br>
            {{ $t('msg.CSBL200_M.009') }}<br>
            {{ $t('msg.CSBL200_M.010') }}
          </p>
        </div>

        <div v-if="regParams.polCtrCd === 'JP' && regParams.podCtrCd === 'IN'">
          <p class="txt_desc_normal">
            {{ `&lt;${$t('msg.CSBL200_M.017')}&gt;` }}<br />
            {{ $t('msg.CSBL200_M.018') }}<br>
            {{ $t('msg.CSBL200_M.019') }}<br>
            {{ $t('msg.CSBL200_M.020') }}<br>
            {{ $t('msg.CSBL200_M.021') }}<br>
            {{ $t('msg.CSBL200_M.022') }}<br>
            {{ $t('msg.CSBL200_M.023') }}<br>
            {{ $t('msg.CSBL200_M.024') }}<br>
          </p>
        </div>
        <div v-else-if="regParams.podCtrCd === 'IN' || regParams.inTsYn === 'Y'">
          <p class="txt_desc">
            {{ $t('msg.CSBL200_M.011') }}<br>
            {{ $t('msg.CSBL200_M.012') }}<br>
            {{ $t('msg.CSBL200_M.013') }}<br>
            {{ $t('msg.CSBL200_M.014') }}<br>
            {{ $t('msg.CSBL200_M.015') }}
          </p>
        </div>

        <template>
          <template v-if="regParams.polCtrCd === 'JP' && regParams.podCtrCd === 'ID'">
            <!--
            <p class="txt_desc">{{ $t('msg.CSBL200_M.026') }}</p>
            <p class="txt_desc">{{ $t('msg.CSBL200_M.024') }}</p>-->
          </template>
          <template v-else>
            <p class="txt_desc">{{ $t('msg.CSBL200_M.016') }}</p>
          </template>
        </template>
        <div v-if="regParams.polCtrCd === 'JP' && regParams.podCtrCd === 'PK'">
          <p class="txt_desc_normal">{{ `&lt;${$t('msg.CSBL200_M.035')}&gt;` }}</p>
          <p class="txt_desc">{{ $t('msg.CSBL200_M.036') }}</p>
          <p class="txt_desc">{{ $t('msg.CSBL200_M.037') }}</p>
        </div>

        <h2 class="content_title">{{ $t('msg.CSBL200.245') }}</h2> <!-- Cargo Information -->

        <table class="tbl_row mt10">
          <colgroup>
            <col style="width:170px">
            <col>
            <col style="width:180px">
            <col>
            <col style="width:150px">
            <col>
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('msg.CSBL200.246') }}</th> <!-- 품명(세관신고 품목) -->
              <td colspan="5">
                <e-trans-string-check-cnee-input
                  :id="'cgoCont'"
                  v-model="regParams.cgoCont"
                  maxlength="200"
                  :is-upper-case="true"
                />
              </td>
            </tr>
            <tr>
              <th>Cargo Weight</th> <!--  Cargo Weight -->
              <td>
                <e-input-number
                  v-model="regParams.grsWt"
                  :is-comma="true"
                  :point="3"
                  id="grsWt"
                  class="col_6"
                  maxlength="14"
                  @blur="fnSumValue('grsWt')"
                  @input="checkValidationInfo('grsWt')"
                >
                </e-input-number> Kgs
              </td>
              <th>{{ $t('art.CMATK100') }}</th> <!-- Package -->
              <td>
                <e-input-number
                  v-model="regParams.pkgQty"
                  :is-comma="true"
                  id="pkgQty"
                  class="col_3"
                  maxlength="10"
                  @blur="fnSumValue('pkgQty');fnPackage('CHANGE')"
                  @input="checkValidationInfo('pkgQty')"
                >
                </e-input-number>
                <select
                  v-model="regParams.pkgCd"
                  id="pkgCd"
                  class="inp_space70"
                  @change="checkValidationInfo('pkgCd');fnPackage('CHANGE')"
                >
                  <option value="">{{ $t('msg.CSBL200_P1.018') }}</option>
                  <option v-for="(vo, idx) in pkgCdList" :key="'pkgCd_' + idx" :value="vo.cd">{{ vo.cdNm }}</option>
                </select>
              </td>
              <th>{{ $t('art.CMATK098') }}</th> <!-- Measure -->
              <td>
                <e-input-number class="col_5" v-model="regParams.grsCbm" id="grsCbm" :is-comma="true" :point="4" maxlength="13" @input="checkErrorVisible('grsCbm')"></e-input-number>
                CBM
              </td>
            </tr>
            <tr>
              <th>POL HS Code</th>
              <td>
                <div class="tbl_form">
                  <span :class="isPolHsMulti ? 'dv col_8' : 'dv'">
                    <e-trans-string-check-input :id="'hsCd'" v-model="regParams.hsCd" :is-upper-case="true" :maxlength="hsChkParams.maxlengthPolHsCd"
                                                :error-message="hsChkParams.hscdPolErrorMessage" @input="checkHsCd($event, 'POL')"
                    />
                  </span>
                  <span v-if="isPolHsMulti" class="dv">
                    <button class="tbl_icon plus" type="button" @click="hsCdMultiPop('01')">plus</button>
                  </span>
                </div>
              </td>
              <th>POD HS Code</th>
              <td>
                <div class="tbl_form">
                  <span :class="isPodHsMulti ? 'dv col_8' : 'dv'">
                    <e-trans-string-check-input :id="'podHsCd'" v-model="regParams.podHsCd" :is-upper-case="true" :maxlength="hsChkParams.maxlengthPodHsCd"
                                                :error-message="hsChkParams.hscdPodErrorMessage" @input="checkHsCd($event, 'POD')"
                    />
                  </span>
                  <span v-if="isPodHsMulti" class="dv">
                    <button class="tbl_icon plus" type="button" @click="hsCdMultiPop('02')">plus</button>
                  </span>
                  <span v-if="!isPodHsMulti" class="dv">
                    <div class="position_relative">
                      <input type="text" :readonly="true" v-model="regParams.podHsCdNm" />
                    </div>
                  </span>
                </div>
              </td>
              <td colspan="2" class="border_left_none"></td>
            </tr>
          </tbody>
        </table>

        <div class="desc_list" v-if="lang === 'KOR'">
          <ul>
            <li>{{ $t('msg.CSBL200.247') }}</li>
          </ul>
          <ul v-if="regParams.podCtrCd === 'MY'">
            <li style="color:red;">말레이시아향은 품명(세관신고 품목)란과 디스크립션의 품명이 수입신고 품명으로 일치해야 합니다.</li>
          </ul>
        </div>

        <div class="mt20">
          <h2 class="content_title">{{ $t('msg.CSBL200.248') }}</h2> <!-- E/L Information -->
          <table class="tbl_row mt10">
            <colgroup>
              <col style="width:170px">
            </colgroup>
            <tbody>
              <tr>
                <th>{{ $t('msg.CSBL200.249') }}</th> <!-- E/L Type -->
                <td colspan="5">
                  <select v-model="regParams.blEdiTypCd" class="wid200" @change="changeBlEdiTypCd">
                    <option v-for="(vo, idx) in blEdiTypCdList" :key="'blEdiTypCd_' + idx" :value="vo.cd">{{ vo.dsc }}</option>
                  </select>
                </td>
              </tr>
            </tbody>
          </table>

          <table class="tbl_col mt10 table_elInfo" v-if="isExportLicense()">
            <colgroup>
              <col style="width:5%">
              <col style="width:12%">
              <col style="width:18%">
              <col style="width:10%">
              <col style="width:18%">
              <col style="width:18%">
              <col style="width:14%">
              <col style="width:5%">
            </colgroup>
            <thead>
              <tr>
                <th>No.</th>
                <th>{{ $t('msg.CSBL220.002') }}</th> <!-- 수출신고필증 -->
                <th>{{ $t('art.CMATK100') }}</th> <!-- Package -->
                <th>{{ $t('msg.ARAI100_M1.005') }}</th> <!-- 총중량(Kg) -->
                <th>{{ $t('msg.ARAI100_M1.006') }}</th> <!-- 분할유무 -->
                <th>{{ $t('msg.CSBL200.082') }}</th> <!-- 동시포장 -->
                <th>{{ $t('msg.CSBL200.083') }}</th> <!-- 신고일 -->
                <th>
                  <button
                    type="button"
                    class="tbl_icon plus"
                    @click="addExportLicense"
                  >
                    plus
                  </button>
                </th>
              </tr>
            </thead>
            <tbody v-for="(vo, idx) in regParams.exportLicenseList" :key="'expLicense_' + idx">
              <tr>
                <td>
                  {{ idx + 1 }}
                </td>
                <td>
                  <input
                    v-model="vo.elNo"
                    :id="'elNo_' + idx"
                    type="text"
                    @input="checkValidationExportLicense('elNo', idx)"
                    @blur="checkElNo(idx)"
                  >
                </td>
                <td>
                  <e-input-number
                    v-model="vo.pkgQty"
                    :is-comma="true"
                    class="inp_space30"
                    :id="'lcsPkgQty_' + idx"
                    maxlength="10"
                    @input="checkValidationExportLicense('lcsPkgQty', idx)"
                  >
                  </e-input-number>
                  <select
                    v-model="vo.pkgCd"
                    class="inp_responsive_space7 ml3"
                    :id="'lcsPkgCd_' + idx"
                    @input="checkValidationExportLicense('lcsPkgCd', idx)"
                  >
                    <option value="">{{ $t('msg.CSBL220.003') }}</option> <!-- 단위선택 -->
                    <option v-for="(cvo, i) in pkgCdList" :key="'lcsPkg_' + i" :value="cvo.cd">{{ cvo.cdNm }}</option>
                  </select>
                </td>
                <td>
                  <e-input-number
                    v-model="vo.grsWt"
                    :is-comma="true"
                    :id="'lcsGrsWt_' + idx"
                    :point="3"
                    maxlength="13"
                    @input="checkValidationExportLicense('lcsGrsWt', idx)"
                  >
                  </e-input-number>
                </td>
                <td>
                  <select
                    v-model="vo.prtlLdgYn"
                    :id="'prtlLdgYn_' + idx"
                    class="inp_space50"
                  >
                    <option value="N">No</option>
                    <option value="Y">Yes</option>
                  </select>
                  <select
                    v-model="vo.ldgTcnt"
                    class="inp_space50 ml3"
                    :id="'ldgTcnt_' + idx"
                    @input="checkValidationExportLicense('ldgTcnt', idx)"
                  >
                    <option value="">{{ $t('msg.CSBL220.004') }}</option> <!-- 차수 -->
                    <option v-for="(cvo, i) in 30" :key="'ldgTcnt_' + i" :value="cvo">{{ cvo }}</option>
                  </select>
                </td>
                <td>
                  <select
                    v-model="vo.pkgtgCd"
                    :id="'pkgtgCd_' + idx"
                    class="inp_space50"
                  >
                    <option value="">Code</option>
                    <option v-for="(cvo, i) in 9" :key="'pkgtgCd_' + i" :value="String.fromCharCode(cvo + 64)">{{ String.fromCharCode(cvo + 64) }}</option>
                  </select>

                  <e-input-number
                    v-model="vo.pkgtgPkgQty"
                    :is-comma="true"
                    :id="'pkgtgPkgQty_' + idx"
                    maxlength="3"
                    class="inp_space50 ml3"
                    @input="checkValidationExportLicense('pkgtgPkgQty', idx)"
                  >
                  </e-input-number>
                </td>
                <td>
                  <div class="input_calendar mid">
                    <e-date-picker
                      v-model="vo.elNoValdDt"
                      :id="'elNoValdDt_' + idx"
                      :disabled="elNoValdDisbled"
                      @input="checkValidationExportLicense('elNoValdDt', idx)"
                    >
                    </e-date-picker>
                  </div>
                </td>
                <td>
                  <button
                    class="tbl_icon minus"
                    @click="removeExportLicense(idx)"
                  >
                    minus
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <table class="tbl_col mt10" v-if="isIndonesia()">
          <colgroup>
            <col style="width:5%">
            <col style="width:19%">
            <col style="width:13%">
            <col style="width:20%">
            <col style="width:15%">
            <col style="width:21%">
            <col style="width:5%">
          </colgroup>
          <thead>
            <tr>
              <th>No.</th>
              <th>License No.</th>
              <th>License Date</th>
              <th>Customs Office</th>
              <th>Empty Check</th>
              <th>
                <input v-model="regParams.consCgoYn" id="tickYn" type="checkbox" true-value="Y" false-value="N" @click="consCgoYnCheck($event)">
                <label for="tickYn">{{ $t('msg.CSBL200.284') }}</label> <!-- Tick consolidate cargo -->
              </th>
              <th>
                <button
                  v-show="!closeEl"
                  type="button"
                  class="tbl_icon plus"
                  @click="addExportLicense"
                >
                  plus
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(vo, idx) in regParams.exportLicenseList" :key="'expLicense_' + idx" :id="'expLicense_' + idx">
              <td>{{ idx + 1 }}</td>
              <td>
                <input
                  v-model="vo.elNo"
                  :id="'elNo_' + idx"
                  type="text"
                  maxlength="20"
                  @input="checkValidationExportLicense('elNo', idx)"
                  @blur="checkElNoIdn(idx)"
                >
              </td>
              <td>
                <e-date-picker
                  v-model="vo.elNoValdDt"
                  :id="'elNoValdDt_' + idx"
                  :disabled="elNoValdDisbled"
                  @input="checkErrorVisible('elNoValdDt_' + idx)"
                >
                </e-date-picker>
              </td>
              <td>
                <input
                  v-model="vo.idCstmCd"
                  :id="'idCstmCd_' + idx"
                  type="text"
                  maxlength="6"
                  @input="checkErrorVisible('elNoValdDt_' + idx)"
                >
              </td>
              <td>
                <input
                  v-model="vo.emptyYn"
                  :id="'emptyCheck_' + idx"
                  type="checkbox"
                  true-value="Y"
                  false-value="N"
                  @click="checkIdEmptyCheck($event, idx)"
                >
                <label :for="'emptyCheck_' + idx"></label>
              </td>
              <td>Compulsory Item only for Indonesian Export B/L</td>
              <td>
                <button
                  v-show="!closeEl"
                  class="tbl_icon minus"
                  @click="removeExportLicense(idx)"
                >
                  minus
                </button>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="flex_box mt20">
          <h2 class="content_title">Container</h2>
          <span class="ml_auto cal_btn">
            <a
              class="button sm"
              @click="fnCntrPkgAutoSet()"
            >
              {{ $t('msg.CSBL200.250') }} <!-- 중량/개수 자동 나누기 -->
            </a>
            <a
              v-if="excelUploadShow"
              class="button sm ml3"
              @click="excelUploadPop()"
            >Excel Upload</a>
            <a
              class="button sm ml3"
              @click="fnCgoPop()"
            >
              {{ $t('btn.CMBTK045') }} <!-- 특수화물 정보조회 -->
            </a>
          </span>
        </div>

        <table class="tbl_col">
          <colgroup v-if="lang !== 'JPN' && regParams.polCtrCd !== 'PH' && regParams.polCtrCd !== 'VN' && regParams.podCtrCd !== 'BH'">
            <col style="width:3%">
            <col style="width:9.5%">
            <col style="width:11.5%">
            <col style="width:6%">
            <col style="width:12.5%">
            <col style="width:8%">
            <col style="width:11.5%">
            <col style="width:10%">
            <col style="width:8%">
            <col style="width:8%">
            <col style="width:5%">
            <col style="width:10%">
            <col style="width:4%">
          </colgroup>
          <colgroup v-else-if="regParams.polCtrCd === 'PH' || regParams.polCtrCd === 'VN' || regParams.podCtrCd !== 'BH'">
            <col style="width:3%">
            <col style="width:9%">
            <col style="width:11%">
            <col style="width:6%">
            <col style="width:12%">
            <col style="width:8%">
            <col style="width:11%">
            <col style="width:10%">
            <col style="width:7%">
            <col style="width:7%">
            <col style="width:4%">
            <col style="width:5%">
            <col style="width:10%">
            <col style="width:4%">
          </colgroup>
          <colgroup v-else-if="lang === 'JPN'">
            <col style="width:2%">
            <col style="width:9%">
            <col style="width:9%">
            <col style="width:7%">
            <col style="width:12%">
            <col style="width:8%">
            <col style="width:9%">
            <col style="width:8%">
            <col style="width:6%">
            <col style="width:12%">
            <col style="width:7%">
            <col style="width:7%">
            <col style="width:10%">
            <col style="width:3%">
          </colgroup>
          <colgroup v-else-if="regParams.podCtrCd === 'BH'">
            <col style="width:3%">
            <col style="width:9%">
            <col style="width:11%">
            <col style="width:6%">
            <col style="width:12%">
            <col style="width:8%">
            <col style="width:11%">
            <col style="width:10%">
            <col style="width:7%">
            <col style="width:7%">
            <col style="width:8%">
            <col style="width:5%">
            <col style="width:10%">
            <col style="width:4%">
          </colgroup>
          <thead v-if="lang !== 'JPN' && regParams.polCtrCd !== 'PH' && regParams.polCtrCd !== 'VN' && regParams.podCtrCd !== 'BH'">
            <tr>
              <th>No.</th>
              <th>{{ $t('msg.CSBL200.197') }}</th> <!-- Container No. -->
              <th>{{ $t('msg.CSBL200.251') }}</th> <!-- Seal No. -->
              <th>{{ $t('msg.CSBK100.272') }}</th> <!-- Size -->
              <th>{{ $t('msg.CSBK100.273') }}</th> <!-- Type -->
              <th>{{ $t('msg.CSBL200_M1.024') }}</th> <!-- 특수화물 -->
              <th>{{ $t('art.CMATK110') }}</th> <!-- 특수화물 -->
              <th>{{ $t('msg.ONEX050P060.005') }}</th> <!-- Weight -->
              <th>{{ $t('art.CMATK100') }}</th> <!-- Package -->
              <th>{{ $t('msg.CSBL200.252') }}</th> <!-- 화주컨테이너 -->
              <th>Empty</th>
              <th>Deck</th>
              <th>
                <button
                  v-show="!closeCntr"
                  type="button"
                  class="tbl_icon plus"
                  @click="addBLContainer"
                >
                  plus
                </button>
              </th>
            </tr>
          </thead>
          <thead v-else-if="lang === 'JPN'">
            <tr>
              <th>No.</th>
              <th>{{ $t('msg.CSBL200.197') }}</th> <!-- Container No. -->
              <th>{{ $t('msg.CSBL200.251') }}</th> <!-- Seal No. -->
              <th>{{ $t('msg.CSBK100.272') }}</th> <!-- Size -->
              <th>{{ $t('msg.CSBK100.273') }}</th> <!-- Type -->
              <th>{{ $t('msg.CSBL200_M1.024') }}</th> <!-- 특수화물 -->
              <th>Cargo Wgt</th>
              <th>Tare Wgt</th>
              <th>Pkg</th>
              <th>P'kg Type</th>
              <th>Measure</th>
              <th>{{ $t('msg.CSBL200.252') }}</th> <!-- 화주컨테이너 -->
              <th>Deck</th>
              <th>
                <button
                  v-show="!closeCntr"
                  class="tbl_icon plus"
                  @click="addBLContainer"
                >
                  plus
                </button>
              </th>
            </tr>
          </thead>
          <thead v-else-if="regParams.polCtrCd === 'PH' || regParams.polCtrCd === 'VN' || regParams.podCtrCd === 'BH'">
            <tr>
              <th>No.</th>
              <th>{{ $t('msg.CSBL200.197') }}</th> <!-- Container No. -->
              <th>{{ $t('msg.CSBL200.251') }}</th> <!-- Seal No. -->
              <th>{{ $t('msg.CSBK100.272') }}</th> <!-- Size -->
              <th>{{ $t('msg.CSBK100.273') }}</th> <!-- Type -->
              <th>{{ $t('msg.CSBL200_M1.024') }}</th> <!-- 특수화물 -->
              <th>{{ $t('art.CMATK110') }}</th> <!-- 특수화물 -->
              <th>{{ $t('msg.ONEX050P060.005') }}</th> <!-- Weight -->
              <th>{{ $t('art.CMATK100') }}</th> <!-- Package -->
              <th>CBM</th> <!-- CBM -->
              <th>{{ $t('msg.CSBL200.252') }}</th> <!-- 화주컨테이너 -->
              <th>Empty</th>
              <th>Deck</th>
              <th>
                <button
                  v-show="!closeCntr"
                  type="button"
                  class="tbl_icon plus"
                  @click="addBLContainer"
                >
                  plus
                </button>
              </th>
            </tr>
          </thead>
          <tbody v-for="(vo, idx) in regParams.containerList" :key="'cntr_' + idx">
            <tr>
              <td>{{ idx + 1 }}</td>
              <td>
                <input
                  v-model="vo.cntrNo"
                  :id="'cntrNo_' + idx"
                  type="text"
                  maxlength="11"
                  @input="changeOnlyUpperEng($event, vo, 'cntrNo', idx)"
                  @blur="checkCntrNo(idx)"
                >
              </td>
              <td>
                <div
                  class="tbl_form div_seal"
                  v-for="(cvo, i) in vo.sealList" :key="'seal_' + i"
                >
                  <div>
                    <span class="dv col_8">
                      <input
                        v-model="cvo.sealNo"
                        :id="'sealNo_' + i"
                        :name="'sealNo_' + idx + '_' + i"
                        type="text"
                        @input="changeOnlyUpperEng($event, cvo, 'sealNo_' + idx, i)"
                        :style="{ width: '80px' }"
                      >
                    </span>
                    <span class="dv">
                      <button
                        v-if="i === 0"
                        class="tbl_icon plus"
                        @click="addSealNo(idx)"
                      >
                        plus
                      </button>
                      <button
                        v-if="i !== 0"
                        class="tbl_icon minus"
                        @click="removeSealNo(idx, i)"
                      >
                        minus
                      </button>
                    </span>
                  </div>
                </div>
              </td>
              <td>
                <select
                  v-model="vo.cntrSzCd"
                  :id="'cntrSzCd_' + idx"
                  @click="fnNoChange($event, idx)"
                >
                  <option value=""></option>
                  <option v-for="(cvo, i) in commonCodes['01031']" :key="'cntrSzCd_' + i" :value="cvo.cd">{{ cvo.cdNm }}</option>
                </select>
              </td>
              <td>
                <select
                  v-model="vo.cntrTypCd"
                  :id="'cntrTypCd_' + idx"
                  @change="changeCntrType(vo, idx)"
                  :class="(vo.cntrTypCd === 'RF' || vo.cntrTypCd === 'RH') ? 'col_85' : ''"
                  @click="fnNoChange($event, idx)"
                >
                  <option value=""></option>
                  <option v-for="(cvo, i) in commonCodes['01068']" :key="'cntrTypCd_' + i" :value="cvo.cd">{{ cvo.cdNm }}</option>
                </select>
                <a
                  v-if="(vo.cntrTypCd === 'RF' || vo.cntrTypCd === 'RH') && !rfDisabled"
                  class="btn_rf_entry float_right"
                  @click="cntrTypeInfoPop(vo.cntrTypCd, idx)"
                >
                  {{ $t('msg.CSBL200.258') }}
                </a>
              </td>
              <td>
                <select
                  v-model="vo.cgoTypCd"
                  :id="'cgoTypCd_' + idx"
                  @change="cgoTypeInfoPop(vo.cgoTypCd, idx)"
                >
                  <option value="">----------------</option>
                  <option v-for="(cvo, i) in cgoTypCdList" :key="'cgoTypCd_' + i" :value="cvo.cd">{{ cvo.cdNm }}</option>
                </select>
              </td>
              <td v-show="lang !== 'JPN'">
                <select
                  :id="'lcsPolShtmCd_' + idx"
                  class="inp_space50_2"
                  v-model="vo.polShtmCd"
                  disabled
                >
                  <option v-for="(cvo, i) in vo.polShtmCdList" :key="'lcsPolShtmCd_' + i" :value="cvo.cd">{{ cvo.cdNm }}</option>
                </select>
                <select
                  :id="'lcsPodShtmCd_' + idx"
                  class="inp_space50_2"
                  v-model="vo.podShtmCd"
                  disabled
                >
                  <option v-for="(cvo, i) in commonCodes['01033']" :key="'lcsPodShtmCd_' + i" :value="cvo.cd">{{ cvo.cdNm }}</option>
                </select>
              </td>
              <td>
                <e-input-number
                  v-model="vo.wt"
                  :id="'wt_' + idx"
                  :point="3"
                  class="col_7"
                  :is-comma="true"
                  maxlength="14"
                  @input="checkValidationInfo('wt_' + idx)"
                >
                </e-input-number> Kgs
              </td>
              <td v-if="lang === 'JPN'">
                <div class="tbl_form">
                  <span class="dv col_7">
                    <e-input-number
                      v-model="vo.tareWt"
                      :id="'tareWt_' + idx"
                      :is-comma="true"
                      :point="3"
                      maxlength="14"
                    >
                    </e-input-number>
                  </span>
                  <span class="dv">
                    Kgs
                  </span>
                </div>
              </td>
              <td>
                <e-input-number
                  v-model="vo.pkgQty"
                  :id="'pkgQty_' + idx"
                  :is-comma="true"
                  maxlength="10"
                >
                </e-input-number>
              </td>
              <td v-if="lang === 'JPN'">
                <select
                  :id="'lcsPkgCd_' + idx"
                  v-model="vo.pkgCd"
                >
                  <option value="">{{ $t('msg.CSBL220.003') }}</option>
                  <option v-for="(cvo, i) in pkgCdList" :key="'cntrPkgCd_' + i" :value="cvo.cd">{{ cvo.cdNm }}</option>
                </select>
              </td>
              <td v-if="lang === 'JPN' || regParams.polCtrCd === 'PH' || regParams.polCtrCd === 'VN' || regParams.podCtrCd === 'BH'">
                <e-input-number
                  v-model="vo.meaCbm"
                  :id="'meaCbm_' + idx"
                  :is-comma="true"
                  :point="4"
                  maxlength="8"
                >
                </e-input-number>
              </td>
              <td>
                <input
                  v-model="vo.shprVanYn"
                  @change="changeShprVanYn(vo, idx)"
                  type="checkbox"
                  :id="'shprVanYn_' + idx"
                  true-value="Y"
                  false-value="N"
                >
                <label :for="'shprVanYn_' + idx"></label>
              </td>
              <td v-if="lang !== 'JPN'">
                <input
                  v-model="vo.feUnitCd"
                  type="checkbox"
                  :id="'feUnitCd_' + idx"
                  true-value="E"
                  false-value="F"
                  :disabled="vo.shprVanYn === 'N'"
                >
                <label :for="'feUnitCd_' + idx"></label>
              </td>
              <td>
                <select class="deck_select"
                        v-model="vo.deckCd"
                        :id="'deckCd_' + idx"
                        disabled
                >
                  <option value=""></option>
                  <option v-for="(cvo, i) in deckCdList" :key="'deckCd_' + i" :value="cvo.cd">{{ cvo.cdNm }}</option>
                </select>
              </td>
              <td>
                <button
                  v-show="!closeCntr"
                  class="tbl_icon minus"
                  @click="removeBLContainer(idx)"
                >
                  minus
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="mt10 flex_box">
          <div class="ml_auto">
            <a v-if="lang === 'JPN'" class="button blue sm" @click="fnCommonBtnAction('JPCLP')">CLP Print</a>
            <a v-if="showSHAMFButton()" class="button blue sm ml5" @click="fnCommonBtnAction('SHAMF')">SHA M/F Data</a>
          </div>
        </div>

        <h2 class="content_title">B/L Body Detail</h2>
        <table class="tbl_row mt10">
          <colgroup>
            <col width="170px">
            <col>
          </colgroup>
          <tbody>
            <tr>
              <th>Say</th>
              <td style="display: flex;">
                <e-trans-string-check-cnee-input
                  :id="'blPrtrCont'"
                  v-model="regParams.blPrtrCont"
                  maxlength="100"
                  :is-upper-case="true"
                  class="col_8"
                  @blur="fnSayTab"
                />
                <a
                  class="button sm ml2"
                  @click="fnSaySet()"
                >Auto Say</a>
              </td>
            </tr>
          </tbody>
        </table>

        <table class="tbl_col mt10 tbl_mark">
          <colgroup>
            <col width="41%">
            <col width="56%">
            <col width="3%">
          </colgroup>
          <thead>
            <tr>
              <th class="text_left">
                Mark & No
                <select
                  v-model="addrStr"
                  class="wid150 ml10"
                  @change="searchAgentAddr"
                >
                  <option value="">Agent address</option>
                  <option v-if="regParams.porPlcCd === regParams.polPortCd" :value="regParams.porCtrCd + ':' + regParams.porPlcCd">{{ regParams.porPlcNm }}</option>
                  <option v-if="regParams.porPlcCd !== regParams.polPortCd" :value="regParams.porCtrCd + ':' + regParams.porPlcCd">{{ regParams.porPlcNm }}</option>
                  <option v-if="regParams.porPlcCd !== regParams.polPortCd" :value="regParams.polCtrCd + ':' + regParams.polPortCd">{{ regParams.polPortNm }}</option>
                  <option v-if="regParams.podPortCd === regParams.dlyPlcCd" :value="regParams.podCtrCd + ':' +regParams.podPortCd">{{ regParams.podPortNm }}</option>
                  <option v-if="regParams.podPortCd !== regParams.dlyPlcCd" :value="regParams.podCtrCd + ':' +regParams.podPortCd">{{ regParams.podPortNm }}</option>
                  <option v-if="regParams.podPortCd !== regParams.dlyPlcCd" :value="regParams.dlyCtrCd + ':' +regParams.dlyPlcCd">{{ regParams.dlyPlcNm }}</option>
                </select>
                <button
                  class="tbl_icon search ml2"
                  @click="searchAgentAddr"
                >
                  {{ $t('msg.NEWB010P020.006') }}
                </button>
                <select
                  class="wid200 ml20 bg_white float_right"
                  @change="changeTopMarkDscr($event, 'mrk')"
                >
                  <option value="">{{ $t('msg.CSBL200_M1.019') }}</option>
                  <option v-for="(vo, idx) in topMarkList" :key="'topMark_' + idx" :value="vo.mrk">{{ vo.mrkNm }}</option>
                </select>
              </th>
              <th class="text_left">
                Description
                <button
                  class="button sm ml2"
                  @click="addRequSentenceDescForVN"
                  v-show="regParams.porCtrCd === 'VN' && regParams.podPortCd === 'DMM'"
                >
                  REQUIRED SENTENCE
                </button>
                <button
                  class="button sm ml2"
                  @click="addTempClauseDescForVN"
                  v-show="regParams.porCtrCd === 'VN' && regParams.podPortCd === 'DMM'"
                >
                  Temp Clause
                </button>
                <select
                  class="wid200 ml20 bg_white float_right"
                  @change="changeTopMarkDscr($event, 'dscr')"
                >
                  <option value="">{{ $t('msg.CSBL200_M1.019') }}</option>
                  <option v-for="(vo, idx) in topDscrList" :key="'topDscr_' + idx" :value="vo.dscr">{{ vo.mrkNm }}</option>
                </select>
              </th>
              <th>
                <button
                  class="tbl_icon plus"
                  @click="addMarkDesc"
                >
                  plus
                </button>
              </th>
            </tr>
          </thead>
          <tbody v-for="(vo, idx) in regParams.markDescList" :key="'markDesc_' + idx">
            <tr>
              <td class="td_mrk" :style="idx > 0 ? '' : regParams.polShtmCd === '02' ? 'padding-top: 32px' : 'padding-top: 18px'">
                <ETextarea2 :id="'mrk_' + idx"
                            :max-line="18"
                            :column-length="25"
                            v-model="vo.mrk"
                            :value="vo.mrk"
                            :is-upper="true"
                            :show-line="18"
                />
              </td>
              <td>
                <textarea
                  v-if="idx === 0"
                  class="txt_desc1"
                  v-model="vo.dscrSub"
                  id="dscrSub"
                  readonly
                  rows="3"
                >
                </textarea>
                <ETextarea2 :id="'dscr_' + idx"
                            :max-line="idx > 0 ? 18 : regParams.polShtmCd === '02' ? 17 : 16"
                            :column-length="45"
                            class="txt_dscr"
                            v-model="vo.dscr"
                            :is-upper="true"
                            :show-line="18"
                />
              </td>
              <td>
                <button
                  v-if="idx !== 0"
                  class="tbl_icon minus"
                  @click="removeMarkDesc(idx)"
                >
                  minus
                </button>
              </td>
            </tr>
          </tbody>
        </table>

        <h2 class="content_title">Attached Files</h2>
        <table class="tbl_row mt10">
          <colgroup>
            <col width="170px">
            <col>
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('msg.CMBA100.00140') }}</th><!-- 첨부파일 -->
              <td class="text_left">
                <span>
                  <a v-show="$ekmtcCommon.isEmpty(showMsg)" :class="uploadFileList.length > 0 ? 'blue button sm ml2':'button sm ml2'" @click="fnAttachPop()">
                    {{ $t('msg.ONEX020P120.022') }}</a><!-- 파일첨부 -->
                </span>

                <span class="ml50">
                  <a :class="showUploadClass(regParams.riderYn)">Rider</a>
                  <a v-show="usrCtrCd === 'KR'" :class="showUploadClass(regParams.emanYn)" @click="showMessage()">{{ $t('msg.ARAI100_M1.003') }}</a> <!-- 면장 -->
                  <a :class="showUploadClass(regParams.dgcertiYn)" @click="showMessage()">DG-Certi</a>
                  <a v-show="usrCtrCd === 'KR'" :class="showUploadClass(regParams.vgmcertiYn)" @click="showMessage()">VGM-Certi</a>
                  <a v-show="regParams.dlyCtrCd === 'IR'" class="button sm ml2" @click="fnIranUploadPop('01')">LOI Upload</a>
                  <a v-show="usrCtrCd === 'KR'" :class="showUploadClass(regParams.etcYn)" @click="showMessage()">ETC</a>
                </span>
              </td>
            </tr>
          </tbody>
        </table>

        <table class="tbl_row mt10">
          <colgroup>
            <col width="170px">
            <col width="">
            <col width="170px">
            <col width="">
          </colgroup>
          <tbody>
            <tr v-if="lang === 'JPN'">
              <th>No. of B/L</th>
              <td>
                <e-input-number
                  id="oblPrtScnt"
                  v-model="regParams.oblPrtScnt"
                  maxlength="1"
                >
                </e-input-number>
              </td>
              <th>B/L Type</th>
              <td>
                <select
                  id="blCatCd"
                  v-model="regParams.blCatCd"
                >
                  <option value="">{{ $t('msg.CSBL200.075') }}</option>
                  <option value="01">O.B/L</option>
                  <option value="02">SeaWay Bill</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>Shipment No. <span class="txt">({{ $t('msg.CSBK100.062') }})</span></th>
              <td colspan="3">
                <e-trans-string-check-input :id="'shpmNo'"
                                            v-model="regParams.shpmNo"
                                            :is-upper-case="true"
                                            class="col_8"
                                            maxlength="10"
                />
              </td>
            </tr>
          </tbody>
        </table>
        <p
          v-show="regParams.dlyCtrCd === 'IR'"
          class="txt_desc"
        >
          {{ $t('msg.CSBL200.253') }}
        </p>
        <p
          v-show="regParams.jpPodCount > 0"
          class="txt_desc"
        >
          {{ $t('msg.CSBL200.254') }}<br>
          {{ $t('msg.CSBL200.254_1') }}
        </p>

        <h2 class="content_title">Request</h2>
        <table class="tbl_col">
          <colgroup>
            <col style="width:50%">
            <col style="width:50%">
          </colgroup>
          <thead>
            <tr>
              <th>Request</th>
              <th>Answer</th>
            </tr>
          </thead>
          <tbody>
            <template v-if="regParams.reqList.length > 0">
              <tr v-for="(vo, idx) in regParams.reqList" :key="'reqInfo_' + idx">
                <td>
                  <textarea
                    :value="vo.rmk"
                    @keyup="(e) => handleChange(e)"
                  >
                  </textarea>
                </td>
                <td>
                  <textarea
                    v-model="vo.rspsRmk"
                    readonly
                  >
                  </textarea>
                </td>
              </tr>
            </template>
            <template v-if="regParams.reqList.length === 0">
              <tr>
                <td>
                  <textarea
                    id="rmk"
                    :value="regParams.rmk"
                    @keyup="(e) => handleChange(e)"
                  >
                  </textarea>
                </td>
                <td>
                  <textarea
                    v-model="regParams.rspsRmk"
                    readonly
                  >
                  </textarea>
                </td>
              </tr>
            </template>
          </tbody>
        </table>

        <ul class="bul_list_sm lh_short">
          <li>
            {{ $t('msg.CSBL200.255') }}
          </li>
          <li>
            {{ $t('msg.CSBL200.256') }}
          </li>
          <li>
            {{ $t('msg.CSBL200.257') }}
          </li>
        </ul>
      </div>

      <div class="mt10 flex_box">
        <a class="button lg mr5" @click="fnCommonBtnAction('GOLIST')">{{ $t('msg.MAIN100.213') }}</a>
        <a v-if="saveFlag !== 'SR_ADD'" v-show="vueMode" class="button lg mr5" @click="templateSavePop()">Template Save</a>
        <!-- Template Save -->
        <span class="ml_auto">
          <a v-if="showButton() && lang === 'KOR'" class="button blue lg ml5" @click="fnCommonBtnAction('ERROR_CHECK')">B/L Error Check</a>
          <a v-if="showButton()" class="button blue lg ml5" @click="fnCommonBtnAction('VGMPOP_OPEN')">Input VGM</a>
          <a v-if="saveFlag !== 'SR_ADD'" class="button blue lg ml5" @click="print()">Preview</a>
          <a v-if="showSHAMFButton()" class="button blue lg ml5" @click="fnCommonBtnAction('SHAMF')">SHA M/F Data</a>
          <a v-if="saveFlag === 'SR_ADD'" class="button blue lg ml5" @click="saveBL('SAVE')">{{ $t('msg.CSBK100.178') }}</a>
          <a v-if="showSaveBtn()" class="button blue lg ml5" @click="saveBL('UPD')">{{ $t('msg.CSBK100.178') }}</a>
          <a v-if="saveFlag === 'SR_ADD' && regParams.polCtrCd !=='SG'" class="button blue lg ml5" @click="saveBL('SAVE_THEN')">{{ $t('msg.ONEX020P120.021') }}</a>
          <a v-if="saveFlag === 'SR_ADD' && regParams.polCtrCd ==='SG'" class="button blue lg ml5" @click="saveSgBL('SAVE_THEN')">{{ $t('msg.ONEX020P120.021') }}</a>
          <a v-if="showSaveBtnSubmit() && regParams.polCtrCd !=='SG'" class="button blue lg ml5" @click="saveBL('DCLR')">{{ $t('msg.ONEX020P120.021') }}</a>
          <a v-if="showSaveBtnSubmit() && regParams.polCtrCd ==='SG'" class="button blue lg ml5" @click="saveSgBL('DCLR')">{{ $t('msg.ONEX020P120.021') }}</a>
          <a v-if="showSaveBtn()" class="button gray lg ml5" @click="cancelSR()">{{ $t('msg.ONEX020P070.001') }}</a>
        </span>
      </div>
    </form>
    <win-layer-pop>
      <component
        :is="customComponent"
        v-if="customComponent"
        :parent-info="popParams"
        @close="closePopup"
        @selectFunc="selectFunc"
      />
    </win-layer-pop>

    <component
      v-if="commonBLComponent"
      :is="commonBLComponent"
      :parent-info="commonBLParams"
      ref="commonBL"
      @callback="commonBLCallback"
    />
  </div>
</template>

<script>
import { rootComputed } from '@/store/helpers'
import commons from '@/api/services/commons'
import trans from '@/api/rest/trans/trans'
import transCommon from '@/api/rest/trans/transCommon'
import BookingScheduleInfo from '@/components/trans/BookingScheduleInfo'
import EInputNumber from '@/components/common/EInputNumber'
import EDatePicker from '@/components/common/EDatePicker'
import ETransStringCheckInput from '@/components/trans/ETransStringCheckInput'
import ETransAddressInput from '@/components/trans/ETransAddressInput'
import ETransAddressTextarea from '@/components/trans/ETransAddressTextarea'
import ETransStringCheckCneeInput from '@/components/trans/ETransStringCheckCneeInput'
//import ETextareaLineColumn from '@/components/common/ETextareaLineColumn'
import ETextarea2 from '@/components/common/ETextarea2'
import blAttach from '@/api/rest/trans/blAttach'

export default {
  name: 'WorkingSR',
  components: {
    BookingScheduleInfo,
    EInputNumber,
    EDatePicker,
    ETransStringCheckInput,
    ETransAddressInput,
    ETransAddressTextarea,
    ETransStringCheckCneeInput,
    //ETextareaLineColumn,
    ETextarea2,
    'win-layer-pop': () => import('@/pages/trans/popup/WinLayerPop'),
    'bl-agent-address-pop': () => import('@/pages/trans/popup/BLAgentAddressPop'),
    'bl-on-board-date-pop': () => import('@/pages/trans/popup/BLOnBoardDatePop'),
    'bl-container-rf-pop': () => import('@/pages/trans/popup/BLContainerRFPop'),
    'bl-container-oog-pop': () => import('@/pages/trans/popup/BLContainerOOGPop'),
    'bl-container-dg-pop': () => import('@/pages/trans/popup/BLContainerDGPop'),
    'bl-container-cgo-pop': () => import('@/pages/trans/popup/BLContainerCgoPop'),
    'hs-code-ir-pop': () => import('@/pages/trans/popup/HsCodeIRPop'),
    'hs-code-id-pop': () => import('@/pages/trans/popup/HsCodeIDPop'),
    'hs-code-in-pop': () => import('@/pages/trans/popup/HsCodeINPop'),
    'bl-call-pop': () => import('@/pages/trans/popup/BLCallPop'),
    'common-bl': () => import('@/pages/trans/CommonBL'),
    'bl-attach-pop': () => import('@/pages/trans/popup/BLAttachPop'),
    'sr-notice-pop': () => import('@/pages/trans/popup/SrNoticePop'),
    'bl-copy-itext-pop': () => import('@/pages/pdf/popup/BlCopyItextPop'),
    'iran-upload-pop': () => import('@/pages/trans/popup/IranUploadPop'),
    'container-excel-upload-pop': () => import('@/pages/trans/popup/ContainerExcelUploadPop'),
    'template-save-pop': () => import('@/pages/trans/popup/TemplateSavePop'),
    'bl-vgm-noti-pop': () => import('@/pages/trans/popup/BLVgmNoticePop')
  },
  props: {
    detailBkgNo: {
      type: String,
      default: ''
    },
    detailSrRno: {
      type: String,
      default: ''
    },
    newSrNo: {
      type: String,
      default: ''
    },
    detailArrBkgNo: {
      type: Array,
      default: () => {
        return []
      }
    },
    srCnclYn: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      lang: '',
      usrCtrCd: '',
      cstCatCd: '',
      saveFlag: 'SR_ADD',
      userHkSg: '',
      selectBeforeVal: '',
      commonCodes: {},
      plcEnmCodes: {},
      cstEnmCodes: {},
      ctrCdList: [],
      ctrCdIccList: [],
      actCstIdList: [],
      cneCstIdList: [],
      notiCstIdList: [],
      cgoTypCdList: [],
      issPlcList: [],
      frtPayPlcList: [],
      topMarkList: [],
      topDscrList: [],
      deckCdList: [],
      pkgCdList: [],
      indiaCityList: [],
      blEdiTypCdList: [],
      isCstId: false, // Company ID 입력란 생성 여부
      addrStr: '',
      isPolHsMulti: false,
      isPodHsMulti: false,
      saveCntrCnt: 1,
      customComponent: null,
      popParams: {},
      selectFunc: {},
      commonBLComponent: null,
      commonBLParams: {},
      commonBLCallback: null,
      porPlcNmType: 'select',
      polPortNmType: 'select',
      podPortNmType: 'select',
      dlyPlcNmType: 'select',
      freeTime: '',
      serverYear: '',
      serverMonth: '',
      serverDay: '',
      saveType: '',
      dscrStr: '',
      byStr: '',
      templateNo: '',
      templateNo_2: '',
      originCntrList: [],
      isShipperLOI: false,
      isShipperLOIPlc: true, // Shipper LOI 첨부 가능지역여부
      saveShipperLOI: '',
      closeCntr: false,
      closeEl: false,
      rfDisabled: false,
      excelUploadShow: true,
      elNoValdDisbled: false,
      showMsg: '',
      cntrVerifyPass: 'N',
      requestedYn: '',
      hsCdPopLoad: 'N',
      phbtAlertMsg: [],
      hsChkParams: {
        maxlengthPolHsCd: '6',
        maxlengthPodHsCd: '6',
        hscdPolErrorMessage: '',
        hscdPodErrorMessage: '',
        polCtrCd: '',
        podCtrCd: '',
        polSuccYn: '',
        podSuccYn: ''
      },
      originParams: {},
      pkgInfo: '',
      regParams: {
        bkgNo: '',
        srNo: '',
        blPrtrCont: '',
        bizPicNm: '',
        bizPicTelPlcNo: '',
        bizPicTelOfcNo: '',
        bizPicFaxPlcNo: '',
        bizPicFaxOfcNo: '',
        bizPicMbpBsnNo: '',
        bizPicMbpOfcNo: '',
        bizPicEmlAddr: '',
        shprCstEnm: '',
        shprCstAddr: '',
        actShprCstCtrCd: '',
        actShprStateCd: '',
        cneCstCtrCd: '',
        cneCstStateCd: '',
        cneCstEnm: '',
        cneCstAddr: '',
        cneNatlId: '', // 도착국가 : 이란
        ntifCstCtrCd: '',
        ntifCstStateCd: '',
        ntifCstEnm: '',
        ntifCstAddr: '',
        ntfyNatlId: '', // 도착국가 : 이란
        porPlcNm: '', // Place of Receipt
        polPortNm: '', // Port of Loading
        podPortNm: '', // Port of Discharge
        dlyPlcNm: '', // Place of Delivery
        lstDestPlcNm: '', // Final Destination
        obrdDt: '',
        frtPncCd: 'P',
        polShtmCd: '',
        podShtmCd: '',
        grsCbm: '',
        grsWt: '',
        actShprIdCatCd: '',
        actShprIdNo: '',
        cneIdCatCd: '',
        cneIdNo: '',
        ntifIdCatCd: '',
        ntifIdNo: '',
        pkgCd: '',
        blDclrYn: 'N',
        issCtrCd: '',
        issPlcCd: '',
        frtPayCtrCd: '',
        frtPayPlcCd: '',
        blEdiTypCd: 'S',
        srCstrSbmtYn: 'N',
        frtAppNo: '',
        exportLicenseList: [],
        containerList: [],
        markDescList: [],
        srList: [],
        reqList: [],
        addBkgNoList: [],
        tplCatCd: '02',
        tsCgoChoYn: '', //환적화물 여부
        vslFlagYn: '',
        consCgoYn: '',
        podHsCdNm: '',
        machineRmk: '',
        blTypCd: ''
      },
      uploadFileList: [],
      timer: null,
      vueMode: true, //process.env.VUE_APP_MODE !== 'PRD'
      templateList: [],
      templateCd: 'A',
      consCgoDisYn: '',
      blTypeVisibleContry: []
    }
  },
  computed: {
    ...rootComputed,
    isJpn: function () {
      let b = false
      if (this.auth.login) {
        b = this.auth.userCtrCd === 'JP'
      } else {
        b = this.serviceLang === 'JPN'
      }

      return b
    }
  },
  watch: {
    'regParams.dlyPlcNm' () {
      this.changeLstDestPlcNm()
    }
  },
  created () {
    this._ = require('lodash')
    this.regParams.bkgNo = this.detailBkgNo
    this.regParams.srNo = this.detailSrRno

    if (this.$ekmtcCommon.isNotEmpty(this.detailSrRno) && this.$ekmtcCommon.isEmpty(this.newSrNo)) {
      this.saveFlag = 'SR_EDIT'
    }

    if (this.$ekmtcCommon.isNotEmpty(this.newSrNo)) {
      this.regParams.srNo = this.newSrNo
    }

    if (this.srCnclYn === 'Y') {
      this.regParams.srNo = ''
      this.saveFlag = 'SR_ADD'
    }

    if (this.auth !== undefined) {
      this.lang = this.$ekmtcCommon.isNotEmpty(this.auth.serviceLang) ? this.auth.serviceLang : 'KOR'
      this.cstCatCd = this.auth.cstCatCd
    }

    this.usrCtrCd = this.memberDetail.userCtrCd

    // Shipper LOI 첨부 불가지역 체크
    if (['JP', 'TH', 'HK', 'SG', 'IN'].indexOf(this.usrCtrCd) >= 0) {
      this.isShipperLOIPlc = false
    // } else if (this.usrCtrCd === 'CN' && ['CAN', 'XMN'].indexOf(this.memberDetail.bkgPlcCd) >= 0) { // 중국은 이 페이지로 안오는것 같음
    //   this.isShipperLOIPlc = false
    } else {
      this.isShipperLOIPlc = true
    }

    if (this.detailArrBkgNo.length > 0) {
      this.detailArrBkgNo.forEach(vo => {
        this.regParams.addBkgNoList.push(vo)
      })
    }

    this.init()
  },
  methods: {
    async init () {
      // 01099 : 수출신고필증
      // 01032 : OCEAN FREIGHT 종류
      // 01130 : OCEAN FREIGHT 종류
      // 01031 : 컨테이너 사이즈
      // 01068 : 컨테이너 타입
      // 01033 : POD SHIPPING TERM
      // 01038 : DG
      // 01278 : 요청사항
      // CS038 : 인도 STATE
      this.fnCommonBtnAction('')
      const arrCdId = ['01099', '01032', '01130', '01031', '01068', '01033', '01038', '01208', '01278', 'CS038', '00003', 'CS003', 'CS004', 'EQ051', 'CS105', 'WB208']
      await commons.getCommonsCodesCommon(arrCdId).then(res => {
        this.commonCodes = res

        const cgoTypCdList = this.commonCodes['01038'].filter(vo => vo.cd !== ' ' && vo.cd !== '04')
        this.cgoTypCdList = cgoTypCdList

        const pkgCdList = this.commonCodes['01130']
        const deckCdList = this.commonCodes['01208']
        const indiaCityList = this.commonCodes.CS038

        pkgCdList.sort((a, b) => {
          return a.cdNm < b.cdNm ? -1 : a.cdNm > b.cdNm ? 1 : 0
        })

        deckCdList.sort((a, b) => {
          return a.cd < b.cd ? -1 : a.cd > b.cd ? 1 : 0
        })

        indiaCityList.sort((a, b) => {
          return a.cdNm < b.cdNm ? -1 : a.cdNm > b.cdNm ? 1 : 0
        })

        this.pkgCdList = pkgCdList
        this.deckCdList = deckCdList
        this.indiaCityList = indiaCityList

        //bl Type display contry
        this.commonCodes.WB208.forEach((v) => {
          this.blTypeVisibleContry.push(v.cdNm)
        })
      })
      await this.initBlBkgInfo()

      let blEdiTypCdList = this.commonCodes['01099']
      if (this.regParams.emptyFullYn === 'Y') {
        blEdiTypCdList = blEdiTypCdList.filter(vo => vo.cd !== 'E')
      }
      this.blEdiTypCdList = blEdiTypCdList

      const regParams = this.regParams

      const arrPlcCd = [regParams.porPlcCd, regParams.polPortCd, regParams.podPortCd, regParams.dlyPlcCd]
      const arrPromise = [
        commons.getPlcStrEnmInfo(arrPlcCd).then(res => {
          this.plcEnmCodes = res

          this.setInputType()
        }),
        commons.getCommonsCodesCountry().then(res => {
          this.ctrCdList = res
          if (this.saveFlag === 'SR_EDIT') {
            this.checkShpperLOI()
          }
        }),
        commons.getCommonsCodesCountry('Y').then(res => {
          this.ctrCdIccList = res
        }),
        trans.fnBlCstEnmInfo().then(res => {
          this.cstEnmCodes = res
        }),
        trans.findTopMark().then(res => {
          this.topMarkList = res
        }),
        trans.findTopDscr().then(res => {
          this.topDscrList = res
        }),
        commons.getServerTime().then(res => {
          this.serverYear = res.data.substring(0, 4)
          this.serverMonth = res.data.substring(4, 6)
          this.serverDay = res.data.substring(6, 8)
        })
      ]

      if (Number(regParams.cnPodCount) > 0 || regParams.polCtrCd === 'MY' || Number(regParams.shaPodTsCount) > 0) {
        this.isCstId = true
        arrPromise.push(
          this.fnChangeActCatId(regParams.actShprCstCtrCd, 'INIT')
        )

        arrPromise.push(
          this.fnChangeCneCatId(regParams.cneCstCtrCd, 'INIT')
        )

        arrPromise.push(
          this.fnChangeNotiCatId(regParams.ntifCstCtrCd, 'INIT')
        )
      }

      if (this.saveFlag === 'SR_ADD' && this.$ekmtcCommon.isEmpty(this.regParams.srNo)) { // B/L에서 part S/R 추가시 srNo 존재 (props > newSrNo)
        const params = {
          polPortCd: this.regParams.polPortCd,
          porPlcCd: this.regParams.porPlcCd
        }
        arrPromise.push(
          trans.fnGetSrNoTemp(params).then(res => {
            this.regParams.srNo = res.data

            this.$set(this.regParams, 'srList', [{ srNo: res.data, saveFlag: 'SR_ADD' }])
          })
        )
      } else {
        if (this.lang === 'JPN') {
          if (this.$ekmtcCommon.isNotEmpty(this.regParams.issCtrCd)) {
            arrPromise.push(
              commons.getPlcCdList(this.regParams.issCtrCd).then(res => {
                this.$set(this, 'issPlcList', res)
              })
            )
          }

          if (this.$ekmtcCommon.isNotEmpty(this.regParams.frtPayCtrCd)) {
            arrPromise.push(
              commons.getPlcCdList(this.regParams.frtPayCtrCd).then(res => {
                this.$set(this, 'frtPayPlcList', res)
              })
            )
          }
        }
      }
      //if (this.saveFlag === 'SR_ADD') {
        console.log('@@@@@@@@@@@@@@@@@@', this.regParams)
        this.regParams.tplCatCd = '02'
        //this.regParams.templateCd = 'A'
        await trans.getTemplateList(this.regParams).then((res) => {
          this.templateList = res.data
          console.log('@@@@@@@@@@@@@@@@@@', this.templateList)
        })
     // }

      if (this.$ekmtcCommon.isNotEmpty(this.regParams.addBkgNoList) && this.regParams.addBkgNoList.length > 0) {
        arrPromise.push(
          trans.getShprVanInfoMulti(this.regParams.addBkgNoList).then(res => {
            if (this.$ekmtcCommon.isNotEmpty(res)) {
              this.regParams.shprVanYn = res.data
            }
          })
        )
      }

      // JamSin 도착지가 ID 인 경우 TAX NO 숫자만 남기고 제거
      if (this.regParams.podCtrCd === 'ID' || this.regParams.podCtrCd === 'JO' || this.regParams.podCtrCd === 'TZ') {
        this.regParams.idTaxNo = this.regParams.idTaxNo.replace(/[^0-9]/gi, '')
      }
      // JamSin 출발지가 ID 인 경우 TAX NO 숫자만 남기고 제거
      if (this.regParams.polCtrCd === 'ID') {
        this.regParams.etTaxNo = this.regParams.etTaxNo.replace(/[^0-9]/gi, '')
      }

      await Promise.all(arrPromise)
      this.originParams = this._.cloneDeep(this.regParams)

      this.setFileInfo()
    },
    consCgoYnCheck (e) {
      if (e.target.checked) {
        this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.285'))
      }
    },
    setBlBkgInfo (obj) {
      const originSrList = this.regParams.srList

      const srNo = this.regParams.srNo
      if (obj !== undefined) {
        const defaults = {
          srNo: srNo,
          bkgNo: '',
          bizPicNm: '',
          bizPicTelPlcNo: '',
          bizPicTelOfcNo: '',
          bizPicFaxPlcNo: '',
          bizPicFaxOfcNo: '',
          bizPicMbpBsnNo: '',
          bizPicMbpOfcNo: '',
          bizPicEmlAddr: '',
          shprCstEnm: '',
          shprCstAddr: '',
          actShprCstCtrCd: '',
          actShprStateCd: '',
          cneCstCtrCd: '',
          cneCstStateCd: '',
          cneCstEnm: '',
          cneCstAddr: '',
          cneNatlId: '', // 도착국가 : 이란
          ntifCstCtrCd: '',
          ntifCstStateCd: '',
          ntifCstEnm: '',
          ntifCstAddr: '',
          ntfyNatlId: '', // 도착국가 : 이란
          porPlcNm: '', // Place of Receipt
          polPortNm: '', // Port of Loading
          podPortNm: '', // Port of Discharge
          dlyPlcNm: '', // Place of Delivery
          lstDestPlcNm: '', // Final Destination
          obrdDt: '',
          frtPncCd: 'P',
          polShtmCd: '',
          podShtmCd: '',
          grsCbm: '',
          grsWt: '',
          actShprIdCatCd: '',
          actShprIdNo: '',
          cneIdCatCd: '',
          cneIdNo: '',
          ntifIdCatCd: '',
          ntifIdNo: '',
          acidNo: '',
          cneAcidNo: '',
          pkgCd: '',
          blDclrYn: 'N',
          issCtrCd: '',
          issPlcCd: '',
          frtPayPlcCd: '',
          invValCurCd: '',
          blEdiTypCd: 'S',
          exportLicenseList: [],
          containerList: [],
          markDescList: [],
          cgoTypList: [],
          srList: [],
          reqList: [],
          addBkgNoList: this.regParams.addBkgNoList,
          machineRmk: this.regParams.machineRmk,
          inTsYn: ''
        }
        this.regParams = { ...defaults, ...obj }

        if (originSrList.length > 0 && this.$ekmtcCommon.isNotEmpty(originSrList[0].srNo)) {
          this.$set(this.regParams, 'srList', originSrList)
        } else {
          this.$set(this.regParams, 'srList', [{ srNo: srNo, saveFlag: this.saveFlag }])
        }

        this.regParams.obrdDtTemp = this.$ekmtcCommon.changeDatePattern(this.regParams.obrdDt, '.')

        if (this.saveFlag === 'SR_ADD') {
          this.regParams.etaObrdDt = this.regParams.obrdDt

          this.regParams.actShprCstCtrCd = this.regParams.polCtrCd
          this.regParams.cneCstCtrCd = this.regParams.dlyCtrCd
          this.regParams.ntifCstCtrCd = this.regParams.dlyCtrCd

          this.regParams.cneCstPicNm = this.regParams.cnePicNm !== '' ? this.regParams.cnePicNm : ''
          this.regParams.cneCstTelNo = this.regParams.cnePicTelNo !== '' ? this.regParams.cnePicTelNo : ''
          this.regParams.cneCstFaxNo = this.regParams.cnePicFaxNo !== '' ? this.regParams.cnePicFaxNo : ''
          this.regParams.cneCstEmlAddr = this.regParams.cnePicEmlAddr !== '' ? this.regParams.cnePicEmlAddr : ''
        } else {
          this.checkShpperLOI()
        }

        const regParams = this.regParams
        if (regParams.polCtrCd === 'ID' || regParams.polCtrCd === 'PK') {
          this.hsChkParams.maxlengthPolHsCd = '8'
        }
        //일본사용자 아닌 경우, Pay 자동세팅.
        if (regParams.polCtrCd !== 'JP') {
          if (this.regParams.frtPncCd === 'P') {
            this.regParams.frtPayCtrCd = this.regParams.polCtrCd
            this.regParams.frtPayPlcCd = this.regParams.polPortCd
          } else {
            this.regParams.frtPayCtrCd = this.regParams.podCtrCd
            this.regParams.frtPayPlcCd = this.regParams.podPortCd
          }
        }
        if (regParams.dlyCtrCd === 'PK') {
          this.hsChkParams.maxlengthPodHsCd = '8'
        }

        this.hsChkParams.polCtrCd = regParams.polCtrCd

        if (regParams.cgoModeCd === '01' || regParams.cgoModeCd === '03') {
          if (regParams.dlyCtrCd === 'IN') {
            this.hsChkParams.maxlengthPodHsCd = '6'
          } else if (regParams.dlyCtrCd === 'ID' || regParams.dlyCtrCd === 'VN') {
            this.hsChkParams.maxlengthPodHsCd = '8'
          } else if (regParams.shaTsYn === 'Y') {
            this.hsChkParams.maxlengthPodHsCd = '10'
          }
        } else if (regParams.dlyCtrCd === 'IR') {
          this.hsChkParams.maxlengthPodHsCd = '10'
        }

        if (regParams.polShtmCd === '02' && this.$ekmtcCommon.isNotEmpty(regParams.blNo)) {
          regParams.blCntCfs = 'Y'
        } else {
          regParams.blCntCfs = 'N'
        }

        this.hsChkParams.podCtrCd = regParams.dlyCtrCd

        if (regParams.shaTsYn === 'Y') {
          this.hsChkParams.podCtrCd = 'SHA'
        }
      }

      if (this.regParams.consCgoYn === 'Y') {
        this.consCgoDisYn = 'Y'
      }

      if (this.regParams.exportLicenseList.length === 0) {
        this.addExportLicense()

        if (this.saveFlag === 'SR_EDIT') {
          this.regParams.exportLicenseList[0].pkgCd = this.regParams.pkgCd
          this.fnSumValue('grsWt')
          this.fnSumValue('pkgQty')
        }
      } else {
        this.regParams.exportLicenseList.forEach(e => {
          if (Number(e.ldgTcnt) === 0) {
            e.ldgTcnt = ''
          }
        })
      }

      if (this.regParams.containerList.length === 0) {
        this.addBLContainer()
      } else {
        this.regParams.containerList.forEach(c => {
          if (this.$ekmtcCommon.isEmpty(c.sealNo1) || this.$ekmtcCommon.isEmpty(c.sealNo1.trim())) {
            //c.sealList = [{ sealNo: '' }]
            this.$set(c, 'sealList', [{ sealNo: '' }])
          } else {
            const sealList = []
            for (let i = 1; i <= 5; i++) {
              if (this.$ekmtcCommon.isNotEmpty(c['sealNo' + i]) && this.$ekmtcCommon.isNotEmpty(c['sealNo' + i].trim())) {
                sealList.push({ sealNo: c['sealNo' + i] })
              }
            }
            this.$set(c, 'sealList', sealList)
          }
        })
      }

      const trdFileInfo = this.regParams.trdFileInfo
      if (this.$ekmtcCommon.isNotEmpty(trdFileInfo) && this.$ekmtcCommon.isNotEmpty(trdFileInfo.reqRno)) {
        this.saveShipperLOI = 'Y'
      }

      /* if (this.$ekmtcCommon.isNotEmpty(this.regParams.invIssNo)) {
        const frm = document.querySelector('#frm_sr')
        const blTypCd = frm.querySelector('#blTypCd')

        blTypCd.disabled = true
      } */

      if (this.regParams.markDescList.length === 0) {
        this.addMarkDesc()
        this.fnChangeContSz()
        this.fnPackage('INIT')
      } else {
        this.markDescEnterSet()
      }

      this.initDesc()

      this.isMultiPolHsCode()
      this.isMultiPodHsCode()

      if (this.auth !== undefined && this.auth.userId === 'CKLINE' && this.regParams.blDclrYn !== 'Y') {
        this.$nextTick(() => {
          const frm = document.querySelector('#frm_sr')
          frm.querySelector('#polShtmCd').disabled = false
          frm.querySelector('#podShtmCd').disabled = false

          this.regParams.containerList.forEach((c, idx) => {
            frm.querySelector('#lcsPolShtmCd_' + idx).disabled = false
            frm.querySelector('#lcsPodShtmCd_' + idx).disabled = false
          })
        })
      }

      this.regParams.containerList.forEach(c => {
        if (this.isPolShtmListChange()) {
          c.polShtmCdList = this.commonCodes['01033'].filter(vo => vo.cd === '01' || vo.cd === '02')
        } else {
          c.polShtmCdList = this.commonCodes['01033']
        }
      })

      if (this.saveFlag === 'SR_EDIT') {
        this.originCntrList = this._.cloneDeep(this.regParams.containerList)
        this.initPkgInfo()
        this.originParams = this._.cloneDeep(this.regParams)
      }

      if (this.hsCdPopLoad === 'Y') { // 저장 후 refresh 시 팝업 로드
        const catCd = this.regParams.polCtrCd === 'IN' ? '01' : '02'
        this.hsCdMultiPop(catCd)
        this.hsCdPopLoad = 'N'
      }

      let isCgoTypeO1 = false
      if (this.regParams.cgoTypList.length > 0) {
        this.regParams.cgoTypList.forEach(c => {
          if (c.cgoTypCd === '01') { isCgoTypeO1 = true }
        })

        if (isCgoTypeO1 && this.regParams.podPortCd === 'SGN' && (this.regParams.dlyPlcCd === 'TNT' || this.regParams.dlyPlcCd === 'TCP')) {
            this.regParams.lstDestPlcNm = this.regParams.dlyPlcNm
            $('#lstDestPlcNm').prop('disabled', true)
        }
      }
    },
    initPkgInfo () {
      const regParams = this.regParams
      const pkgQty = this.regParams.pkgQty
      const pkgCd = this.regParams.pkgCd

      const pkgInfo = this.commonCodes['01130'].filter(vo => vo.cd === pkgCd)[0]
      let pkgNm = ''

      if (pkgInfo !== undefined) {
        pkgNm = pkgInfo.cdNm
      }

      if (this.$ekmtcCommon.isNotEmpty(pkgQty) && this.$ekmtcCommon.isNotEmpty(pkgCd)) {
        this.pkgInfo = this.$ekmtcCommon.changeNumberFormat(pkgQty, { isComma: true }) + pkgNm
      }
    },
    async initBlBkgInfo () {
      const params = {
        bkgNo: this.regParams.bkgNo,
        srNo: this.regParams.srNo
      }

      // S/R 정보 조회
      await trans.fnSrBkgInfo(params).then(res => {
        const result = res.data

        if (result !== undefined) {
          this.setBlBkgInfo(result)
        }
      })
    },
    initStatus () {
      const regParams = this.regParams

      let closeYn = '0'
      let isDisabled = false

      if (regParams.ediYn === 'Y') {
        if (this.auth.userId !== 'CKLINE') {
          this.showMsg = this.$t('art.CMATK370')
          this.isDisabled = true
        }
      } else if (regParams.cllYn === 'Y') {
        if (this.auth.userId !== 'CKLINE') {
          this.showMsg = this.$t('art.CMATK356')
          isDisabled = true
        }
      } else if (regParams.blDclrYn === 'Y') {
        this.showMsg = this.$t('art.CMATK356')
        isDisabled = true
      }

      if (isDisabled) {
        this.fnValidation(true)
      }
    },
    fnValidation (flag) {
      const frm = document.querySelector('#frm')
      const inputEl = frm.getElementsByTagName('input')
      for (const el of inputEl) {
        el.disabled = flag
        this.elNoValdDisbled = true
      }

      const selectEl = frm.getElementsByTagName('select')
      for (const el of selectEl) {
        el.disabled = flag
      }

      const textareaEl = frm.getElementsByTagName('textarea')
      for (const el of textareaEl) {
        el.disabled = flag
      }

      if (flag) {
        this.closeCntr = true
        this.closeEl = true
        this.rfDisabled = true
        this.excelUploadShow = false
      }
    },
    templateSearch () {
      this.popParams = {
        templateNo: this.templateNo
      }
      this.selectFunc = this.getTemplateInfo
      this.openPopup('bl-call-pop')
    },
    templateEnter (e) {
      if (e.keyCode === 13) {
        this.templateSearch()
      }
    },
    setTemplateInfo () {
      const blNo = this.templateCd === 'A' ? this.templateNo : this.templateNo_2

      let succFlag = true
      let result = null
      trans.findBlTemplateInfo(blNo).then(res => {
        if (res.headers.respcode === 'C0000') {
          result = res.data
        } else {
          succFlag = false
        }
      }).catch(() => {
        succFlag = false
      }).finally(() => {
        if (succFlag) {
          this.setTemplateDetail(result)
        } else {
          //this.$ekmtcCommon.alertDefault(this.$t('js.common.005'))
          if (this.regParams.templateCd === 'A') {
           this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.235'))
          } else {
            this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.078'))
          }
        }
       // result.templateCd = this.regParams.templateCd
      })
    },
    setTemplateDetail (obj) {
      const defaults = this.regParams
      const detail = { ...defaults, ...obj }

      this.regParams = {}
      this.regParams = detail

      const param = this.regParams

      const shprCstEnm = param.shprCstEnm || ''
      const shprCstAddr = param.shprCstAddr || ''
      const shprCstNo = param.shprCstNo || ''
      const cneCstEnm = param.cneCstEnm || ''
      const cneCstAddr = param.cneCstAddr || ''
      const cneCstNo = param.cneCstNo || ''
      const ntifCstEnm = param.ntifCstEnm || ''
      const ntifCstAddr = param.ntifCstAddr || ''
      const ntifCstNo = param.ntifCstNo || ''
      const hcneCstEnm = param.hcneCstEnm || ''
      const hcneCstAddr = param.hcneCstAddr || ''
      const hcneCstNo = param.hcneCstNo || ''
      const cgoCont = param.cgoCont || ''

      const regParams = this.regParams

      // Template 일 경우 input를 수정하면 대문자로 바뀌지만 그대로 저장할 경우 소문자로 들어가므로 대문자로 변경
      regParams.shprCstEnm = shprCstEnm.split('`').join('\'').split('\\n').join('\r\n').toUpperCase()
      regParams.shprCstAddr = shprCstAddr.split('`').join('\'').split('\\n').join('\r\n')
      //regParams.shprCstNo = shprCstNo.split('`').join('\'').split('\\n').join('\r\n')
      regParams.shprCstNo = '' // Template 일 경우는 cstNo 초기화
      // Template 일 경우 input를 수정하면 대문자로 바뀌지만 그대로 저장할 경우 소문자로 들어가므로 대문자로 변경
      regParams.cneCstEnm = cneCstEnm.split('`').join('\'').split('\\n').join('\r\n').toUpperCase()
      regParams.cneCstAddr = cneCstAddr.split('`').join('\'').split('\\n').join('\r\n')
      //regParams.cneCstNo = cneCstNo.split('`').join('\'').split('\\n').join('\r\n')
      regParams.cneCstNo = '' // Template 일 경우는 cstNo 초기화
      // Template 일 경우 input를 수정하면 대문자로 바뀌지만 그대로 저장할 경우 소문자로 들어가므로 대문자로 변경
      regParams.ntifCstEnm = ntifCstEnm.split('`').join('\'').split('\\n').join('\r\n').toUpperCase()
      regParams.ntifCstAddr = ntifCstAddr.split('`').join('\'').split('\\n').join('\r\n')
      //regParams.ntifCstNo = ntifCstNo.split('`').join('\'').split('\\n').join('\r\n')
      regParams.ntifCstNo = '' // Template 일 경우는 cstNo 초기화
      // Template 일 경우 input를 수정하면 대문자로 바뀌지만 그대로 저장할 경우 소문자로 들어가므로 대문자로 변경
      regParams.hcneCstEnm = hcneCstEnm.split('`').join('\'').split('\\n').join('\r\n').toUpperCase()
      regParams.hcneCstAddr = hcneCstAddr.split('`').join('\'').split('\\n').join('\r\n')
      //regParams.hcneCstNo = hcneCstNo.split('`').join('\'').split('\\n').join('\r\n')
      regParams.hcneCstNo = '' // Template 일 경우는 cstNo 초기화
      regParams.cgoCont = cgoCont.split('`').join('\'').split('\\n').join('\r\n')
      //불러오기 시, 아래 항목 초기화
      regParams.cnePicNm = ''

      regParams.markDescList.forEach(c => {
        if (c.mrk) c.mrk = c.mrk.split('`').join('\'').split('\\n').join('\r\n')
        if (c.dscr) c.dscr = c.dscr.split('`').join('\'').split('\\n').join('\r\n')
      })

      this.initDesc()
      this.$ekmtcCommon.alertDefault(this.$t('tem.CMAT015'))
    },
    getTemplateInfo (obj) {
      const blNo = obj.blNo
      this.templateNo = blNo

      this.setTemplateInfo() // 자동 적용
    },
    initDesc () {
      const regParams = this.regParams
      let descStatic = ''
      const markDesc = this.regParams.markDescList[0]

      if (this.$ekmtcCommon.isNotEmpty(markDesc.dscr.trim())) {
        let dscr = markDesc.dscr

        if (this.regParams.polShtmCd === '02') {
          descStatic = '"SAID TO BE:"\r\n'
        } else {
          descStatic = '"SHIPPER\'S LOAD, COUNT & SEAL"\r\n'
          descStatic += '"SAID TO CONTAIN"\r\n'
        }

        if (dscr.indexOf('"SHIPPER\'S LOAD, COUNT & SEAL"') > -1) {
          dscr = dscr.replace('"SHIPPER\'S LOAD, COUNT & SEAL"', '').replace(/^\s*/, '')
        }

        if (dscr.indexOf('"SAID TO CONTAIN"') > -1) {
          dscr = dscr.replace('"SAID TO CONTAIN"', '').replace(/^\s*/, '')
        }

        if (dscr.indexOf('"SAID TO BE:"') > -1 || dscr.indexOf('"SAID TO BE : "') > -1) {
          dscr = dscr.replace('"SAID TO BE:"', '').replace(/^\s*/, '')
          dscr = dscr.replace('"SAID TO BE : "', '').replace(/^\s*/, '')
        }

        markDesc.dscrSub = descStatic
        markDesc.dscr = dscr
      } else {
        if (this.regParams.polShtmCd === '02') {
          descStatic = '"SAID TO BE:"\r\n'
        } else {
          descStatic = '"SHIPPER\'S LOAD, COUNT & SEAL"\r\n'
          descStatic += '"SAID TO CONTAIN"\r\n'
        }

        markDesc.dscrSub = descStatic
        //markDesc.dscr = markDesc.dscr
      }
    },
    fnChangeActCatId (ctrCd, flag) {
      if (this.$ekmtcCommon.isEmpty(ctrCd)) {
        ctrCd = this.regParams.polCtrCd
      }

      if (flag === undefined) {
        flag = 'CHANGE'
      }

      transCommon.getCatId(ctrCd)
        .then(res => {
          if (flag !== 'INIT') {
            this.regParams.actMin = ''
            this.regParams.actMax = ''
            this.regParams.actShprIdNo = ''
            this.regParams.actShprIdCatCd = ''
          }
          this.actCstIdList = res.data
        })
    },
    fnChangeCneCatId (ctrCd, flag) {
      if (this.$ekmtcCommon.isEmpty(ctrCd)) {
        ctrCd = this.regParams.podCtrCd
      }

      if (flag === undefined) {
        flag = 'CHANGE'
      }

      transCommon.getCatId(ctrCd)
        .then(res => {
          if (flag !== 'INIT') {
            this.regParams.cneMin = ''
            this.regParams.cneMax = ''
            this.regParams.cneIdNo = ''
            this.regParams.cneIdCatCd = ''
          }
          this.cneCstIdList = res.data
        })
    },
    fnChangeNotiCatId (ctrCd, flag) {
      if (this.$ekmtcCommon.isEmpty(ctrCd)) {
        ctrCd = this.regParams.podCtrCd
      }

      if (flag === undefined) {
        flag = 'CHANGE'
      }

      transCommon.getCatId(ctrCd)
        .then(res => {
          if (flag !== 'INIT') {
            this.regParams.ntifMin = ''
            this.regParams.ntifMax = ''
            this.regParams.ntifIdNo = ''
            this.regParams.ntifIdCatCd = ''
          }
          this.notiCstIdList = res.data
        })
    },
    fnChangeCatId (e, flag) {
      const val = e.target.value
      const regParams = this.regParams
      if (flag === 'ACT') {
        if (val !== '') {
          const vo = this.actCstIdList.find(vo => vo.cstIdCatCd === val) || {}
          regParams.actShprIdCatCd = val
          regParams.actMin = vo.minInpLen || ''
          regParams.actMax = vo.maxInpLen || ''
        } else {
          regParams.actShprIdCatCd = ''
          regParams.actMin = ''
          regParams.actMax = ''
        }

        this.checkValidationInfo('actShprIdCatCd')
      } else if (flag === 'CNE') {
        if (val !== '') {
          const vo = this.cneCstIdList.find(vo => vo.cstIdCatCd === val) || {}
          regParams.cneIdCatCd = val
          regParams.cneMin = vo.minInpLen || ''
          regParams.cneMax = vo.maxInpLen || ''
        } else {
          regParams.cneIdCatCd = ''
          regParams.cneMin = ''
          regParams.cneMax = ''
        }

        if (regParams.polPortCd === 'PKG' || regParams.polPortCd === 'PKW') {
          if (regParams.cneIdCatCd !== '') {
            this.checkValidationInfo('cneIdCatCd')
          }
        } else {
          this.checkValidationInfo('cneIdCatCd')
        }
      } else if (flag === 'NTIF') {
        if (val !== '') {
          const vo = this.notiCstIdList.find(vo => vo.cstIdCatCd === val) || {}
          regParams.ntifIdCatCd = val
          regParams.ntifMin = vo.minInpLen || ''
          regParams.ntifMax = vo.maxInpLen || ''
        } else {
          regParams.ntifIdCatCd = ''
          regParams.ntifMin = ''
          regParams.ntifMax = ''
        }

        if (regParams.polPortCd === 'PKG' || regParams.polPortCd === 'PKW') {
          if (regParams.ntifIdCatCd !== '') {
            this.checkValidationInfo('ntifIdCatCd')
          }
        } else {
          this.checkValidationInfo('ntifIdCatCd')
        }
      }
    },
    fnAddInfoStateSet (flag) {
      if (flag === 'ACT') {
        this.$set(this.regParams, 'actShprStateCd', '')
        this.$set(this.regParams, 'actShprStateNm', '')
      } else if (flag === 'CNE') {
        this.$set(this.regParams, 'cneCstStateCd', '')
        this.$set(this.regParams, 'cneCstStateNm', '')
      } else if (flag === 'NTIF') {
        this.$set(this.regParams, 'ntifCstStateCd', '')
        this.$set(this.regParams, 'ntifCstStateNm', '')
      }
    },
    changeInputType (e, targetKey) {
      const elTxt = e.target.value // 보여줄 대상

      if (elTxt === 'input') {
        // selectbox 숨기면서 이전값을 셋팅
        // 다시 select로 변경시 사용하기 위한 용도
        this.selectBeforeVal = this.regParams[targetKey]
        this.regParams[targetKey] = ''
      } else {
        this.regParams[targetKey] = this.selectBeforeVal
      }
    },
    changeOnlyUpperEng (e, object, key, idx) {
      let key2 = key
      if (key === 'cntrNo') {
        const frm = document.querySelector('#frm_sr')
        const elem = frm.querySelector('#cntrNo_' + idx)
        this.$ekmtcCommon.hideErrorTooltip(elem)
        if (e.target.value) {
          const regEng = /^[a-zA-Z0-9]*$/
          const matchEng = regEng.test(e.target.value)
          if (!matchEng) {
            this.$ekmtcCommon.showErrorTooltip(elem, this.$t('art.CMATK215')) // 특수문자 또는 한글을 사용할 수 없습니다.
          }
        }
      } else if (key && key.startsWith('sealNo')) { // seal No
        const frm = document.querySelector('#frm_sr')
        const elem = frm.querySelector(`[name="${key}_${idx}"]`) // name으로 검색
        key2 = 'sealNo'
        this.$ekmtcCommon.hideErrorTooltip(elem)
        if (e.target.value) {
          const regEng = /^[a-zA-Z0-9]*$/
          const matchEng = regEng.test(e.target.value)
          if (!matchEng) {
            this.$ekmtcCommon.showErrorTooltip(elem, this.$t('art.CMATK215')) // 특수문자 또는 한글을 사용할 수 없습니다.
          }
        }
      }
      this.$ekmtcCommon.onlyEngNumber(e)
      this.$set(object, key2, object[key2].toUpperCase())
    },
    changeLatestInfo (e, flag) {
      const cstNm = e.target.value
      if (this.$ekmtcCommon.isNotEmpty(cstNm)) {
        const selectedIdx = e.target.options.selectedIndex
        const cstEnmInfo = this.cstEnmCodes[flag.toUpperCase()][selectedIdx - 1]

        this.$set(this.regParams, flag + 'CstEnm', cstEnmInfo.cstNm)
        this.$set(this.regParams, flag + 'CstAddr', cstEnmInfo.cstAddr)
      }
    },
    async changeCtrCd (e, target) {
      const ctrCd = e.target.value

      const plcList = await commons.getPlcCdList(ctrCd)
      this.$set(this, target, plcList)
    },
    changeCneCstEnmToOrder (e) {
      if (e.target.checked) {
        this.regParams.cneCstEnm = 'TO ORDER'
      } else {
        this.regParams.cneCstEnm = ''
      }
    },
    copyConsignee (e) {
      if (e.target.checked) {
        this.regParams.ntifCstEnm = this.regParams.cneCstEnm
        this.regParams.ntifCstAddr = this.regParams.cneCstAddr

        //지역, 담당자++ 부분도 복사
        this.regParams.ntifCstCtrCd = this.regParams.cneCstCtrCd
        this.regParams.ntifCstCityNm = this.regParams.cneCstCityNm
        this.regParams.ntifCstStateNm = this.regParams.cneCstStateNm
        this.regParams.ntifCstPostNo = this.regParams.cneCstPostNo
        this.regParams.ntifCstPicNm = this.regParams.cneCstPicNm
        this.regParams.ntifCstTelNo = this.regParams.cneCstTelNo
        this.regParams.ntifCstFaxNo = this.regParams.cneCstFaxNo
        this.regParams.ntifCstEmlAddr = this.regParams.cneCstEmlAddr
      } else {
        this.regParams.ntifCstEnm = ''
        this.regParams.ntifCstAddr = ''
        this.regParams.ntifCstCtrCd = ''
        this.regParams.ntifCstCityNm = ''
        this.regParams.ntifCstStateNm = ''
        this.regParams.ntifCstPostNo = ''
        this.regParams.ntifCstPicNm = ''
        this.regParams.ntifCstTelNo = ''
        this.regParams.ntifCstFaxNo = ''
        this.regParams.ntifCstEmlAddr = ''
      }
    },
    showBkgNoList (e) {
      const el = e.target
      const parentEl = el.closest('.span_tooltip')
      parentEl.querySelector('.tooltip_wrap').style.display = 'inline-block'
    },
    hideBkgNoList (e) {
      const el = e.target
      el.closest('.tooltip_wrap').style.display = 'none'
    },
    addExportLicense () {
      const obj = {
        elNo: '',
        pkgQty: '',
        pkgCd: '',
        grsWt: '',
        prtlLdgYn: 'N',
        ldgTcnt: '',
        pkgtgCd: '',
        pkgtgPkgQty: '',
        elNoValdDt: ''
      }

      if (this.isIndonesia()) {
        obj.idExportYn = 'Y'
        obj.idCstmCd = ''
        obj.emptyYn = ''
      } else {
        obj.idExportYn = 'N'
      }

      this.regParams.exportLicenseList.push(obj)
    },
    removeExportLicense (idx) {
      this.regParams.exportLicenseList.splice(idx, 1)

      if (this.regParams.exportLicenseList.length === 0) {
        this.addExportLicense()
      }
    },
    addBLContainer () {
      let obj = this.getAddBLContainerDefaultValue() // 신규 컨테이너의 초기값

      const cntrLen = this.regParams.containerList.length
      this.regParams.containerList.push(obj)

      this.$nextTick(() => {
        // 220713 #356 비활성화 처리
        // if ((cntrLen > 1 && this.regParams.polShtmCd === '03')) {
        //   const frm = document.querySelector('#frm_sr')
        //   frm.querySelector('#lcsPolShtmCd_' + cntrLen).disabled = false
        // }

        if (this.auth.userId === 'CKLINE') {
          const frm = document.querySelector('#frm_sr')
          frm.querySelector('#lcsPolShtmCd_' + cntrLen).disabled = false
          frm.querySelector('#lcsPodShtmCd_' + cntrLen).disabled = false
        }
      })
    },
    getAddBLContainerDefaultValue () { // 신규 컨테이너의 초기값
      let obj = {
        cntrNo: '',
        sealList: [
          { sealNo: '' }
        ],
        cntrSzCd: '',
        cntrTypCd: '',
        cgoTypCd: '',
        wt: '',
        pkgQty: '',
        shprVanYn: 'N',
        feUnitCd: 'F',
        cntrCheck: 'Y',
        polShtmCd: this.regParams.polShtmCd,
        podShtmCd: this.regParams.podShtmCd,
        deckCd: ''
      }

      if (this.lang === 'JPN') {
        const objJpn = {
          tareWt: '',
          pkgCd: '',
          meaCbm: ''
        }
        obj = { ...obj, ...objJpn }
      }

      if (this.regParams.polShtmCd === '03') {
        obj.polShtmCd = '01'
      }

      if (this.isPolShtmListChange()) {
        obj.polShtmCdList = this.commonCodes['01033'].filter(vo => vo.cd === '01' || vo.cd === '02')
      } else {
        obj.polShtmCdList = this.commonCodes['01033']
      }

      return obj
    },
    removeBLContainer (idx) {
      this.regParams.containerList.splice(idx, 1)

      if (this.regParams.containerList.length === 0) {
        this.addBLContainer()
      }

      this.fnChangeContSz()
      this.fnPackage()
    },
    addSealNo (idx) {
      const containerList = this.regParams.containerList[idx]
      let sealList = containerList.sealList
      if (containerList.sealList.length === 5) {
        this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.271')) // 최대 5개까지 추가 가능합니다.
        return
      }

      if (sealList === undefined || sealList === null) {
        sealList = []
      }

      sealList.push({ sealNo: '' })

      this.$set(this.regParams.containerList[idx], 'sealList', sealList)
    },
    removeSealNo (parentIdx, idx) {
      const containerList = this.regParams.containerList[parentIdx]
      const sealList = containerList.sealList

      sealList.splice(idx, 1)
    },
    addMarkDesc () {
      const markDescList = this.regParams.markDescList
      const markSeq = markDescList.length === 0 ? 1 : Number(markDescList[markDescList.length - 1].mrkSeq) + 1
      const obj = {
        mrkSeq: markSeq,
        mrk: '',
        dscr: ''
      }

      if (markSeq === 1) {
        obj.dscrSub = ''
      }

      markDescList.push(obj)
    },
    removeMarkDesc (idx) {
      this.regParams.markDescList.splice(idx, 1)

      if (this.regParams.markDescList.length === 0) {
        this.addMarkDesc()
        this.initDesc()
      }
    },
    checkIdEmptyCheck (e, idx) {
      const tr = document.querySelector('#expLicense_' + idx)
      const inputEl = tr.getElementsByTagName('input')

      if (e.target.checked) {
        [].forEach.call(inputEl, function (el) {
          if (el.type === 'text') {
            el.disabled = true
          }
        })
      } else {
        [].forEach.call(inputEl, function (el) {
          if (el.type === 'text') {
            el.disabled = false
          }
        })
      }
    },
    async checkElNo (idx) {
      const regParams = this.regParams
      const exportLicense = regParams.exportLicenseList[idx]
      const elNo = exportLicense.elNo

      if (this.$ekmtcCommon.isNotEmpty(elNo)) {
        if (elNo.length < 10) {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.194'))
          exportLicense.elNo = ''
          return
        }

        let duplCnt = 0
        regParams.exportLicenseList.forEach((el, i) => {
          if (i !== idx && el.elNo.trim() === elNo.trim()) {
            duplCnt += 1
          }
        })

        if (duplCnt > 0) {
          this.$ekmtcCommon.alertCallback(this.$t('art.CMATK043'), () => {
            exportLicense.elNo = ''
          })
          return
        }

        const retVal = this.elNoValidation(elNo)
        console.log('checkElNo regParams >>>> ', regParams)

        if (retVal) {
          console.log(regParams.blNo)
          let successFlag = true
          let result = null
          await trans.fnElNoDuplChk('', elNo).then(res => {
            if (res.headers.respcode !== 'C0000') {
              successFlag = false
            } else {
              result = res.data
            }
          }).catch(() => {
            successFlag = false
          }).finally(() => {
            if (successFlag) {
              if (result === 'Y') {
                // this.$ekmtcCommon.alertCallback(this.$t('art.CMATK043'), () => {
                //   exportLicense.elNo = ''
                // })
                this.$ekmtcCommon.alertDefault(this.$t('art.CMATK043'))
              }
            } else {
              this.$ekmtcCommon.alertDefault(this.$t('js.common.005'))
            }
          })
        } else {
          this.$ekmtcCommon.alertCallback(this.$t('art.CMATK016') + this.$t('tem.CMAT006'), () => {
            exportLicense.elNo = ''
            const frm = document.querySelector('#frm_sr')
            frm.querySelector('#elNo_' + idx).focus()
          })
        }
        if (regParams.polCtrCd === 'KR') {
          this.checkElNoTsCgoChk()
          const arrElNo = elNo.split('')
          if (arrElNo.length > 14 && this.$ekmtcCommon.isEmpty(regParams.tsCgoChoYn)) {
            if (await this.$ekmtcCommon.asyncAlertMessage({ message: '환적화물인지 여부 확인 바랍니다.', useConfirmBtn: true })) {
              this.checkTsCargoYes(idx)
            } else {
              this.checkTsCargonNo(idx)
            }
         }
        }
      }
    },
    checkTsCargoYes (idx) {
      this.$ekmtcCommon.alertDefault('수입적하목록 첨부 바랍니다.')
      this.regParams.tsCgoChoYn = 'Y'
    },
    async checkTsCargonNo (idx) {
      if (await this.$ekmtcCommon.asyncAlertMessage({ message: '수출면장번호는 14자리까지 입력 가능합니다. 까르네(전시품) 진행건인지 확인 바랍니다.', useConfirmBtn: true })) {
        this.checkExhibitYes(idx)
      } else {
        this.checkExhibitNo(idx)
       }
    },
    checkExhibitYes () {
      this.regParams.tsCgoChoYn = 'N'
    },
    checkExhibitNo (idx) {
      this.$ekmtcCommon.alertDefault('면장번호는 14자리까지 입력 가능합니다.')
      const selector = document.querySelector('#elNo_' + idx)
      this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBL200.194'))
      this.regParams.exportLicenseList[idx].elNo = ''
      this.checkElNoTsCgoChk()
    },
    checkElNoTsCgoChk () {
      let tsCgoYn = ''
      this.regParams.exportLicenseList.forEach((el, i) => {
        if (this.$ekmtcCommon.isNotEmpty(el.elNo) && el.elNo.length > 14) {
          tsCgoYn = 'Y'
        }
      })
      this.regParams.tsCgoChoYn = tsCgoYn === '' ? tsCgoYn : this.regParams.tsCgoChoYn
    },
    checkElNoIdn (idx) {
      const regParams = this.regParams
      const exportLicense = regParams.exportLicenseList[idx]
      const elNo = exportLicense.elNo

      if (this.$ekmtcCommon.isEmpty(elNo)) {
        return
      }

      let duplCnt = 0
      regParams.exportLicenseList.forEach((el, i) => {
        if (i !== idx && el.elNo.trim() === elNo.trim()) {
          duplCnt += 1
        }
      })

      if (duplCnt > 0) {
        this.$ekmtcCommon.alertCallback(this.$t('art.CMATK043'), () => {
          exportLicense.elNo = ''
        })
      }
    },
    elNoValidation (elNo) {
      const arrElNo = elNo.split('')

      if (arrElNo.length !== 14 && arrElNo.length !== 15 && arrElNo.length !== 18 && arrElNo.length !== 19) {
        return false
      }

      /*
      if (arrElNo.length === 14) {
        for (let i = 0; i < arrElNo.length; i++) {
          if (isNaN(arrElNo) && i < 12 && i !== 1) {
            return false
          }
        }

        if (!isNaN(arrElNo[12])) {
          if (arrElNo[13] !== 'X' && arrElNo[13] !== 'R') {
            return false
          }
        } else {
          if ((arrElNo[12] + arrElNo[13]) !== 'PX' && (arrElNo[12] + arrElNo[13]) !== 'PR') {
            return false
          }
        }
      }
      */

      return true
    },
    checkCntrNo (idx) {
      if (this.auth !== undefined && this.auth.userId === 'CKLINE') {
        return
      }

      const regParams = this.regParams
      const containerInfo = regParams.containerList[idx]
      const cntrNo = containerInfo.cntrNo
      const frm = document.querySelector('#frm_sr')

      if (cntrNo.length === 0) {
        return
      }

      if (cntrNo.length < 11) {
        this.$ekmtcCommon.alertCallback(this.$t('msg.CSBL200.195'), () => {
          containerInfo.cntrNo = ''
          frm.querySelector('#cntrNo_' + idx).focus()
        })
        return
      }

      if (this.cstCatCd === 'Y' || regParams.shprVanYn === 'Y') {
        const regParams = this.regParams
        const containerInfo = regParams.containerList[idx]
        const cntrNo = containerInfo.cntrNo
        const frm = document.querySelector('#frm_sr')
        const arrCntrPrefix = this.commonCodes.EQ051
        // const arrCntrPrefix = [
        //                       'TDIU', 'HDXU', 'IRSU', 'BANU', 'FURU', 'ALXU', 'XBIU', 'SBAU', 'AGIU', 'KGNU', 'PESU', 'XANU', 'FMCU', 'LCTU', 'AKKU'
        //                     ]

        let iranPrefix = 0
        arrCntrPrefix.forEach(p => {
          if (cntrNo.indexOf(p.cd) > -1) {
            iranPrefix++
          }
        })

        if (iranPrefix > 0) {
          this.$ekmtcCommon.alertCallback(this.$t('msg.CSBL200.196') + ' ' + cntrNo, () => {
            containerInfo.cntrNo = ''
            frm.querySelector('#cntrNo_' + idx).focus()
          })
          return
        }

        this.fnCntrNoCheck(idx)
      } else {
        console.log('else')
        const dlyPlcCd = regParams.dlyPlcCd

        if ((dlyPlcCd === 'BJO' || dlyPlcCd === 'LUU' || dlyPlcCd === 'ROQ') &&
          (cntrNo.startsWith('MAXU') || cntrNo.startsWith('INNU') || cntrNo.startsWith('IRNU') || cntrNo.startsWith('RALU'))) {
          this.$ekmtcCommon.alertCallback(this.$t('msg.CSBL200.160'), () => {
            containerInfo.cntrNo = ''
            frm.querySelector('#cntrNo_' + idx).focus()
          })
        }

        let succFlag = true
        let result = null
        transCommon.findCntrInfo(cntrNo).then(res => {
          console.log('findCntrInfo >>>> ', res)
          if (res.headers.respcode === 'C0000') {
            result = res.data
          } else {
            succFlag = false
          }
        }).catch(() => {
          succFlag = false
        }).finally(() => {
          if (succFlag && result !== null && result !== undefined) {
            const cntrSzCd = result.cntrSzCd
            const cntrTypCd = result.cntrTypCd

            if (this.$ekmtcCommon.isNotEmpty(cntrSzCd)) {
              containerInfo.cntrSzCd = cntrSzCd
              containerInfo.cntrTypCd = cntrTypCd

              if (cntrTypCd === 'RF' || cntrTypCd === 'RH') {
                this.rfInfoPop(idx)
              }
            } else {
              this.fnResetContainer(containerInfo, idx)
            }

            this.fnChangeContSz()
            this.fnCntrNoCheck(idx)
          } else {
            this.fnResetContainer(containerInfo, idx)
          }
        })
      }
    },
    async checkCntrNoInMy (srNo, cntrNo) {
      let result = true
      await trans.checkCntrNoInMy(srNo, cntrNo).then(res => {
        if (res.data === 'N') {
          this.$ekmtcCommon.alertDefault('The Container number not assigned to this booking. Please recheck')
          result = false
        }
      })

      return result
    },
    fnResetContainer (containerInfo, idx) {
      const frm = document.querySelector('#frm_sr')
      this.$ekmtcCommon.alertDefault(this.$t('art.CMATK088') + this.$t('tem.CMAT006'))
      containerInfo.cntrSzCd = ''
      containerInfo.cntrTypCd = ''
      containerInfo.cntrNo = ''
      frm.querySelector('#cntrNo_' + idx).focus()
    },
    fnChangeContSz () {
      const dscr = this.regParams.markDescList[0].dscr
      let flagChange = 'N'
      let cn2 = ''
      let cn4 = ''
      let cn5 = ''

      let sz20 = 0
      let sz40 = 0
      let sz45 = 0
      let retValue = ''

      const arrDscr = dscr.split('\n')

      if (arrDscr.length > 0) {
        const szInfo = arrDscr[0]
        if (szInfo.indexOf('X20\'') > -1) {
          cn2 = szInfo.split('X20\'')[1]
          if (arrDscr[1].indexOf('X40\'') > -1) {
            cn4 = dscr.split('\n')[1].split('X40\'')[1]
          }
        } else if (szInfo.indexOf('X40\'') > -1) {
          cn4 = szInfo.split('X40\'')[1]
        } else if (szInfo.indexOf('X45\'') > -1) {
          cn5 = szInfo.split('X45\'')[1]
        }
      }

      if (!cn2) cn2 = ''
      if (!cn4) cn4 = ''
      if (!cn5) cn5 = ''

      const regParams = this.regParams
      const containerList = regParams.containerList

      containerList.forEach(c => {
        if (c.cntrSzCd === '20') {
          sz20++
        } else if (c.cntrSzCd === '40') {
          sz40++
        } else if (c.cntrSzCd === '45') {
          sz45++
        }
      })

      if (sz20 > 0) {
        retValue += sz20 + 'X20\'' + cn2 + '\n'
      }

      if (sz40 > 0) {
        retValue += sz40 + 'X40\'' + cn4 + '\n'
      }

      if (sz45 > 0) {
        retValue += sz45 + 'X45\'' + cn5 + '\n'
      }

      let dd = 0
      let kk = ''
      let cc = ''
      let jj = ''
      arrDscr.forEach((d, i) => {
        if ((d.indexOf('X20') !== -1 || d.indexOf('X40') !== -1 || d.indexOf('X45') !== -1) && i <= 3) {
          ++dd
        } else {
          if (parseInt(d.substring(0, 1).charAt(0)) >= 0 && parseInt(d.substring(0, 1).charAt(0)) <= 9) {
            // Package 수량 체크는 2번째 라인까지만 확인
            if (i < 2 && flagChange === 'N') {
              kk = dscr.replace(d, retValue)
              cc += d
              flagChange = 'Y'
            } else {
              jj += d
            }
          } else {
            jj += d
          }

          if (i !== (d.length - 1)) {
            jj += '\n'
          }
        }
      })

      if (arrDscr.length === dd) {
        kk = retValue + cc
      } else {
        if (this.$ekmtcCommon.isNotEmpty(retValue)) {
          kk = retValue + cc + jj
        } else {
          kk = retValue + jj
        }
      }

      regParams.markDescList[0].dscr = kk
    },
    async fnCntrNoCheck (idx) {
      const regParams = this.regParams
      const containerInfo = regParams.containerList[idx]
      const cntrNo = containerInfo.cntrNo
      const params = {
        cntrNo: containerInfo.cntrNo,
        bkgNo: regParams.bkgNo
      }

      let checkMyCntrNo = true
      //SR 1362717 Request Container Error Message on ICC and e-kmtc Website
      if (regParams.polCtrCd === 'MY' && 'PKG, PKW, PGU, PEN'.indexOf(regParams.polPortCd) > -1) {
        checkMyCntrNo = await this.checkCntrNoInMy(regParams.bkgNo, cntrNo)
      }

      let succFlag = true
      let result = null
      transCommon.searchCtrCount(params).then(res => {
        if (res.headers.respcode === 'C0000') {
          result = res.data
        } else {
          succFlag = false
        }
      }).catch(() => {
        succFlag = false
      }).finally(() => {
        if (succFlag && result !== null && result !== undefined) {
          const cntrOffYn = result.cntrOffYn
          const ctrCd = result.ctrCd
          const polCtrCd = regParams.polCtrCd
          if (cntrOffYn === 'Y') {
            // 컨테이너 Number를 확인하세요. <br>Container No. XXXX
            const failMsg = this.$t('msg.CSBL200.195') + '<br>' + this.$t('msg.CSBL200.197') + cntrNo
            this.$ekmtcCommon.alertCallback(failMsg, () => {
              containerInfo.cntrNo = ''
              containerInfo.cntrSzCd = ''
              containerInfo.cntrTypCd = ''
              document.querySelector('#frm_sr').querySelector('#cntrNo_' + idx).focus()
            })
          } else {
            if (ctrCd === 'N') {
              containerInfo.cntrCheck = 'Y'
              return
            }
            if (this.$ekmtcCommon.isNotEmpty(ctrCd)) {
              if (polCtrCd === ctrCd) {
                containerInfo.cntrCheck = 'Y'
              } else {
                containerInfo.cntrCheck = 'N'
                if (ctrCd === 'HK' && regParams.bkgCn4Yn === 'Y') {
                  containerInfo.cntrCheck = 'Y'
                }
              }
            } else {
              containerInfo.cntrCheck = 'Y'
            }
          }
        } else {
          //this.$ekmtcCommon.alertDefault(this.$t('js.common.005'))
        }
      })
    },
    fnCntrPkgAutoSet () {
      const regParams = this.regParams
      const frm = document.querySelector('#frm_sr')
      const totalPkgQty = regParams.pkgQty
      const totalWt = regParams.grsWt
      const totalCbm = regParams.grsCbm

      let cntrSz = 0
      let cntr20hop = 0
      let cntr40hop = 0
      let count = 0

      let wtSum = 0
      let cbmSum = 0

      regParams.containerList.forEach((c, idx) => {
        if (c.cntrSzCd === '20') {
          cntrSz += 1
          cntr20hop++
        } else if (c.cntrSzCd === '40' || c.cntrSzCd === '45') {
          cntrSz += 1.3
          cntr40hop++
        } else {
          this.$ekmtcCommon.alertDefault(this.$t('art.CMATK088') + this.$t('tem.CMAT002'))
          frm.querySelector('#cntrSzCd_' + idx).focus()
          return
        }

        count++
      })

      let cCntrSz20Sum = Math.round(totalPkgQty * 1 / cntrSz * cntr20hop)
      let cCntrSz40Sum = Math.round(totalPkgQty * 1 / cntrSz * cntr40hop)
      let lsCntr20Cnt = 0
      let lsCntr40Cnt = 0

      if (cCntrSz20Sum + cCntrSz40Sum !== totalPkgQty) {
        if (cCntrSz20Sum > 0 && cCntrSz40Sum > 0) {
          cCntrSz40Sum = totalPkgQty - cCntrSz20Sum
        } else {
          if (cCntrSz20Sum > 0) {
            cCntrSz20Sum = totalPkgQty
          }

          if (cCntrSz40Sum > 0) {
            cCntrSz40Sum = totalPkgQty
          }
        }
      }

      if (count === 1) {
        const containerInfo = regParams.containerList[0]

        containerInfo.wt = totalWt
        containerInfo.pkgQty = totalPkgQty
        if (this.lang === 'JPN' || this.regParams.polCtrCd === 'PH' || this.regParams.polCtrCd === 'VN' || this.regParams.podCtrCd === 'BH') {
          containerInfo.meaCbm = totalCbm
        }
      } else {
        regParams.containerList.forEach(c => {
          if (c.cntrSzCd === '20') {
            //SR 1428093 콜럼보 항은 소숫점 2자리로 반올림처리
            if (this.regParams.podPortCd === 'CMB') {
              c.wt = (Math.trunc(totalWt * (1 / cntrSz) * 100)) / 100
            } else {
              c.wt = (Math.trunc(totalWt * (1 / cntrSz) * 1000)) / 1000
            }
            wtSum += Number(c.wt)

            if (this.lang === 'JPN' || this.regParams.polCtrCd === 'PH' || this.regParams.polCtrCd === 'VN' || this.regParams.podCtrCd === 'BH') {
              c.meaCbm = (Math.round(totalCbm * (1 / cntrSz) * 1000)) / 1000
              cbmSum += Number(c.meaCbm)
            }

            lsCntr20Cnt++
            if ((cCntrSz20Sum % cntr20hop) < lsCntr20Cnt) {
              c.pkgQty = Math.floor(Number(cCntrSz20Sum / cntr20hop))
            } else {
              c.pkgQty = Math.floor(Number(cCntrSz20Sum / cntr20hop) + 1)
            }
          } else {
            //SR 1428093 콜럼보 항은 소숫점 2자리로 반올림처리
            if (this.regParams.podPortCd === 'CMB') {
              c.wt = (Math.trunc(totalWt * (1.3 / cntrSz) * 100)) / 100
            } else {
              c.wt = (Math.trunc(totalWt * (1.3 / cntrSz) * 1000)) / 1000
            }
            wtSum += Number(c.wt)

            if (this.lang === 'JPN' || this.regParams.polCtrCd === 'PH' || this.regParams.polCtrCd === 'VN' || this.regParams.podCtrCd === 'BH') {
              c.meaCbm = (Math.round(totalCbm * (1.3 / cntrSz) * 1000)) / 1000
              cbmSum += Number(c.meaCbm)
            }

            lsCntr40Cnt++
            if ((cCntrSz40Sum % cntr40hop) < lsCntr40Cnt) {
              c.pkgQty = Math.floor(Number(cCntrSz40Sum / cntr40hop))
            } else {
              c.pkgQty = Math.floor(Number(cCntrSz40Sum / cntr40hop) + 1)
            }
          }
        })

        let modWt = 0

        if (this.regParams.podPortCd === 'CMB') {
          modWt = (totalWt - wtSum).toFixed(2)

          let modCnt = Math.ceil((modWt / 0.01) / regParams.containerList.length)

          for (let i = 0; i < modCnt; i++) {
            regParams.containerList.forEach(c => {
              if (modWt > 0) {
                c.wt = c.wt + 0.01
                modWt = modWt - 0.01
              }
            })
          }
        } else {
          modWt = (totalWt - wtSum).toFixed(3)

          let modCnt = Math.ceil((modWt / 0.001) / regParams.containerList.length)

          for (let i = 0; i < modCnt; i++) {
            regParams.containerList.forEach(c => {
              if (modWt > 0) {
                c.wt = c.wt + 0.001
                modWt = modWt - 0.001
              }
            })
          }
        }
      }
    },
    fnSaySet () {
      const regParams = this.regParams
      const polShtmCd = regParams.polShtmCd

      let flag = ''
      let cnt = 0
      let pkgCd = ''
      let arrCntrNo = []
      if (polShtmCd === '01') {
        flag = 'CNTR'

        regParams.containerList.forEach((c, i) => {
          if (this.$ekmtcCommon.isNotEmpty(c.cntrNo)) {
            cnt++

            arrCntrNo.push(c.cntrNo)
          }
        })
      } else if (polShtmCd === '02') {
        flag = 'QTY'
        cnt = regParams.pkgQty

        if (this.$ekmtcCommon.isNotEmpty(regParams.pkgCd)) {
          const pkgCdList = this.commonCodes['01130']
          pkgCd = pkgCdList.filter(vo => vo.cd === regParams.pkgCd)[0].cdNm
        }

        if (this.$ekmtcCommon.isEmpty(pkgCd) || this.$ekmtcCommon.isEmpty(cnt)) {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.270'))
          return
        }
      }

      const params = {
        blNo: regParams.srNo,
        flag: flag,
        cnt: cnt,
        arrCntrNo: arrCntrNo,
        pkgCd: pkgCd
      }

      trans.fnSayCheck(params).then(res => {
        const result = res.data

        if (result !== undefined) {
          this.$set(this.regParams, 'blPrtrCont', result)
          this.$ekmtcCommon.hideErrorTooltip(document.querySelector('#blPrtrCont'))
        }
      })

      if (cnt === 0) {
        this.$ekmtcCommon.alertDefault(this.$t('js.working.001'))
      }
    },
    fnSaySetNew (val) {
      const regParams = this.regParams
      const polShtmCd = regParams.polShtmCd

      let flag = ''
      let cnt = 0
      let pkgCd = ''
      let arrCntrNo = []
      if (val > 999) {
          return
        }
      if (polShtmCd === '01') {
        flag = 'CNTR'
        for (let i = 0; i < val; i++) {
          cnt++
        }
      } else if (polShtmCd === '02') {
        flag = 'QTY'
        cnt = val

        if (this.$ekmtcCommon.isNotEmpty(regParams.pkgCd)) {
          const pkgCdList = this.commonCodes['01130']
          pkgCd = pkgCdList.filter(vo => vo.cd === regParams.pkgCd)[0].cdNm
        }

        if (this.$ekmtcCommon.isEmpty(pkgCd) || this.$ekmtcCommon.isEmpty(cnt)) {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.270'))
          return
        }
      }

      const params = {
        blNo: regParams.blNo,
        flag: flag,
        cnt: cnt,
        arrCntrNo: arrCntrNo,
        pkgCd: pkgCd
      }

      trans.fnSayCheck(params).then(res => {
        const result = res.data
        // console.log('fnSayCheck res @@@@@ ', res)

        if (result !== undefined) {
          this.$set(this.regParams, 'blPrtrCont', result)
        }
      })

      if (cnt === 0) {
        this.$ekmtcCommon.alertDefault(this.$t('js.working.001'))
      }
    },
    fnSayTab () {
      let vNormal = /[^0-9.]/g
      let val = document.querySelector('#blPrtrCont').value
      if (!vNormal.test(val) && !isNaN(parseInt(val))) {
        this.fnSaySetNew(val)
      }
    },
    fnSayQtyChk () {
      const regParams = this.regParams
      const polShtmCd = regParams.polShtmCd
      let cntrCnt = 0
      let sayCnt = 0
      if (polShtmCd === '01') {
        regParams.containerList.forEach((c, i) => {
          if (this.$ekmtcCommon.isNotEmpty(c.cntrNo)) {
            cntrCnt++
          }
        })
      } else if (polShtmCd === '02') {
        cntrCnt = regParams.pkgQty
      }
      sayCnt = document.querySelector('#blPrtrCont').value.replaceAll(/[^0-9]/g, '')
      if (Number(cntrCnt) === Number(sayCnt) || cntrCnt === 0) {
        return true
      } else {
        return false
      }
    },
    addrStrEnter (e) {
      if (e.keyCode === 13) {
        this.searchAgentAddr()
      }
    },
    searchAgentAddr () {
      this.popParams = {}
      if (this.$ekmtcCommon.isNotEmpty(this.addrStr)) {
        const ctrCd = this.addrStr.split(':')[0]
        const plcCd = this.addrStr.split(':')[1]
        this.popParams.addrCtrCd = ctrCd
        this.popParams.addrPlcCd = plcCd
      }

      this.selectFunc = ''
      this.openPopup('bl-agent-address-pop')
    },
    selectAgentAddr (vo) {
      if (vo !== undefined) {
        const markDescList = this.regParams.markDescList

        markDescList[0].mrk = vo.sntcCont.trim()
        this.closePopup()
      }
    },
    onBoardDatePop () {
      this.popParams = {
        blNo: this.regParams.srNo,
        bkgNo: this.regParams.bkgNo,
        flag: 'SR'
      }

      this.selectFunc = this.setOnBoardDate
      this.openPopup('bl-on-board-date-pop')
    },
    setOnBoardDate (date) {
      this.regParams.obrdDt = date
      this.regParams.obrdDtTemp = this.$ekmtcCommon.changeDatePattern(date)

      this.closePopup()
    },
    changeCntrType (vo, idx) { // 컨테이너 Type에 따른 처리
      // Tank container 여부에 따라 화주컨테이너여부 체크
      if (vo.cntrTypCd === 'TK') {
        this.$set(vo, 'shprVanYn', 'Y') // 화주컨테이너여부
        //this.$set(vo, 'feUnitCd', 'E') // Empty (ASIS로직은 있으나 작동이 안됨)
      } else {
        this.$set(vo, 'shprVanYn', 'N') // 화주컨테이너여부
        this.$set(vo, 'feUnitCd', 'F') // Empty(F:Full)
      }

      this.cntrTypeInfoPop(vo.cntrTypCd, idx)
    },
    cntrTypeInfoPop (cntrTypCd, idx) {
      if (cntrTypCd === 'RF' || cntrTypCd === 'RH') {
        this.rfInfoPop(idx)
      }
    },
    changeShprVanYn (vo, idx) { // 화주컨테이너 여부에 따른 처리
      // 화주컨테이너여부 체크 해제시 Empty 체크 해제
      if (vo.shprVanYn !== 'Y' && vo.feUnitCd !== 'F') {
        this.$set(vo, 'feUnitCd', 'F') // Empty(F:Full)
      }

      this.shprVanValidation(vo)
    },
    cgoTypeInfoPop (cntrTypCd, idx) {
      const containerInfo = this.regParams.containerList[idx]

      if (this.$ekmtcCommon.isEmpty(containerInfo.cntrNo) || containerInfo.cntrNo.length < 10) {
        this.$ekmtcCommon.alertDefault(this.$t('art.CMATK088') + ' ' + this.$t('tem.CMAT002'))
        containerInfo.cgoTypCd = ''
        return
      }

      if (cntrTypCd === '02') {
        this.oogInfoPop(idx)
      }
    },
    async rfInfoPop (idx) {
      if (!await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('art.CMATK359'), useConfirmBtn: true })) {
        return
      }
      const containerInfo = this.regParams.containerList[idx]

      this.popParams = {
        blNo: this.regParams.srNo,
        bkgNo: this.regParams.bkgNo,
        modifyFlag: this.saveFlag === 'SR_ADD' ? 'I' : 'U',
        idx: idx,
        rfTmprUnitCd: containerInfo.tmprUnitCd || '',
        rfSetupTmpr: containerInfo.rfSetupTmpr || '',
        rfSlecUseYn: containerInfo.rfSlecUseYn || '',
        cntrRfStsCd: containerInfo.cntrRfStsCd || '',
        polCtrCd: this.regParams.polCtrCd
      }

      this.selectFunc = this.setRFInfo
      this.openPopup('bl-container-rf-pop')
    },
    setRFInfo (vo) {
      if (vo !== undefined) {
        const containerInfo = this.regParams.containerList[vo.selectIdx]

        containerInfo.cntrRfStsCd = vo.cntrRfStsCd
        containerInfo.maxTmpr = vo.rfSetupTmpr
        containerInfo.tmprUnitCd = vo.rfTmprUnitCd
        containerInfo.rfSlecUseYn = vo.rfSlecUseYn
      }
    },
    hzInfoPop (idx) {
      const containerList = this.regParams.containerList[idx]

      this.popParams = {
        bkgNo: this.regParams.bkgNo
      }

      this.selectFunc = ''
      this.openPopup('bl-container-dg-pop')
    },
    oogInfoPop (idx) {
      const containerList = this.regParams.containerList[idx]

      this.popParams = {
        blNo: this.regParams.srNo,
        bkgNo: this.regParams.bkgNo,
        modifyFlag: this.saveFlag === 'SR_ADD' ? 'I' : 'U',
        idx: idx
      }

      this.selectFunc = this.setOOGInfo
      this.openPopup('bl-container-oog-pop')
    },
    setOOGInfo (vo) {
      if (vo !== undefined) {
        const containerInfo = this.regParams.containerList[vo.selectIdx]

        containerInfo.ovrHght = vo.ovrHght
        containerInfo.ovrFwdLen = vo.ovrFwdLen
        containerInfo.ovrBwrdLen = vo.ovrBwrdLen
        containerInfo.ovrPortLen = vo.ovrPortLen
        containerInfo.ovrStbdLen = vo.ovrStbdLen
      }
    },
    openPopup (compNm) {
      this.customComponent = null
      this.customComponent = compNm

      this.$ekmtcCommon.layerOpen('.bl_area > .popup_dim')
    },
    closePopup () {
      this.customComponent = null
      this.selectFunc = null
      this.$ekmtcCommon.layerClose('.bl_area > .popup_dim')
    },
    isMultiPolHsCode () {
      let isBtnShow = false

      const regParams = this.regParams
      if ((regParams.cgoModeCd === '01' || regParams.cgoModeCd === '03') &&
          (regParams.polCtrCd === 'ID' || regParams.polCtrCd === 'IN')) {
        isBtnShow = true
      }

      this.isPolHsMulti = isBtnShow
    },
    isMultiPodHsCode () {
      let isBtnShow = false

      const regParams = this.regParams

      if (regParams.dlyCtrCd === 'IR' ||
          (
            (regParams.cgoModeCd === '01' || regParams.cgoModeCd === '03') &&
            (regParams.podCtrCd === 'ID' || regParams.podCtrCd === 'IN')
          )
        ) {
          isBtnShow = true
      }

      this.isPodHsMulti = isBtnShow
    },
    hsCdMultiPop (catCd) {
      // if (this.regParams.containerList.length === 0) {
      //   this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.224'))
      //   return
      // }
      let cntrNoVal = true
      this.regParams.containerList.forEach(c => {
        if (this.$ekmtcCommon.isEmpty(c.cntrNo)) {
          cntrNoVal = false
        }
      })
      if (!cntrNoVal) {
        this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.224'))
        return
      }

      const regParams = this.regParams
      let popParams = {}
      let openPopName = ''
      this.selectFunc = ''
      // DELIVERY 국가 이란
      if (catCd === '02' && regParams.dlyCtrCd === 'IR') {
        popParams = {
          bkgNo: regParams.bkgNo
        }

        openPopName = 'hs-code-ir-pop'
      } else {
        // if (Number(this.saveCntrCnt) === 0) {
        //   this.$ekmtcCommon.alertDefault(this.$t('msg.hsCdPop.006'))
        //   return
        // }
        // 출발지 국가 인도네시아 또는 인도
        // 도착지 국가 인도네시아 또는 인도
        const ctrCd = catCd === '01' ? regParams.polCtrCd : regParams.podCtrCd
        popParams = {
          blNo: regParams.srNo,
          ldCatCd: catCd,
          uiId: 'SR',
          ctrCd: ctrCd
        }

        if (ctrCd === 'IN') {
          popParams.pkgCd = regParams.exportLicenseList[0].pkgCd
          popParams.pkgQty = regParams.pkgQty
          popParams.containerList = this.regParams.containerList
          this.selectFunc = this.setHsQty
        }

        openPopName = ctrCd === 'ID' ? 'hs-code-id-pop' : 'hs-code-in-pop'
      }

      this.popParams = popParams
      this.openPopup(openPopName)
    },
    setHsQty (qty) {
      if (this.regParams.podCtrCd === 'IN' || regParams.inTsYn === 'Y') {
        this.regParams.hsQty = qty
      }
    },
    fnNoChange (e, idx) {
      const containerInfo = this.regParams.containerList[idx]

      if (this.cstCatCd === 'Y' || this.regParams.shprVanYn === 'Y') {
        return
      }

      if (this.$ekmtcCommon.isNotEmpty(containerInfo.cntrNo)) {
        e.target.blur()
        this.$ekmtcCommon.alertDefault(this.$t('art.CMATK356'))
      }
    },
    fnCgoPop () {
      this.popParams = {
        blNo: this.regParams.srNo,
        bkgNo: this.regParams.bkgNo,
        modifyFlag: this.saveFlag === 'SR_ADD' ? 'I' : 'U',
        type: 'SR'
      }

      this.selectFunc = ''
      this.openPopup('bl-container-cgo-pop')
    },
    async checkHsCd (e, ldCatCd) {
      const regParams = this.regParams
      const checkType = ldCatCd === 'POD' ? this.hsChkParams.podCtrCd : this.hsChkParams.polCtrCd

      let val = e.replace(/,/g, '')
      if (val === '') {
        if (ldCatCd === 'POL') {
          this.$nextTick(() => {
            this.hsChkParams.hscdPolErrorMessage = ''
          })
          this.hsChkParams.polSuccYn = 'Y'
        } else {
          this.$nextTick(() => {
            this.hsChkParams.hscdPodErrorMessage = ''
          })
          this.hsChkParams.podSuccYn = 'Y'
        }
        return
      }

      let isOk = true
      let message = ''
      const regex = /[^0-9]/g

      if (checkType === 'IR') {
        isOk = await this.fnIranCheck(ldCatCd)
        message = this.$t('msg.CSBK100.117')
      } else {
        message = this.$t('msg.CSBL200.175')
        if (regex.test(val) || isNaN(parseInt(val))) {
          isOk = false
        }

        if (checkType === 'ID' || checkType === 'IR') {
          if (val.length !== 4 && val.length !== 6 && val.length !== 8) {
            isOk = false
          }
        } else if (ldCatCd === 'POD' && checkType === 'VN') {
          if (regParams.wstCgoYn === 'Y') {
            if (val.length !== 8) {
              isOk = false
            }
          } else if (val.length !== 4 && val.length !== 6 && val.length !== 8) {
            isOk = false
          }
        } else if (ldCatCd === 'POD' && checkType === 'SHA') {
          if (val.length !== 10) {
            isOk = false
          }
        } else {
          if (val.length < 6) {
            isOk = false
          }
        }

        if (isOk) {
          isOk = await this.fnHsCdValidation(ldCatCd)
        }
      }

      if (ldCatCd === 'POD') {
        this.setIndiaInfo()
      }

      if (!isOk) {
        if (ldCatCd === 'POL') {
          this.hsChkParams.hscdPolErrorMessage = ''
          this.$nextTick(() => {
            this.hsChkParams.hscdPolErrorMessage = message
          })
          this.hsChkParams.polSuccYn = 'N'
        } else {
          this.hsChkParams.hscdPodErrorMessage = ''
          this.$nextTick(() => {
            this.hsChkParams.hscdPodErrorMessage = message
          })
          this.hsChkParams.podSuccYn = 'N'
        }
      } else {
        if (ldCatCd === 'POL') {
          this.$nextTick(() => {
            this.hsChkParams.hscdPolErrorMessage = ''
          })
          this.hsChkParams.polSuccYn = 'Y'
        } else {
          this.$nextTick(() => {
            this.hsChkParams.hscdPodErrorMessage = ''
          })
          this.hsChkParams.podSuccYn = 'Y'
        }
      }
    },
    async fnIranCheck (ldCatCd) {
      let isOk = true
      const hsCd = ldCatCd === 'POL' ? this.regParams.hsCd : this.regParams.podHsCd
      const params = {
        kndCd: 3,
        hsCd: hsCd
      }

      await transCommon.getIranCheck(params).then(res => {
        const result = res.data

        if (res.headers.respcode === 'C0000' && result !== undefined) {
          if (result === 'Y') {
            isOk = false
          }
        }
      })

      return isOk
    },
    async fnHsCdValidation (ldCatCd) {
      let isOk = true
      let hsCd = ldCatCd === 'POL' ? this.regParams.hsCd : this.regParams.podHsCd
      let checkType = ldCatCd === 'POL' ? this.hsChkParams.polCtrCd : this.hsChkParams.podCtrCd

      let polCtrCd = ''
      let podCtrCd = ''

      if (checkType !== 'SHA' && checkType !== 'VN' && checkType !== 'IN') {
        checkType = ldCatCd // POD or POL
        polCtrCd = this.hsChkParams.polCtrCd
        podCtrCd = this.hsChkParams.podCtrCd
      }

      await transCommon.getHscdValidation({
        hsCd: hsCd,
        hsFlag: checkType,
        polCtrCd: polCtrCd,
        podCtrCd: podCtrCd,
        hsNmSearchYn: 'Y'
      }).then(res => {
        const result = res.data

        this.$set(this.regParams, 'podHsCdNm', result.hsNm)

        if (result.returnFlag === 'N') {
          isOk = false
        }
      })

      return isOk
    },
    async inputValidation () {
      const TH = this
      const type = TH.saveType
      const regParams = TH.regParams
      const cllYn = regParams.cllYn
      let isOk = true

      if (cllYn === 'Y') {
        this.$ekmtcCommon.alertDefault(this.$t('art.CSBK005'))
        return false
      }

      if (!this.checkValidationInfoAll()) {
        isOk = false
      }

      if (!this.checkValidationEmailAll()) {
        isOk = false
      }

      if (!this.checkValidationHsCd()) {
        isOk = false
      }

      if (!this.checkValidationELAll()) {
        isOk = false

        if (this.isIndonesia()) {
          const message = 'Input the Licence No.\n\n(PEB no. is compulsory item for the Indonesian Export S/R confirm. Please fill in the Blank spaces of the PEB no.'
          this.$ekmtcCommon.alertDefault(message)
        }
      }

      if (!this.checkValidationCntrAll()) {
        isOk = false
      }

      if (regParams.dlyCtrCd === 'IR') {
        if (!await this.checkValidationIranHsCdAll()) {
          isOk = false
        }
      }
      return isOk
    },
    checkValidationRFCntr () {
      let isOk = true
      const TH = this
      const regParams = TH.regParams
      const containerList = regParams.containerList
      const frm = document.querySelector('#frm_sr')

      containerList.forEach((cvo, idx) => {
        const selector = frm.querySelector('#cntrTypCd_' + idx)

        this.$ekmtcCommon.hideErrorTooltip(selector)
        if ((cvo.cntrTypCd === 'RF' || cvo.cntrTypCd === 'RH') && this.$ekmtcCommon.isEmpty(cvo.tmprUnitCd)) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('art.CMATK347'))
          isOk = false
        }
      })

      return isOk
    },
    async validation () {
      let isOk = true
      const TH = this
      const regParams = TH.regParams
      const type = this.saveType

      const cgoTypList = regParams.cgoTypList
      const containerList = regParams.containerList

      await commons.getServerTime().then(res => {
        this.serverYear = res.data.substring(0, 4)
        this.serverMonth = res.data.substring(4, 6)
        this.serverDay = res.data.substring(6, 8)
      })

      if (!await this.inputValidation()) {
        isOk = false
      }

      containerList.forEach(cvo => {
        if (TH.$ekmtcCommon.isNotEmpty(cvo.cntrNo)) {
          cgoTypList.some(cgoVo => {
            if (TH.$ekmtcCommon.isNotEmpty(cgoVo.cgoTypCd)) {
              if (cvo.cntrSzCd + cvo.cntrTypCd === cgoVo.cntrSzCd + cgoVo.cntrTypCd) {
                if (cvo.cgoTypCd !== cgoVo.cgoTypCd) {
                  cvo.cgoCheckYn = 'N'
                } else {
                  cvo.cgoCheckYn = 'Y'
                }
                return cvo.cgoTypCd === cgoVo.cgoTypCd
              }
            }
          })
        }
      })

      const rfBkYn = cgoTypList.filter(vo => this.$ekmtcCommon.isNotEmpty(vo.rfTmprUnitCd)).length > 0 ? 'Y' : 'N'
      if (type === 'DCLR' || type === 'DCLR_DIRECT') {
        if (rfBkYn === 'Y') {
          if (!this.checkValidationRFCntr()) {
            isOk = false
          }
        }
      }

      return isOk
    },
    async saveBL (saveType) {
      const TH = this
      const regParams = TH.regParams
      const cllYn = regParams.cllYn
      this.saveType = saveType
      let isOk = await this.validation()
      let chkMyHz = false // 말레이시아 위험물 체크

      // 일본 부킹일때, 부킹 화주 컨테이너 값 (shprVanYn) / BL 컨테이너 화주 값 같은지 확인
      if (this.memberDetail.userCtrCd === 'JP') {
        if (!this.shprVanValidation()) {
          this.$ekmtcCommon.alertDefault('BookingのShipper’s Van情報と相違があります。まずはBooking情報を確認してください')
          return
        }
      }

      if (regParams.dlyPlcCd === 'IPL') {
        const phuocLongIdx = regParams.dlyPlcNm.indexOf('PHUOC LONG')
        if ((phuocLongIdx >= regParams.dlyPlcNm.indexOf('1') && phuocLongIdx >= regParams.dlyPlcNm.indexOf('3')) || phuocLongIdx === -1) {
          await this.$ekmtcCommon.asyncAlertMessage({ message: '<div style="width: 560px;">There are two ports for Place of Delivery : <b>ICD PHUOC LONG 1</b> and <b>ICD PHUOC LONG 3</b>.<br>Please clarify and choose the correct port. Do not accept the exception.</div>' })
        }
      }

      // 제출 시 CY/CY 이거나 CFS/CY 이면 AutoSay 적용
      if (saveType === 'DCLR') {
        if ((regParams.polShtmCd === '01' || regParams.polShtmCd === '02') && regParams.podShtmCd === '01') {
          //await this.fnSaySet()
        }

        //SR 1362717 Request Container Error Message on ICC and e-kmtc Website
        if (regParams.polCtrCd === 'MY' && 'PKG, PKW, PGU, PEN'.indexOf(regParams.polPortCd) > -1) {
           if (regParams.containerList.length > 0) {
             for (let c of regParams.containerList) {
               if (this.$ekmtcCommon.isNotEmpty(c.cntrNo)) {
                 let check = await TH.checkCntrNoInMy(regParams.bkgNo, c.cntrNo)
                 if (!check) {
                   isOk = false
                   return false
                 }
               }
             }
             if (!isOk) return
           }
        }
      }

      //1416920 사우디(DMM)향 부킹 필수정보 기입
      if ((saveType === 'DCLR' || saveType === 'SAVE_THEN') && isOk) {
         //1.POD가 DMM인 경우
         if (regParams.podPortCd === 'DMM') {
           //2.Consignee가 To order가 아닌 경우, Consignee란에 “To order of”가 포함된 경우
           if (regParams.cneCstEnm.indexOf('TO ORDER') < 0) {
              //이메일 및 전화번호 필수 체크
              if (this.$ekmtcCommon.isEmpty(regParams.cneCstEmlAddr) || this.$ekmtcCommon.isEmpty(regParams.cneCstTelNo)) {
                //message : The consignee's contact information(Tel/email) is required.
                this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.290'))
                return
              }
            }
         }
      }

      // VN -> DMM향일 때 REQUIRED SENTENCE 필수 체크
      if ((saveType === 'DCLR' || saveType === 'SAVE_THEN') && isOk) {
        if (regParams.porCtrCd === 'VN' && regParams.podPortCd === 'DMM') {
          let arr = [
            'SHIPPING LINE / SHIPPING LINE AGENTS ARE ELIG',
            'IBLE UNDER THE TERMS OF THIS B/L AS AGREED, T',
            'O COLLECT ANY CHARGES SUCH AS  DEPOSIT SAR 30',
            '00 PER DRY CNTR, SAR 7000 PER REEFER AND ANY ',
            'OTHER CHARGES OR FEES WHERE DEEMED NECESSARY ',
            'BY THE SHIPPING LINE / SHIPPING LINE AGENTS.L',
            'OLO(FASAH): SAR 50/CNTR, GATE PASS & HANDLING',
            ': SAR 120/CNTR, OCR: USD80/120 PER 20\'/40\'(NO',
            'N HAZ SHIPMENTS), USD 100/150 PER 20\'/40\'(HAZ',
            ' SHIPMENTS)'
          ]

          let chkRequiredSentence = false
          for (let desc of this.regParams.markDescList) {
            if (desc.dscr.indexOf(arr[0]) > -1 && desc.dscr.indexOf(arr[1]) > -1 &&
              desc.dscr.indexOf(arr[2]) > -1 && desc.dscr.indexOf(arr[3]) > -1 &&
              desc.dscr.indexOf(arr[4]) > -1 && desc.dscr.indexOf(arr[5]) > -1 &&
              desc.dscr.indexOf(arr[6]) > -1 && desc.dscr.indexOf(arr[7]) > -1 &&
              desc.dscr.indexOf(arr[8]) > -1 && desc.dscr.indexOf(arr[9]) > -1) {
              chkRequiredSentence = true
            }
          }

          if (!chkRequiredSentence) {
            this.$ekmtcCommon.alertDefault('REQUIRED SENTENCE is required.')
            return
          }
        }
      }

      if ((saveType === 'DCLR' || saveType === 'DCLR_DIRECT') && regParams.blEdiTypCd === 'S' && this.memberDetail.userCtrCd === 'KR') {
        if (!this.checkTotPkgWt()) {
          isOk = false
        }
      }
      if ((saveType === 'DCLR' || saveType === 'SAVE_THEN') && this.regParams.polCtrCd === 'KR') {
        this.checkElNoTsCgoChk()
        if (this.regParams.tsCgoChoYn === 'Y' && this.regParams.emanYn !== 'Y') {
          this.$ekmtcCommon.alertDefault('수입적하목록 첨부 바랍니다.')
          return
        }
      }
      if (cllYn === 'Y') {
          this.$ekmtcCommon.alertDefault(this.$t('art.CSBK005'))
          return
      }

      if (!isOk) {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.238'))
          document.querySelector('html').scrollTop = 0
          //return
      }
      if (saveType === 'DCLR' || saveType === 'DCLR_DIRECT') {
          if (this.auth.userCtrCd === 'KR' && isOk) {
            if (this.$ekmtcCommon.isNotEmpty(this.regParams.blPrtrCont) && this.regParams.blPrtrCont.indexOf('ZERO') > -1) {
            this.$ekmtcCommon.alertDefault(this.$t('js.working.001'))
            return
          }
          let sayQtychk = await this.fnSayQtyChk()
          if (!sayQtychk) {
            if (!await this.$ekmtcCommon.asyncAlertMessage({ message: '입력한 컨테이너 갯수와 SAY란이 불일치합니다.\n이대로 저장하시겠습니까? ', useConfirmBtn: true })) {
            return
            }
          }
        }
      }

      if (this.regParams.podCtrCd === 'BH') {
        //여기 validation 추가
        if (Number(this.regParams.grsCbm) <= 0) {
          await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.CSBK100.238') })
          this.$ekmtcCommon.showErrorTooltip('#grsCbm', this.$t('msg.CSBK100.238'))
          return
        }
      }

      // 1378414 Mandatory BL Type Selection prior online BL submission
      if (saveType === 'DCLR' || saveType === 'SAVE_THEN') {
        if (this.blTypeVisibleContry.includes(this.auth.userCtrCd) && isOk) {
          if (this.$ekmtcCommon.isEmpty(this.regParams.blTypCd)) {
            this.$ekmtcCommon.showErrorTooltip('#blTypCd0', this.$t('msg.CSBK100.147'))
            isOk = false
          } else {
            let bltypCdNm = $('[name=blTypCd]:checked').next().text()

            let cs105CdNmArr = []
            this.commonCodes.CS105.forEach(c => {
              cs105CdNmArr.push(c.cdNm)
            })

            if (this.$ekmtcCommon.isEmpty(this.regParams.rmk)) {
              TH.regParams.rmk = bltypCdNm
            } else {
              if (!TH.regParams.rmk.includes(bltypCdNm)) {
                for (var val of cs105CdNmArr) {
                  if (bltypCdNm === val) continue

                  if (TH.regParams.rmk.indexOf(val) > -1) {
                    TH.regParams.rmk = TH.regParams.rmk.replaceAll(val, '')
                  }
                }
                if (this.$ekmtcCommon.isEmpty(this.regParams.rmk)) {
                  TH.regParams.rmk = bltypCdNm
                } else {
                  TH.regParams.rmk = TH.regParams.rmk + '\n' + bltypCdNm
                }

                $('#rmk').val(TH.regParams.rmk)
              }
            }
          }
        }
      }

      if (isOk) {
        const rsltWt = this.checkCntrGrossWeightAndPackage('wt')
        const rsltPkg = this.checkCntrGrossWeightAndPackage('pkgQty')
        let rsltCbm = 0
        if (this.lang === 'JPN' || regParams.polCtrCd === 'PH' || regParams.polCtrCd === 'VN') {
          rsltCbm = this.checkCntrGrossWeightAndPackage('meaCbm')
        }

        if (rsltWt === -1) {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.097'))
          return
        } else if (rsltWt === -2) {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.098'))
          return
        }

        if (rsltPkg === -1) {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.095'))
          return
        } else if (rsltPkg === -2) {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.096'))
          return
        }

        if (this.lang === 'JPN' || regParams.polCtrCd === 'PH' || regParams.polCtrCd === 'VN') {
          if (rsltCbm === -1) {
            this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.102'))
            return
          } else if (rsltCbm === -2) {
            this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.103'))
            return
          }
        }

        this.setCntrGrsWtAndPkg(rsltWt, rsltPkg, rsltCbm)

        let cntrCheckMsg = this.$t('art.CMATK364')
        let cntrCheckMsgYn = 'N'

        let cgoCheckMsgYn = 'N'
        let saveOk = true

        if (regParams.containerList.length > 0) {
          regParams.containerList.forEach(c => {
            if (this.$ekmtcCommon.isNotEmpty(c.cntrNo)) {
              if (c.shprVanYn !== 'Y' || regParams.cgoModeCd !== '04') {
                if (c.cntrCheck === 'N' && this.$ekmtcCommon.isNotEmpty(c.cntrNo)) {
                  if (cntrCheckMsgYn === 'Y') {
                    cntrCheckMsg += ', '
                  }
                  cntrCheckMsg += c.cntrNo
                  cntrCheckMsgYn = 'Y'
                }
              }

              if (c.cgoCheckYn === 'N') {
                cgoCheckMsgYn = 'Y'
              }
            }

            // 카고타입명 HZ, 코드 01은 위험물이며, 컨테이너 중 위험물 있는지 체크
            if (c.cgoTypCd === '01') {
              chkMyHz = true
            }
          })
        }

        //1364520 면장란과 비엘의 중량이 불일치하나 S/R 제출 가능한 오류 개선 요청
        if (saveType === 'DCLR' && regParams.polCtrCd === 'KR') {
          //E/L Information 중량에 0입력 여부 확인 후 popup 오픈
          if (this.regParams.exportLicenseList.length > 0) {
            let checkZeroData = false
            this.regParams.exportLicenseList.forEach(c => {
              if (c.grsWt === '0' || c.grsWt === 0) {
                checkZeroData = true
                return false
              }
            })

            if (checkZeroData) {
              this.$ekmtcCommon.alertDefault(this.$t('art.CMATK408'))
              return
            }
          }

          //Container Information 중량에 0입력 여부 확인 후 popup 오픈
          if (regParams.containerList.length > 0) {
            let checkZeroData = false
            this.regParams.containerList.forEach(c => {
              if (c.wt === '0' || c.wt === 0) {
                checkZeroData = true
                return false
              }
            })

            if (checkZeroData) {
              this.$ekmtcCommon.alertDefault(this.$t('art.CMATK409'))
              return
            }
          }
        }

        // SR 작성 후 바로 제출시 SAVE_THEN, 저장후 제출시 DCLR로 넘어옴
        if (saveType === 'DCLR' || saveType === 'SAVE_THEN') {
          // 카고 타입 위험물 포함, POL MY, CN인 경우 DG-Certi 필수 체크. SR 1276477
          if (chkMyHz && (regParams.polCtrCd === 'MY' || regParams.polCtrCd === 'CN') && regParams.dgcertiYn !== 'Y') {
            let msg = this.$t('msg.CSBL200.288')

            if (regParams.polCtrCd === 'CN') {
              msg = this.$t('msg.CSBL200.292')
            }

            this.$ekmtcCommon.alertDefault(msg)
            return
          }
        }

        if (saveType === 'SAVE_THEN') {
          if (regParams.podShaYn === 'Y' && regParams.shaEmanYn !== 'Y' && regParams.emanShaYn !== 'Y') {
            this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200_P1.024'))
            return
          }
        }

        this.blPhbtList = await this.getRtnPhbtChr()

        if (saveType === 'DCLR' || saveType === 'DCLR_DIRECT') {
          if (cgoCheckMsgYn === 'Y') {
            const message = this.$t('msg.CSBL200.093') + '<br>' + this.$t('msg.CSBL200.094') + '<br>' + this.$t('msg.CSBL200.283')
            // SR container 중에 위험물이 하나라도 있으면 cgoCheckMsgYn 이 Y 이기 때문에 mixedYn 이 Y가 아니면 위험물만 입력가능.
            // 이런 경우 모든 케이스에서 mixedYn 이 Y 인 경우처럼 선택 할 수 있는 옵션을 제공.
            if (!await this.$ekmtcCommon.asyncAlertMessage({ message: message, useConfirmBtn: true })) {
              return
            }
            // if (regParams.mixedYn === 'Y') {
            //   if (!await this.$ekmtcCommon.asyncAlertMessage({ message: message, useConfirmBtn: true })) {
            //     return
            //   }
            // } else {
            //   this.$ekmtcCommon.alertDefault(message)
            //   return
            // }
          }

          if (regParams.dlyPlcCd === 'SGN') {
            if (!await this.fnDlyTrmlMsgChk()) {
              saveOk = false
            }
          }

          if (regParams.podShaYn === 'Y' && regParams.polShtmCd === '01' && (regParams.shaEmanYn !== 'Y' || regParams.emanShaYn !== 'Y')) {
            const arrChkPort = ['NCH', 'AQG', 'CZH', 'TOL', 'YCH', 'JIN', 'JJG', 'JZH', 'JXN',
                                'CHZ', 'CKG', 'CSX', 'CTU', 'CGS', 'HGH', 'HSI', 'JIA', 'CZX',
                                'LUZ', 'NKG', 'NTG', 'KUS', 'SZH', 'TAZ', 'TCG', 'SHA', 'WUH',
                                'WUX', 'YZH', 'WHI', 'ZHE', 'ZJG', 'MSN', 'JIU']
            if (arrChkPort.indexOf(regParams.polPortCd) === -1) {
              this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200_P1.022'))
              return
            }
          }

          if ((Number(regParams.obrdDt) < Number(regParams.etaObrdDt) || Number(regParams.obrdDt) > Number(regParams.etdObrdDt)) && this.lang !== 'JPN') {
            if ([regParams.etaObrdDt, regParams.etdObrdDt].indexOf(regParams.obrdDt) === -1) { // 입항일, 출항일이 잘못나온경우 입항일, 출항일중 하나와 동일한 경우 제출
              const etaObrdDt = this.$ekmtcCommon.changeDatePattern(regParams.etaObrdDt)
              const etdObrdDt = this.$ekmtcCommon.changeDatePattern(regParams.etdObrdDt)
              this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.274') + '( POL ETA : ' + etaObrdDt + '/ POL ETD : ' + etdObrdDt + ' )')
              return
            }
          }

          if (this.lang === 'KOR') {
            if (!await this.checkValidationDscrErr()) {
              saveOk = false
            }
          }

          if (this.lang === 'ENG' && regParams.unnoVaniYn === 'Y') {
            if (!await this.checkVanningInfo()) {
              saveOk = false
            }
          }

          /*
          if (!await this.checkValidationPhbtChrAll(blPhbtList)) {
            saveOk = false
          }
          */

          if (regParams.podCtrCd === 'VN' && regParams.wstCgoYn !== 'Y') {
            if (!await this.checkValidationVNWaste()) {
              saveOk = false
              return
            }
          }
        }

        if (cntrCheckMsgYn === 'Y') {
          if (!await this.$ekmtcCommon.asyncAlertMessage({ message: cntrCheckMsg, useConfirmBtn: true })) {
            saveOk = false
            return
          }
        }

        if (this.lang === 'KOR') {
          if (this.$ekmtcCommon.isEmpty(regParams.grsCbm) && saveType !== 'DCLR_DIRECT') {
            if (!await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.CSBL200.199'), useConfirmBtn: true })) {
              saveOk = false
              return
            }
          }
        }

        if (saveOk) {
          let saveMessage = saveType !== 'SAVE' && saveType !== 'UPD' ? this.$t('msg.CSBL200.167') : this.$t('msg.CSBL200.165')

          if (saveType !== 'DCLR_DIRECT') {
            if (!await this.$ekmtcCommon.asyncAlertMessage({ message: saveMessage, useConfirmBtn: true })) {
              return
            }

            if (regParams.dlyCtrCd === 'IN' && Number(regParams.pkgQty) !== Number(regParams.hsQty) && Number(regParams.hsQty) !== 0) {
              // 1462098 SCMTR -Regulation manifest error issues.
              //await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.CSBL200.276') })
              //this.hsCdMultiPop('02')
              //return

              /*if (!await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.CSBL200.273'), useConfirmBtn: true })) {
                return
              }*/
            }
          }

          regParams.containerList.forEach((c, i) => {
            c.sealNo1 = ''
            c.sealNo2 = ''
            c.sealNo3 = ''
            c.sealNo4 = ''
            c.sealNo5 = ''
            c.sealList.forEach((s, idx) => {
              c['sealNo' + (idx + 1)] = s.sealNo
            })
          })

          //regParams.frtPayPlcCd = regParams.frtPncCd === 'C' ? regParams.podPortCd : regParams.polPortCd

          this.freeTime = ''
          regParams.markDescList.forEach((vo, i) => {
            this.checkCAword(vo.mrk)
            this.checkCAword(vo.dscr)
          })

          if (saveType === 'DCLR' || saveType === 'DCLR_DIRECT') {
            if (regParams.podCtrCd === 'HK' || regParams.podCtrCd === 'PKG') {
              if (regParams.riderYn !== 'N') {
                regParams.riderYn = 'Y'
              }

              if (regParams.emanYn !== 'N') {
                regParams.emanYn = 'Y'
              }
            } else {
              if (this.isRider()) {
                if (regParams.riderYn !== 'N') {
                  regParams.emanYn = 'Y'
                }
              }
            }

            let byStr = this.checkValidationNboErr(regParams.cgoCont, 'BYTEXT', this.byStr, this.blPhbtList)
            regParams.byStrMsg = byStr

            let dscrMsg = ''
            regParams.markDescList.forEach(vo => {
              dscrMsg += this.checkValidationNboErr(vo.dscr, 'DSCR', this.dscrStr, this.blPhbtList)
            })

            regParams.dscrStrMsg = dscrMsg

            this.dscrStr = ''
            this.byStr = ''

            if (regParams.dlyCtrCd === 'IR' && regParams.dlyPlcCd === 'BUZ') {
              this.addMarkDesc()
              const lastIdx = this.regParams.markDescList.length - 1
              this.regParams.markDescList[lastIdx].dscr = 'INTENDED FEEDER VESSEL AT JEBEL ALI, MARC, TIAN YI, LOTUS MARITIMUS, RATANA THIDA OR HUNSA BHUM'
            }

            if (this.lang === 'KOR') {
              const rtnObj = await this.fnSrConstraintsCheck()
              if (rtnObj.isCons) {
                if (!rtnObj.isOk) {
                  return
                } else {
                  console.log('rtnObj.rtnVal@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@', rtnObj.rtnVal)
                  this.regParams.machineRmk = rtnObj.rtnVal
                  console.log('machineRmk@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@', this.regParams.machineRmk)
                  this.fnNoticePop()
                  return
                }
              }
            }
          }

          // regParams.markDescList.forEach(m => {
          //   let mrk = this.$ekmtcCommon.isNotEmpty(m.mrk) ? m.mrk : ''
          //   let dscr = this.$ekmtcCommon.isNotEmpty(m.dscr) ? m.dscr : ''

          //   mrk = mrk.replace(/\r/gi, '')
          //   dscr = dscr.replace(/\r/gi, '')

          //   m.mrk = mrk.split('\n').join('\r\n').trim()
          //   m.dscr = dscr.split('\n').join('\r\n').trim()
          // })
          this.markDescListChangeCarriageReturn(regParams.markDescList) // 줄바꿈 문자 치환

          if (saveType === 'SAVE' || saveType === 'SAVE_THEN') {
            this.insertSR(saveType)
          } else {
            this.updateSR(saveType)
          }
        }
      }
    },
    markDescListChangeCarriageReturn (markDescList) { // markDesc List의 줄바꿈 문자 치환
      markDescList.forEach(m => {
        let mrk = this.$ekmtcCommon.isNotEmpty(m.mrk) ? m.mrk : ''
        let dscr = this.$ekmtcCommon.isNotEmpty(m.dscr) ? m.dscr : ''

        mrk = mrk.replace(/\r/gi, '')
        dscr = dscr.replace(/\r/gi, '')

        m.mrk = mrk.split('\n').join('\r\n')
        m.dscr = dscr.split('\n').join('\r\n')
      })
    },
    async insertSR (flag) {
      const TH = this
      let succFlag = true
      let vgmInputYn = 'N'

      let result = null

      // if (this.regParams.blPrtrCont.indexOf('ZERO') > -1) {
      //   this.$ekmtcCommon.alertDefault(this.$t('js.working.001'))
      //   return
      // }

      await trans.insertSR(this.regParams).then(res => {
        if (res.headers.respcode !== 'C0000') {
          succFlag = false
        } else {
          result = res.data
          if (result !== undefined && (result.rtnCode === 'SUCC' || result.rtnCode === 'blChk')) {
            vgmInputYn = result.vgmInputYn
          } else {
            succFlag = false
          }
        }
      }).catch(() => {
        succFlag = false
      }).finally(async () => {
        if (succFlag) {
          await this.fnAfterAdd(this.regParams.srNo)
          let succMessage = this.$t('msg.CSBL200.166')
          if (this.freeTime === 'Y') {
            succMessage += '<br><br>' + this.$t('art.CMATK371')
          }

          this.saveFlag = 'SR_EDIT'
          if (flag === 'SAVE_THEN') {
            this.saveBL('DCLR_DIRECT')
          } else {
            if (result.rtnCode === 'blChk') {
              if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.CSBL200.221'), useConfirmBtn: true })) {
                TH.fnCommonBtnAction('ERROR_CHECK')
                TH.refresh()
              }
            } else {
              this.$ekmtcCommon.alertCallback(succMessage, async () => {
                if (vgmInputYn === 'N' && TH.lang === 'KOR' && TH.regParams.polPortCd !== 'HKG' && TH.regParams.polPortCd !== 'HKM') {
                  TH.requestedYn = result.requestedYn
                  TH.fnCommonBtnAction('VGMPOP_SAVE')
                } else {
                  await TH.refresh()
                }
              })
            }
          }
        } else {
          this.$ekmtcCommon.screenToPdfUpload(this.regParams, 'insertSR')
          this.$ekmtcCommon.alertDefault(this.$t('js.common.004'))
          this.markDescEnterSet()
        }
      })
    },
    async fnSrConstraintsCheck () {
      let isCons = false
      let isOk = true
      let rtnVal = ''
      const regParams = this.regParams
      const chrArray = [regParams.cgoCont]

      regParams.markDescList.forEach(vo => {
        chrArray.push(vo.mrk)
        chrArray.push(vo.dscr)
      })

      chrArray.forEach(vo => {
        if (this.$ekmtcCommon.isNotEmpty(vo)) {
          if (this.fnRtnCHRCheck_machine(vo)) {
            isCons = true
          }
        }
      })

      if (isCons) {
        isOk = await this.$ekmtcCommon.asyncAlertMessage({
            message: this.$t('msg.CSBL200.262'),
            useConfirmBtn: true,
            onConfirmSelf: (resolve, $Simplert) => {
              const popMachineChk = document.querySelectorAll('.popMachineChk:checked')
              const elem = document.querySelector('#popMachineO')
              if (popMachineChk.length === 0) {
                this.$ekmtcCommon.showErrorTooltip(elem, this.$t('msg.CSBL200.263'))
              } else if (popMachineChk.length > 1) {
                this.$ekmtcCommon.showErrorTooltip(elem, this.$t('msg.CSBL200.264'))
              } else {
                this.$ekmtcCommon.hideErrorTooltip(elem)
                rtnVal = popMachineChk[0].value
                if (rtnVal === 'O') {
                  this.$ekmtcCommon.showErrorTooltip(elem, this.$t('msg.CSBL200.265'))
                } else {
                  resolve(true)
                  $Simplert.close()
                }
              }
            }
        })
      }

      return {
        isOk: isOk,
        rtnVal: rtnVal,
        isCons: isCons
      }
    },
    fnRtnCHRCheck_machine (chr) {
      let isReturn = false
      const blPhbtList = this.blPhbtList

      blPhbtList.forEach(vo => {
        if (vo.apclYn === 'Y' || vo.apclYn === 'A') {
          if (vo.srCmplsSbmtYn === 'Y') {
            if (chr.indexOf(vo.phbtChr) > -1) {
              isReturn = true
            }
          }
        }
      })

      return isReturn
    },
    async updateSR (flag) {
      const TH = this
      const regParams = TH.regParams
      console.log('updateSR machineRmk@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@', this.regParams.machineRmk)
      if (flag !== 'UPD') {
        regParams.dclrFlag = 'Y'

        const obj = []
        obj.push(this.regParams.cgoCont)
        this.regParams.markDescList.forEach(m => {
          obj.push(m.mrk)
          obj.push(m.dscr)
        })

        let chrCheckOk = true
        for (let i = 0; i < obj.length; i++) {
          if (await this.fnRtnCHRCheck(this.blPhbtList, obj[i])) {
            chrCheckOk = false
          }
        }

        if (!chrCheckOk) {
          return
        }
      } else {
        regParams.dclrFlag = 'N'
      }
      //regParams.oldBlTypCd = this.originParams.blTypCd

      let succFlag = true
      let vgmInputYn = 'N'
      let result = null
console.log('#########################################################################################', regParams.machineRmk)
      this.markDescListChangeCarriageReturn(regParams.markDescList) // 줄바꿈 문자 치환

      await trans.updateSR(regParams).then(res => {
        if (res.headers.respcode !== 'C0000') {
          succFlag = false
        } else {
          result = res.data
          if (result !== undefined) {
            if (this.$ekmtcCommon.isEmpty(result.rtnCode) || result.rtnCode === 'FAIL') {
              succFlag = false
            } else {
              vgmInputYn = result.vgmInputYn
            }
          } else {
            succFlag = false
          }
        }
      }).catch(() => {
        succFlag = false
      }).finally(async () => {
        if (succFlag && this.$ekmtcCommon.isNotEmpty(result)) {
          const TH = this
          const cntrVerifyYn = result.cntrVerifyYn
          if (TH.$ekmtcCommon.isNotEmpty(cntrVerifyYn) && this.cntrVerifyPass !== 'Y') {
            if (cntrVerifyYn.indexOf('CONTAINER NO.') > -1) {
              TH.$ekmtcCommon.alertDefault(cntrVerifyYn + TH.$t('msg.CSBL200.217'))
            } else if (cntrVerifyYn.indexOf('PICK-UP BKG') > -1) {
              const msgObj = {
                message: cntrVerifyYn + TH.$t('msg.CSBL200.218'),
                useConfirmBtn: true,
                customConfirmBtnText: TH.$t('msg.ONEX020P120.021')
              }
              if (await TH.$ekmtcCommon.asyncAlertMessage(msgObj)) {
                this.regParams.sbCstrSbmtYn = 'Y'
                this.cntrVerifyPass = 'Y'
                TH.fnNoticePop()
              }
            } else {
              if (result.verifyPol === 'DSN') {
                if (await TH.$ekmtcCommon.asyncAlertMessage({ message: cntrVerifyYn, useConfirmBtn: true })) {
                  TH.regParams.verifyDsnYn = 'Y'
                  this.cntrVerifyPass = 'Y'
                  TH.saveBL('DCLR_DIRECT')
                }
              } else {
                TH.$ekmtcCommon.alertDefault(cntrVerifyYn)
              }
            }
          } else {
            if (result.rtnCode === 'SUCC') {
              if (result.requestedYn !== 'Y') {
                let succMessage = this.$t('msg.CSBL200.166')
                if (this.freeTime === 'Y') {
                  succMessage += '<br><br>' + this.$t('art.CMATK371')
                }

                this.$ekmtcCommon.alertCallback(succMessage, () => {
                  TH.saveFlag = 'SR_EDIT'
                  if (vgmInputYn === 'N' && TH.lang === 'KOR' && regParams.polPortCd !== 'HKG' && regParams.polPortCd !== 'HKM') {
                    TH.requestedYn = result.requestedYn
                    TH.fnCommonBtnAction('VGMPOP_SAVE')
                  } else {
                    if (TH.saveType === 'UPD') {
                      TH.refresh()
                    } else {
                      TH.refreshBL()
                    }
                  }
                })
              } else {
                this.$ekmtcCommon.alertCallback(this.$t('msg.CSBL200.219'), () => {
                  TH.saveFlag = 'SR_EDIT'
                  TH.refresh()
                })
              }
            } else if (result.rtnCode === 'DCLR') {
              let succMessage = this.$t('msg.CSBL200.168')
              if (this.$ekmtcCommon.isNotEmpty(result.verifyRmk)) {
                succMessage = result.verifyRmk + '<br><br>' + succMessage
              }

              TH.saveFlag = 'SR_EDIT'
              this.$ekmtcCommon.alertCallback(succMessage, () => {
                if (vgmInputYn === 'N' && TH.lang === 'KOR' && regParams.polPortCd !== 'HKG' && regParams.polPortCd !== 'HKM') {
                  TH.requestedYn = result.requestedYn
                  TH.fnCommonBtnAction('VGMPOP_SAVE')
                } else {
                  if (TH.saveType === 'UPD') {
                    TH.refresh()
                  } else {
                    TH.refreshBL()
                  }
                }
              })
            } else if (result.rtnCode === 'blChk') {
              const dclrFlag = result.dclrFlag

              TH.saveFlag = 'SR_EDIT'
              if (dclrFlag === 'Y') {
                if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.CSBL200.220'), useConfirmBtn: true })) {
                  TH.regParams.blChkYn = 'Y'
                  TH.regParams.srEmailYn = 'Y'
                  TH.updateSR('DCLR_DIRECT')
                } else {
                  TH.fnCommonBtnAction('ERROR_CHECK')
                  TH.refresh()
                }
              } else {
                if (await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.CSBL200.221'), useConfirmBtn: true })) {
                  TH.fnCommonBtnAction('ERROR_CHECK')
                  TH.refresh()
                }
              }
            } else if (result.rtnCode === 'InHsCdSanc') {
              const arrMsg = result.rtnMessage
              let rtnMessage = ''
              arrMsg.forEach(vo => {
                rtnMessage += TH.$t(vo)
              })

              if (TH.$ekmtcCommon.isEmpty(rtnMessage)) {
                rtnMessage = TH.$t('js.common.004')
                TH.$ekmtcCommon.alertDefault(rtnMessage)
              } else {
                TH.saveFlag = 'SR_EDIT'
                TH.$ekmtcCommon.alertCallback(rtnMessage, () => {
                  TH.refresh()
                  TH.hsCdPopLoad = 'Y'
                })
              }

              TH.markDescEnterSet()

              regParams.dclrFlag = 'N'
            } else if (result.rtnCode === 'InHsCdCntrSanc') {
              const arrMsg = result.rtnMessage
              let rtnMessage = ''
              arrMsg.forEach(vo => {
                rtnMessage += TH.$t(vo)
              })

              if (TH.$ekmtcCommon.isEmpty(rtnMessage)) {
                rtnMessage = TH.$t('js.common.004')
                TH.$ekmtcCommon.alertDefault(rtnMessage)
              } else {
                TH.saveFlag = 'SR_EDIT'
                TH.$ekmtcCommon.alertCallback(rtnMessage, () => {
                  //TH.refresh()
                  const catCd = this.regParams.polCtrCd === 'IN' ? '01' : '02'
                  this.hsCdMultiPop(catCd)
                  this.hsCdPopLoad = 'N'
                })
              }

              TH.markDescEnterSet()

              regParams.dclrFlag = 'N'
            }
          }
        } else {
          TH.$ekmtcCommon.screenToPdfUpload(TH.regParams, 'updateSR')
          //TH.$ekmtcCommon.alertDefault(TH.$t('js.common.004'))
          const arrMsg = result.rtnMessage
          let rtnMessage = ''
          arrMsg.forEach(vo => {
            rtnMessage += TH.$t(vo)
          })
          if (TH.$ekmtcCommon.isEmpty(rtnMessage)) {
            rtnMessage = TH.$t('js.common.004')
          }
          //1425495 [WEB] BKG/SR 선명항차 불일치인한 제출 불가건 개선요청의 건
          if (this.$ekmtcCommon.isNotEmpty(result.vslFlagYn) && result.vslFlagYn === 'Y') {
            if (this.regParams.polCtrCd === 'KR') {
              let msg = 'S/R과 Booking의 선명이 일치하지 않습니다.\nS/R을 제출하시겠습니까?\n(Booking과 동일한 선명/항차로 적용됩니다.)\n제출 = 확인 / 미제출 = 취소'
              if (await this.$ekmtcCommon.asyncAlertMessage({ message: msg, useConfirmBtn: true })) {
                this.regParams.vslFlagYn = 'Y'
                this.updateSR('DCLR')
              }
            } else {
              TH.$ekmtcCommon.alertDefault(rtnMessage)
              TH.refresh()
            }
          } else {
            TH.$ekmtcCommon.alertDefault(rtnMessage)
            TH.refresh()
          }
        }
      })
    },
    async cancelSR () {
      if (!await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('tem.CMAT030'), useConfirmBtn: true })) {
        return
      }

      let succFlag = true
      trans.cancelSR(this.regParams.srNo).then(res => {
        if (res.headers.respcode !== 'C0000') {
          succFlag = false
        }

        if (res.data !== 'SUCC') {
          succFlag = false
        }
      }).catch(() => {
        succFlag = false
      }).finally(() => {
        const TH = this
        if (succFlag) {
          this.$ekmtcCommon.alertCallback(this.$t('art.CMATK222'), () => {
            TH.$router.push({ name: 'progress' }).catch(() => {})
          })
        } else {
          this.$ekmtcCommon.alertDefault(this.$t('art.CMATK223') + ' ' + this.$t('art.CMATK185'))
        }
      })
    },
    fnNoticePop () {
      this.selectFunc = this.callBackNotice
      this.popParams = {}
      this.openPopup('sr-notice-pop')
    },
    async callBackNotice (flag) {
      console.log('machineRmk@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@', this.regParams.machineRmk)
      if (flag === 'OK') {
        this.saveFlag = 'SR_EDIT'
        await this.initBlBkgInfo()
        this.regParams.srCstrSbmtYn = 'Y'
        this.regParams.srCmplsSbmtYn = 'Y'
        console.log('machineRmk@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@', this.regParams.machineRmk)
        this.updateSR('DCLR')
      } else {
        this.resetSaveParam()
        this.regParams.srCstrSbmtYn = 'N'
        //this.regParams.machineRmk = ''
      }
    },
    resetSaveParam () {
      const regParams = this.regParams
      regParams.containerList.forEach((c, i) => {
          c.sealList.forEach((s, idx) => {
          c['sealNo' + (idx + 1)] = ''
        })
      })

      regParams.byStrMsg = ''
      regParams.dscrStrMsg = ''
      regParams.srCmplsSbmtYn = 'N'
      regParams.srCstrSbmtYn = 'N'
    },
    async checkValidationDscrErr () {
      let isOk = true
      let chkFlag = false
      const regParams = this.regParams
      const totPkgQty = regParams.pkgQty
      const totPkgCd = regParams.pkgCd
      const totGrsWt = regParams.grsWt
      const pkgCdList = this.commonCodes['01130']
      const pkgCdInfo = pkgCdList.filter(vo => vo.cd === totPkgCd)

      let totPkgNm = ''

      if (pkgCdInfo !== undefined && pkgCdInfo.length > 0) {
        totPkgNm = pkgCdInfo[0].cdNm
      }

      const dscr = regParams.markDescList[0].dscr

      const params = {
        totPkgQty: totPkgQty,
        totPkgNm: totPkgNm,
        dscr: dscr
      }

      await trans.fnPkgDscrErrCheck(params).then(async res => {
        if (res.headers.respcode === 'C0000') {
          const result = res.data

          if (result.rtnYn !== 'N') {
            const pkgErrNm = this.$ekmtcCommon.isEmpty(result.pkgErrNm) ? '' : result.pkgErrNm
            const pkgErrQty = this.$ekmtcCommon.isEmpty(result.pkgErrQty) ? '' : result.pkgErrQty

            const obj = {
              totPkgQty: totPkgQty,
              totPkgNm: totPkgNm,
              pkgErrQty: pkgErrQty,
              pkgErrNm: pkgErrNm,
              totGrsWt: totGrsWt
            }

            const message = this.makeConfirmPkgErrMessage(obj)

            if (!await this.$ekmtcCommon.asyncAlertMessage({ message: message, useConfirmBtn: true })) {
              isOk = false
            }
          }
        }
      })

      return isOk
    },
    async checkVanningInfo () {
      let saveOk = true
      let isSucc = true
      await trans.findVaniInfo(regParams.bkgNo).then(res => {
        if (res.headers.respcode === 'C0000') {
          const result = res.data
          if (result !== undefined) {
            if (result.vaniDtYn === 'N') {
              this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.205'))
              saveOk = false
              return
            }
            if (result.vaniCertYn === 'N') {
              this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.206'))
              saveOk = false
            }
          }
        } else {
          isSucc = false
        }
      }).catch(() => {
        isSucc = false
      }).finally(() => {
        if (!isSucc) {
          this.$ekmtcCommon.alertDefault('[Vanning Info] ' + this.$t('js.common.004'))
          saveOk = false
        }
      })

      return saveOk
    },
    async checkValidationVNWaste () {
      let isOk = true
      const regParams = this.regParams

      let messageYn = 'N'
      if (regParams.cgoCont.indexOf('WASTE') > -1 || regParams.cgoCont.indexOf('SCRAP') > -1) {
        messageYn = 'Y'
      }

      regParams.markDescList.forEach(m => {
        if (m.dscr.indexOf('WASTE') > -1 || m.dscr.indexOf('SCRAP') > -1) {
          messageYn = 'Y'
        }
      })

      if (messageYn === 'Y') {
        if (!await this.$ekmtcCommon.asyncAlertMessage({ message: this.$t('msg.CSBL200.178'), useConfirmBtn: true })) {
          isOk = false
        }
      }

      return isOk
    },
    isRider () {
      let rtn = false
      const regParams = this.regParams

      const shprCstAddr = this.$ekmtcCommon.isNotEmpty(regParams.shprCstAddr) ? regParams.shprCstAddr : ''
      if (shprCstAddr.indexOf('RIDER') >= 0) {
        rtn = true
      }

      const cneCstAddr = this.$ekmtcCommon.isNotEmpty(regParams.cneCstAddr) ? regParams.cneCstAddr : ''
      if (cneCstAddr.indexOf('RIDER') >= 0) {
        rtn = true
      }

      const ntifCstAddr = this.$ekmtcCommon.isNotEmpty(regParams.ntifCstAddr) ? regParams.ntifCstAddr : ''
      if (ntifCstAddr.indexOf('RIDER') >= 0) {
        rtn = true
      }

      const markDescInfo = regParams.markDescList[0]
      const mrk = this.$ekmtcCommon.isNotEmpty(markDescInfo.mrk) ? markDescInfo.mrk : ''
      const dscr = this.$ekmtcCommon.isNotEmpty(markDescInfo.dscr) ? markDescInfo.dscr : ''
      if (mrk.indexOf('RIDER') >= 0) {
        rtn = true
      }

      if (dscr.indexOf('RIDER') >= 0) {
        rtn = true
      }

      return rtn
    },
    setCntrGrsWtAndPkg (rsltWt, rsltPkg, rsltCbm) {
      if (rsltWt !== 0 && rsltPkg !== 0 && rsltCbm !== 0) {
        return
      }

      const TH = this
      const regParams = TH.regParams
      let cntr20hop = 0
      let cntr40hop = 0
      let cntr20Cnt = 0
      let cntr40Cnt = 0
      let cntrSz = 0
      let count = 0

      regParams.containerList.forEach((c, i) => {
        if (this.$ekmtcCommon.isNotEmpty(c.cntrNo)) {
          if (c.cntrSzCd === '20') {
            cntrSz += 1
            cntr20hop++
          } else {
            cntrSz += 1.3
            cntr40hop++
          }
          count++
        }
      })

      const totalPkg = Number(regParams.pkgQty)
      const totalWt = Number(regParams.grsWt)
      const totalCbm = Number(regParams.grsCbm)

      let cntrSz20Sum = Math.round((totalPkg * 1 / cntrSz * cntr20hop))
      let cntrSz40Sum = Math.round((totalPkg * 1 / cntrSz * cntr40hop))

      if (cntrSz20Sum + cntrSz40Sum !== totalPkg) {
        if (cntrSz20Sum > 0 && cntrSz40Sum > 0) {
          cntrSz40Sum = totalPkg - cntrSz20Sum
        } else {
          if (cntrSz20Sum > 0) {
            cntrSz20Sum = totalPkg
          } else {
            cntrSz40Sum = totalPkg
          }
        }
      }

      if (regParams.containerList.length === 1) {
        const cntrInfo = regParams.containerList[0]

        if (rsltWt === 0) {
          cntrInfo.wt = regParams.grsWt
        }

        if (rsltPkg === 0) {
          cntrInfo.pkgQty = regParams.pkgQty
        }

        if (this.lang === 'JPN' && rsltCbm === 0) {
          cntrInfo.meaCbm = regParams.grsCbm
        }
      } else {
        regParams.containerList.forEach((c, i) => {
          if (c.cntrSzCd === '20') {
            if (rsltWt === 0) {
              c.wt = (Math.round(totalWt * (1 / cntrSz) * 1000)) / 1000
            }
            cntr20Cnt++
            if (rsltPkg === 0) {
              if ((cntrSz20Sum % cntr20hop) < cntr20Cnt) {
                c.pkgQty = Math.floor(Number(cntrSz20Sum / cntr20hop))
              } else {
                c.pkgQty = Math.floor(Number(cntrSz20Sum / cntr20hop) + 1)
              }
            }

            if (this.lang === 'JPN' && rsltCbm === 0) {
              c.meaCbm = (Math.round(totalCbm * (1 / cntrSz) * 1000)) / 1000
            }
          } else {
            if (rsltWt === 0) {
              c.wt = (Math.round(totalWt * (1.3 / cntrSz) * 1000)) / 1000
            }
            cntr40Cnt++
            if (rsltPkg === 0) {
              if ((cntrSz40Sum % cntr40hop) < cntr40Cnt) {
                c.pkgQty = Math.floor(Number(cntrSz40Sum / cntr40hop))
              } else {
                c.pkgQty = Math.floor(Number(cntrSz40Sum / cntr40hop) + 1)
              }
            }

            if (this.lang === 'JPN' && rsltCbm === 0) {
              c.meaCbm = (Math.round(totalCbm * (1.3 / cntrSz) * 1000)) / 1000
            }
          }
        })
      }
    },
    checkValidationCntrNo (idx) {
      let isOk = true
      const regParams = this.regParams
      const frm = document.querySelector('#frm_sr')
      const selector = frm.querySelector('#cntrNo_' + idx)
      const cntrInfo = regParams.containerList[idx]
      const cntrTypCd = frm.querySelector('#cntrTypCd_' + idx)

      this.$ekmtcCommon.hideErrorTooltip(selector)

      if (regParams.containerList.length > 1) {
        if (this.$ekmtcCommon.isEmpty(cntrInfo.cntrNo)) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('art.CMATK363'))
          isOk = false
        }

        regParams.containerList.forEach((c, i) => {
          if (i !== idx && c.cntrNo === cntrInfo.cntrNo) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('art.CMATK177'))
            isOk = false
          }
        })
      }

      if (regParams.dlyPlcCd === 'BJO' || regParams.dlyPlcCd === 'LUU' || regParams.dlyPlcCd === 'ROQ') {
        if (cntrInfo.cntrNo.startsWith('MAXU') || cntrInfo.cntrNo.startsWith('INNU') || cntrInfo.cntrNo.startsWith('IRNU') || cntrInfo.cntrNo.startsWith('RALU')) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBL200.160'))
          isOk = false
        }
      }

      if (cntrInfo.sealList.length > 0 && cntrTypCd.value !== 'TK') {
        cntrInfo.sealList.forEach((s, i) => {
          if (!this.checkValidationSealNo(i, idx)) {
            isOk = false
          }
        })
      }

      return isOk
    },
    checkValidationELAll () {
      let isOk = true
      let arrEl = ['elNo']

      if (this.isIndonesia()) {
        arrEl = ['elNo', 'elNoValdDt', 'idCstmCd']
      }

      if (this.regParams.blEdiTypCd === 'S' && this.memberDetail.userCtrCd === 'KR') {
        arrEl = ['elNo', 'lcsPkgQty', 'lcsPkgCd', 'lcsGrsWt', 'ldgTcnt', 'pkgtgPkgQty', 'elNoValdDt']
      }

      arrEl.forEach(el => {
        this.regParams.exportLicenseList.forEach((c, idx) => {
          if (!this.checkValidationExportLicense(el, idx)) {
            isOk = false
          }
        })
      })

      return isOk
    },
    checkValidationExportLicense (flag, idx) {
      let isOk = true
      const regParams = this.regParams
      const frm = document.querySelector('#frm_sr')
      const selector = frm.querySelector('#' + flag + '_' + idx)
      const elInfo = regParams.exportLicenseList[idx]

      this.$ekmtcCommon.hideErrorTooltip(selector)

      if (flag === 'elNo') {
        let elNo = elInfo.elNo
        elNo = elNo.replace(/[^a-zA-Z0-9]/gi, '')
        this.$set(elInfo, 'elNo', elNo.toUpperCase())
      }

      if (regParams.exportLicenseList.length === 1 && this.$ekmtcCommon.isEmpty(elInfo.elNo)) {
        this.$ekmtcCommon.hideErrorTooltipAll(document.querySelector('.table_elInfo'))
      }

      if (flag === 'elNo') {
        if (regParams.exportLicenseList.length > 1 && !this.isIndonesia()) {
          if (this.$ekmtcCommon.isEmpty(selector.value)) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('art.CMATK362'))
            isOk = false
          }
        } else if (this.isIndonesia() && this.$ekmtcCommon.isNotEmpty(this.saveType) &&
            this.saveType !== 'SAVE' && this.saveType !== 'UPD') {
          if (elInfo.emptyYn !== 'Y') {
            if (this.$ekmtcCommon.isEmpty(selector.value)) {
              this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
              isOk = false
            }
          }
        }
      }

      if (regParams.exportLicenseList.length > 1) {
        regParams.exportLicenseList.forEach((e, i) => {
          if (i !== idx && e.elNo === elInfo.elNo) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBL200.194'))
            isOk = false
          }
        })
      }

      if (this.saveType !== 'SAVE' && this.saveType !== 'UPD' &&
            this.regParams.blEdiTypCd === 'S' &&
            this.$ekmtcCommon.isNotEmpty(elInfo.elNo) &&
            this.lang === 'KOR' &&
            regParams.exportLicenseList.length >= 1) {
        if (flag === 'ldgTcnt') {
          if (elInfo.prtlLdgYn === 'Y' && (this.$ekmtcCommon.isEmpty(selector.value) || String(selector.value) === '0')) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('art.CMATK344'))
            isOk = false
          }
        } else if (flag === 'pkgtgPkgQty') {
          if (this.$ekmtcCommon.isNotEmpty(elInfo.pkgtgCd) && this.$ekmtcCommon.isEmpty(selector.value)) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
            isOk = false
          }
        } else if (flag === 'elNoValdDt') {
          if (this.$ekmtcCommon.isNotEmpty(selector.value)) {
            const today = this.$ekmtcCommon.getStrToDate(String(this.serverYear) + String(this.serverMonth) + String(this.serverDay))
            const elDate = this.$ekmtcCommon.getStrToDate(elInfo.elNoValdDt)

            if (elDate > today) {
              this.$ekmtcCommon.showErrorTooltip(selector, this.$t('art.CMATK345'))
              isOk = false
            }
          } else {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
            isOk = false
          }
        } else if (this.$ekmtcCommon.isEmpty(selector.value)) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
          isOk = false
        }
      }

      //SR 1428093 콜롬보향 중량 소수점 2자리 처리
      if (this.regParams.podPortCd === 'CMB' && flag === 'lcsGrsWt') {
        if (selector.value.indexOf('.') > -1) {
          if (selector.value.substring(selector.value.indexOf('.') + 1).length > 2) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.550'))
            isOk = false
          }
        }
      }

      return isOk
    },
    checkValidationSealNo (idx, cntrIdX) {
      let isOk = true
      const regParams = this.regParams
      const frm = document.querySelector('#frm_sr')
      const selector = frm.querySelector(`[name="sealNo_${cntrIdX}_${idx}"]`)
      const sealNo = selector.value
      this.$ekmtcCommon.hideErrorTooltip(selector)
      const xx = cntrIdX + '_' + idx
      regParams.containerList.forEach((v, x) => {
        if (v.cntrTypCd !== 'TK') {
          regParams.containerList[x].sealList.forEach((s, i) => {
            const yy = x + '_' + i
            if (xx !== yy && s.sealNo === sealNo && this.$ekmtcCommon.isNotEmpty(s.sealNo)) {
              this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBL200.198'))
              isOk = false
            }
          })
        }
      })
      return isOk
    },
    checkValidationInfoAll () {
      let isOk = true
      let arrEl = ['porPlcNm', 'polPortNm', 'podPortNm', 'dlyPlcNm']

      const type = this.saveType
      const regParams = this.regParams

      if (type !== 'SAVE' && type !== 'UPD') {
        arrEl = ['porPlcNm', 'polPortNm', 'podPortNm', 'dlyPlcNm', 'shprCstEnm',
                  'cneCstEnm', 'ntifCstEnm', 'grsWt', 'pkgQty', 'pkgCd', 'cgoCont', 'hsCd', 'podHsCd', 'blPrtrCont']

      let cneeChkYn = 'Y'
      let ntifChkYn = 'Y'

      const cneAfrList = this.commonCodes.CS003
      const ntifAftList = this.commonCodes.CS004

      if (Number(this.regParams.jpPodCount) > 0 && Number(this.regParams.cnPodCount) > 0) {
        const cneCstInfo = cneAfrList.filter(vo => vo.cdNm === this.regParams.cneCstEnm)
        if (cneCstInfo !== null && cneCstInfo !== undefined && cneCstInfo.length > 0) {
          cneeChkYn = 'N'
        }

        const ntifCstInfo = ntifAftList.filter(vo => vo.cdNm === this.regParams.ntifCstEnm)
        if (ntifCstInfo !== null && ntifCstInfo !== undefined && ntifCstInfo.length > 0) {
          ntifChkYn = 'N'
        }
      }
      if ((regParams.cgoModeCd === '01' || regParams.cgoModeCd === '03')) {
          if (Number(regParams.jpPodCount) > 0) {
            const addEl = ['actShprCstTelNo', 'cneCstTelNo', 'ntifCstTelNo']
            addEl.forEach(el => {
              arrEl.push(el)
            })
          }

          if (Number(regParams.cnPodCount) > 0) {
            const addEl = ['actShprCstPicNm', 'actShprCstTelNo', 'cneCstPicNm', 'cneCstTelNo', 'ntifCstPicNm', 'ntifCstTelNo']
            addEl.forEach(el => {
              arrEl.push(el)
            })
          }

          if (Number(regParams.cnPodCount) > 0 || Number(regParams.shaPodTsCount) > 0) {
            const addEl = ['actShprIdNo', 'cneIdNo', 'ntifIdNo']
            addEl.forEach(el => {
              arrEl.push(el)
            })
          }
        }

        if (regParams.polPortCd === 'PKG' || regParams.polPortCd === 'PKW' || regParams.polCtrCd === 'MY') {
          const addEl = ['actShprIdCatCd', 'cneIdNo', 'ntifIdNo']
          addEl.forEach(el => {
            arrEl.push(el)
          })

          if (regParams.polPortCd === 'PKG' || regParams.polPortCd === 'PKW') {
            arrEl.push('actShprIdNo')
          }
        }

        if (regParams.polCtrCd === 'IN') {
          const addEl = ['actShprCstCtrCd', 'actShprCstCityNm', 'actShprPostNo', 'actShprCstPicNm', 'actShprCstTelNo', 'actShprCstFaxNo', 'actShprCstEmlAddr',
                        'cneCstCtrCd', 'cneCstCityNm', 'cneCstPostNo', 'cneCstPicNm', 'cneCstTelNo', 'cneCstFaxNo', 'cneCstEmlAddr',
                        'ntifCstCtrCd', 'ntifCstCityNm', 'ntifCstPostNo', 'ntifCstPicNm', 'ntifCstTelNo', 'ntifCstFaxNo', 'ntifCstEmlAddr',
                        'iecNo', 'invValCurCd', 'invValExp'
                      ]
          addEl.forEach(el => {
            arrEl.push(el)
          })

          if (regParams.actShprCstCtrCd === 'IN') {
            arrEl.push('actShprStateCd')
          } else {
            arrEl.push('actShprStateNm')
          }

          if (regParams.cneCstCtrCd === 'IN') {
            arrEl.push('cneCstStateCd')
          } else {
            arrEl.push('cneCstStateNm')
          }

          if (regParams.ntifCstCtrCd === 'IN') {
            arrEl.push('ntifCstStateCd')
          } else {
            arrEl.push('ntifCstStateNm')
          }
        }

        if (regParams.polCtrCd === 'ID') {
          let addEl = ['etTaxNo']
          addEl.forEach(el => {
            arrEl.push(el)
          })
        }

        if (regParams.podCtrCd === 'ID' || regParams.podCtrCd === 'JO' || regParams.podCtrCd === 'TZ' || regParams.podCtrCd === 'VN') {
          let addEl = ['idTaxNo']
          addEl.forEach(el => {
            arrEl.push(el)
          })
        }
        if (regParams.podCtrCd === 'EG') {
          let addEl = ['acidNo', 'cneAcidNo']
          addEl.forEach(el => {
          arrEl.push(el)
         })
        }
        if (regParams.podCtrCd === 'IN' || regParams.inTsYn === 'Y') {
          let addEl = ['actShprCstCtrCd', 'actShprCstCityNm', 'actShprCstEmlAddr',
                        'cneCstCtrCd', 'cneCstCityNm', 'cneCstPostNo', 'cneCstEmlAddr',
                        'ntifCstCtrCd', 'ntifCstCityNm', 'ntifCstPostNo', 'ntifCstEmlAddr', 'iecNo', 'ntifPanNo',
                        'invValCurCd', 'invValExp'
                      ]
          addEl.forEach(el => {
            arrEl.push(el)
          })

          if (regParams.actShprCstCtrCd === 'IN') {
            arrEl.push('actShprStateCd')
          } else {
            arrEl.push('actShprStateNm')
          }

          if (regParams.cneCstCtrCd === 'IN') {
            arrEl.push('cneCstStateCd')
          } else {
            arrEl.push('cneCstStateNm')
          }

          if (regParams.ntifCstCtrCd === 'IN') {
            arrEl.push('ntifCstStateCd')
          } else {
            arrEl.push('ntifCstStateNm')
          }
        }

        if (regParams.podCtrCd === 'VN') {
          if (regParams.cneCstEnm.indexOf('TO ORDER') === -1) {
            arrEl.push('cneCstTelNo')
            arrEl.push('cneCstEmlAddr')
          } else {
            arrEl.push('ntifCstTelNo')
            arrEl.push('ntifCstEmlAddr')
          }

          //arrEl.push('cneCstFaxNo')
          arrEl.push('idTaxNo')

          if (regParams.wstCgoYn === 'Y') {
            arrEl.push('imLicNo')
            arrEl.push('imBnkGurNo')
          }
        }

        if (regParams.podPortCd === 'DMM') {
          arrEl.push('cneCstTelNo')
          arrEl.push('cneCstEmlAddr')
        }

        if (regParams.podPortCd === 'NSA') {
          arrEl.push('gstNo')
          arrEl.push('iecPicEmlAddr')
        }

        if (regParams.polCtrCd === 'PH' || regParams.podCtrCd === 'PH') {
          //담당자, 전화번호, 이메일
          let addEl = ['actShprCstPicNm', 'actShprCstTelNo', 'cneCstPicNm', 'cneCstTelNo', 'cneCstEmlAddr',
            'ntifCstPicNm', 'ntifCstTelNo', 'ntifCstEmlAddr'
          ]
          addEl.forEach(el => {
            arrEl.push(el)
          })
        }

        //SR 1494861 ///Compulsory input consignee contact point for POD JP, CN///
        if (regParams.podCtrCd === 'CN') {
          let addEl = ['cneCstPicNm', 'cneCstTelNo', 'cneCstEmlAddr',
            'ntifCstPicNm', 'ntifCstTelNo', 'ntifCstEmlAddr'
          ]

          addEl.forEach(el => {
            arrEl.push(el)
          })
        }
      }

      arrEl.forEach(el => {
        if (!this.checkValidationInfo(el)) {
          isOk = false
        }
      })

      return isOk
    },
    checkValidationInfo (id) {
      let isOk = true
      const frm = document.querySelector('#frm_sr')
      const selector = frm.querySelector('#' + id)
      const regParams = this.regParams

      if (id !== 'podHsCd' && id !== 'hsCd') {
        this.$ekmtcCommon.hideErrorTooltip(selector)
      }

      if (id === 'blPrtrCont') {
        if (this.$ekmtcCommon.isNotEmpty(selector.value)) {
          if (this.regParams.blPrtrCont.length > 57) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBL200.207'))
            isOk = false
          }
        } else if (this.$ekmtcCommon.isEmpty(selector.value)) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
          isOk = false
        }
      } else if (id === 'podHsCd') {
        // 카고 모드 01037 체크 (현재 무조건 01만 사용하고 나머지 사용 안한다고 함)
        if ((regParams.cgoModeCd === '01' || regParams.cgoModeCd === '03') && this.$ekmtcCommon.isEmpty(selector.value)) {
          // if문 한개에 조건이 너무 많아 구분하여 분리함
          // blEdiTypCd : E/L TYPE - E-EMPTY, S-LINE, C-HOUSE B/L / emptyFullYn : CS002I 조회하여 컨테이너가 풀이면 Y, empty면 N으로 세팅됨
          if (regParams.blEdiTypCd !== 'E' || regParams.emptyFullYn === 'Y') {
            // POD = 'JP' OR 'MY', (POL = 'NBO', VSL_CD = 'FDR') 체크
            if (Number(regParams.jpPodCount) !== 0 || Number(regParams.nboFdrCount) !== 0 || Number(regParams.myPodCount) !== 0) {
              this.hsChkParams.hscdPodErrorMessage = ''
              this.$nextTick(() => { this.hsChkParams.hscdPodErrorMessage = this.$t('msg.CSBK100.147') })
              isOk = false
            }
            // POD, DLY 국가별 체크
            if (regParams.podCtrCd === 'ID' || regParams.podCtrCd === 'IN' || regParams.podCtrCd === 'VN' ||
              regParams.podCtrCd === 'PK' || regParams.dlyCtrCd === 'PH' || regParams.inTsYn === 'Y') {
              this.hsChkParams.hscdPodErrorMessage = ''
              this.$nextTick(() => { this.hsChkParams.hscdPodErrorMessage = this.$t('msg.CSBK100.147') })
              isOk = false
            }
            // POL 태국, POD 특정 포트 체크
            if (regParams.polCtrCd === 'TH' && (regParams.podPortCd === 'JEA' || regParams.podPortCd === 'SOH')) {
              this.hsChkParams.hscdPodErrorMessage = ''
              this.$nextTick(() => { this.hsChkParams.hscdPodErrorMessage = this.$t('msg.CSBK100.147') })
              isOk = false
            }
            // 이외 복합조건 아래에 추가하면 됨
            if (regParams.shaTsYn === 'Y' || regParams.aimRteYn === 'Y') {
              this.hsChkParams.hscdPodErrorMessage = ''
              this.$nextTick(() => { this.hsChkParams.hscdPodErrorMessage = this.$t('msg.CSBK100.147') })
              isOk = false
            }
            // SR 1353609 특정 POD 포트인 경우 POD HS CODE 필수 입력 요청 건 체크
            // 1. 방콕, 라엠차방, 라크라방(태국), 자카르타(인도네시아)
            // 2. 하이퐁(베트남), 황푸, 마웨이, 샤먼(중국)
            // 3. 첸나이, 문드라, 나바셰바 (인도), 세마랑(인도네시아)
            // 4. 일본 모든 포트
            // 인도, 인도네시아, 베트남은 이미 POD 국가로 추가 되어 있어 제외. 일본은 위 POD 국가 조건에 추가. 나머지 포트 아래 추가.
            // 2024.02.02 고서팀 최기영 대리님 요청으로 원복(아래 주석처리)함.
            // if (regParams.podPortCd === 'BKK' || regParams.podPortCd === 'LCH' || regParams.podPortCd === 'LKB' ||
            //   regParams.podPortCd === 'HUA' || regParams.podPortCd === 'FOC' || regParams.podPortCd === 'XMN') {
            //   this.hsChkParams.hscdPodErrorMessage = ''
            //   this.$nextTick(() => { this.hsChkParams.hscdPodErrorMessage = this.$t('msg.CSBK100.147') })
            //   isOk = false
            // }
          }
          // if (((regParams.blEdiTypCd !== 'E' || regParams.emptyFullYn === 'Y') && Number(regParams.jpPodCount) !== 0) ||
          //     (regParams.blEdiTypCd !== 'E' && (
          //       Number(regParams.nboFdrCount) !== 0 ||
          //       (regParams.podCtrCd === 'ID' || Number(regParams.myPodCount) !== 0 || regParams.podCtrCd === 'IN') ||
          //       regParams.podCtrCd === 'VN' || regParams.podCtrCd === 'PK' ||
          //       regParams.shaTsYn === 'Y' ||
          //       regParams.aimRteYn === 'Y' ||
          //       regParams.dlyCtrCd === 'PH' || (regParams.polCtrCd === 'TH' && (regParams.podPortCd === 'JEA' || regParams.podPortCd === 'SOH'))
          //     ))
          // ) {
          //   this.hsChkParams.hscdPodErrorMessage = ''
          //   this.$nextTick(() => {this.hsChkParams.hscdPodErrorMessage = this.$t('msg.CSBK100.147')})
          //   isOk = false
          // }
        }
      } else if (id === 'hsCd') {
        if ((regParams.cgoModeCd === '01' || regParams.cgoModeCd === '03') && this.$ekmtcCommon.isEmpty(selector.value)) {
          // POL 국가별 체크
          if (regParams.polCtrCd === 'ID' || regParams.polCtrCd === 'MY' || regParams.polCtrCd === 'IN') {
            this.hsChkParams.hscdPolErrorMessage = ''
            this.$nextTick(() => { this.hsChkParams.hscdPolErrorMessage = this.$t('msg.CSBK100.147') })
            isOk = false
          }
          // POL 태국, POD 특정 포트 체크
          if (regParams.polCtrCd === 'TH' && (regParams.podPortCd === 'JEA' || regParams.podPortCd === 'SOH')) {
            this.hsChkParams.hscdPolErrorMessage = ''
            this.$nextTick(() => { this.hsChkParams.hscdPolErrorMessage = this.$t('msg.CSBK100.147') })
            isOk = false
          }
          // SR 1353609 특정 POD 포트인 경우 POL, POD HS CODE 필수 입력 요청 건 체크
          // 1. 방콕, 라엠차방, 라크라방(태국), 자카르타(인도네시아)
          // 2. 하이퐁(베트남), 황푸, 마웨이, 샤먼(중국)
          // 3. 첸나이, 문드라, 나바셰바 (인도), 세마랑(인도네시아)
          // 4. 일본 모든 포트
          // 2024.02.02 고서팀 최기영 대리님 요청으로 원복(아래 주석처리)함.
          // if (regParams.podPortCd === 'BKK' || regParams.podPortCd === 'LCH' || regParams.podPortCd === 'LKB' ||
          //   regParams.podPortCd === 'JKT' || regParams.podPortCd === 'HPH' || regParams.podPortCd === 'HUA' ||
          //   regParams.podPortCd === 'FOC' || regParams.podPortCd === 'XMN' || regParams.podPortCd === 'MAA' ||
          //   regParams.podPortCd === 'MUN' || regParams.podPortCd === 'NSA' || regParams.podPortCd === 'SRG' ||
          //   regParams.podCtrCd === 'JP') {
          //   this.hsChkParams.hscdPolErrorMessage = ''
          //   this.$nextTick(() => { this.hsChkParams.hscdPolErrorMessage = this.$t('msg.CSBK100.147') })
          //   isOk = false
          // }
          // SR 1440595 POL HS CODE 필수 기입값으로 제약함
          // 긴급 배포 요청으로 다시 제거
          // if (this.$ekmtcCommon.isEmpty(selector.value)) {
          //   this.hsChkParams.hscdPolErrorMessage = ''
          //   this.$nextTick(() => {
          //     this.hsChkParams.hscdPolErrorMessage = this.$t('msg.CSBK100.549')
          //   })
          //   isOk = false
          // }
        }
        // if ((regParams.cgoModeCd === '01' || regParams.cgoModeCd === '03') && (regParams.polCtrCd === 'ID' || regParams.polCtrCd === 'MY' || regParams.polCtrCd === 'IN' || (regParams.polCtrCd === 'TH' && (regParams.podPortCd === 'JEA' || regParams.podPortCd === 'SOH')))) {
        //   if (this.$ekmtcCommon.isEmpty(selector.value)) {
        //     this.hsChkParams.hscdPolErrorMessage = ''
        //     this.$nextTick(() => {this.hsChkParams.hscdPolErrorMessage = this.$t('msg.CSBK100.147')})
        //     isOk = false
        //   }
        // }
      } else if (id === 'actShprIdNo') {
        const actCstIdInfo = this.actCstIdList.filter(vo => vo.cstIdCatCd === regParams.actShprIdCatCd)

        if (regParams.polPortCd === 'PKG' || regParams.polPortCd === 'PKW') {
          if (this.$ekmtcCommon.isEmpty(selector.value)) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
            isOk = false
          } else {
            if (actCstIdInfo !== undefined && actCstIdInfo.length > 0) {
              if (Number(actCstIdInfo[0].minInpLen) > selector.value.length ||
                  Number(actCstIdInfo[0].maxInpLen) < selector.value.length
              ) {
                const message = this.$t('msg.CSBL200.210') + actCstIdInfo[0].minInpLen + ' ~ ' + actCstIdInfo[0].maxInpLen + ')'
                this.$ekmtcCommon.showErrorTooltip(selector, message)
                isOk = false
              }
            }
          }
        } else {
          if (actCstIdInfo !== undefined && actCstIdInfo.length > 0) {
            if (Number(actCstIdInfo[0].minInpLen) > selector.value.length ||
                Number(actCstIdInfo[0].maxInpLen) < selector.value.length
            ) {
              const message = this.$t('msg.CSBL200.210') + actCstIdInfo[0].minInpLen + ' ~ ' + actCstIdInfo[0].maxInpLen + ')'
              this.$ekmtcCommon.showErrorTooltip(selector, message)
              isOk = false
            }
          }
        }
      } else if (id === 'actShprIdCatCd') {
        if ((regParams.polPortCd === 'PKG' || regParams.polPortCd === 'PKW') && this.$ekmtcCommon.isEmpty(selector.value)) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
          isOk = false
        }
      } else if (id === 'cneIdNo') {
        const cneIdInfo = this.cneCstIdList.filter(vo => vo.cstIdCatCd === regParams.cneIdCatCd)

        if (regParams.polPortCd === 'PKG' || regParams.polPortCd === 'PKW') {
          if (this.$ekmtcCommon.isNotEmpty(selector.value) && cneIdInfo !== undefined && cneIdInfo.length > 0) {
            if (Number(cneIdInfo[0].minInpLen) > selector.value.length ||
                Number(cneIdInfo[0].maxInpLen) < selector.value.length
            ) {
              const message = this.$t('msg.CSBL200.211') + cneIdInfo[0].minInpLen + ' ~ ' + cneIdInfo[0].maxInpLen + ')'
              this.$ekmtcCommon.showErrorTooltip(selector, message)
              isOk = false
            }
          } else if (this.$ekmtcCommon.isNotEmpty(frm.querySelector('#cneIdCatCd').value) && this.$ekmtcCommon.isEmpty(selector.value)) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
            isOk = false
          }
        } else {
          if (this.$ekmtcCommon.isNotEmpty(selector.value) && cneIdInfo !== undefined && cneIdInfo.length > 0) {
            if (Number(cneIdInfo[0].minInpLen) > selector.value.length ||
                Number(cneIdInfo[0].maxInpLen) < selector.value.length
            ) {
              const message = this.$t('msg.CSBL200.211') + cneIdInfo[0].minInpLen + ' ~ ' + cneIdInfo[0].maxInpLen + ')'
              this.$ekmtcCommon.showErrorTooltip(selector, message)
              isOk = false
            }
          }
        }
      } else if (id === 'ntifIdNo') {
        const ntifIdInfo = this.notiCstIdList.filter(vo => vo.cstIdCatCd === regParams.ntifIdCatCd)

        if (regParams.polPortCd === 'PKG' || regParams.polPortCd === 'PKW') {
          if (this.$ekmtcCommon.isNotEmpty(selector.value) && ntifIdInfo !== undefined && ntifIdInfo.length > 0) {
            if (Number(ntifIdInfo[0].minInpLen) > selector.value.length ||
                Number(ntifIdInfo[0].maxInpLen) < selector.value.length
            ) {
              const message = this.$t('msg.CSBL200.212') + ntifIdInfo[0].minInpLen + ' ~ ' + ntifIdInfo[0].maxInpLen + ')'
              this.$ekmtcCommon.showErrorTooltip(selector, message)
              isOk = false
            }
          } else if (this.$ekmtcCommon.isNotEmpty(frm.querySelector('#ntifIdCatCd').value) && this.$ekmtcCommon.isEmpty(selector.value)) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
            isOk = false
          }
        } else {
          if (this.$ekmtcCommon.isNotEmpty(selector.value) && ntifIdInfo !== undefined && ntifIdInfo.length > 0) {
            if (Number(ntifIdInfo[0].minInpLen) > selector.value.length ||
                Number(ntifIdInfo[0].maxInpLen) < selector.value.length
            ) {
              const message = this.$t('msg.CSBL200.212') + ntifIdInfo[0].minInpLen + ' ~ ' + ntifIdInfo[0].maxInpLen + ')'
              this.$ekmtcCommon.showErrorTooltip(selector, message)
              isOk = false
            }
          }
        }
      } else if (id === 'iecNo') {
        if (regParams.polCtrCd === 'IN' && this.$ekmtcCommon.isEmpty(selector.value)) {
          if (this.$ekmtcCommon.isEmpty(regParams.panNo)) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBL200.213'))
            isOk = false
          }
        } else if (this.$ekmtcCommon.isEmpty(selector.value)) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
          isOk = false
        } else {
          if (selector.value.length < 10) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBL200.214'))
            isOk = false
          }
        }
      } else if (id === 'ntifPanNo') {
        if (this.$ekmtcCommon.isEmpty(selector.value)) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
          isOk = false
        } else if (selector.value.length < 10) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBL200.214'))
          isOk = false
        }
      } else if (id === 'etTaxNo') {
        if (this.$ekmtcCommon.isEmpty(regParams.etTaxNo)) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
          isOk = false
        }
      } else if (id === 'grsWt') {
        if (this.$ekmtcCommon.isEmpty(selector.value)) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
          isOk = false
        } else {
          //SR 1428093 콜롬보향 중량 소수점 2자리 처리
          if (this.regParams.podPortCd === 'CMB') {
            if (selector.value.indexOf('.') > -1) {
              if (selector.value.substring(selector.value.indexOf('.') + 1).length > 2) {
                this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.550'))
                isOk = false
              }
            }
          }
        }
      } else if (id.indexOf('wt') === 0) {
        //SR 1428093 콜롬보향 중량 소수점 2자리 처리
        if (this.regParams.podPortCd === 'CMB') {
          if (selector.value.indexOf('.') > -1) {
            if (selector.value.substring(selector.value.indexOf('.') + 1).length > 2) {
              this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.550'))
              isOk = false
            }
          }
        }
      } else if (id === 'acidNo') {
        if (this.regParams.podCtrCd === 'EG') {
          if (this.$ekmtcCommon.isEmpty(selector.value)) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
            isOk = false
          } else {
            if (selector.value.length !== 19) {
              this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBL200.295'))
              isOk = false
            }
          }
        }
      } else if (id === 'cneAcidNo') {
        if (this.regParams.podCtrCd === 'EG') {
          if (this.$ekmtcCommon.isEmpty(selector.value)) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
            isOk = false
          } else {
            if (selector.value.length !== 9) {
              this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBL200.296'))
              isOk = false
            }
          }
        }
      } else if (id === 'idTaxNo') {
        if (this.regParams.podCtrCd === 'ID' || this.regParams.podCtrCd === 'JO' || this.regParams.podCtrCd === 'TZ' || this.regParams.podCtrCd === 'VN') {
          this.$ekmtcCommon.hideErrorTooltip(selector)
          if (this.$ekmtcCommon.isEmpty(selector.value)) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
            isOk = false
          } else {
            if (this.regParams.podCtrCd === 'ID') {
              if (this.regParams.idTaxNo.length !== 16) {
                this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBL200_M.038'))
                isOk = false
              }
            } else if (this.regParams.podCtrCd === 'JO' || this.regParams.podCtrCd === 'TZ') {
              if (this.regParams.idTaxNo.length !== 9) {
                this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBL200_M.043'))
                isOk = false
              }
            }
          }
        }
      } else if (id === 'grsCbm') {
        // 테스트 끝나면 주석 해제
        if (this.regParams.podCtrCd === 'BH') {
          if (Number(this.regParams.grsCbm) <= 0) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.238'))
            isOk = false
          }
        }
      } else {
        if (this.$ekmtcCommon.isEmpty(selector.value)) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
          isOk = false
        }
      }

      return isOk
    },
    async checkValidationIranHsCdAll () {
      let isOk = true

      if (!await this.fnIranCheck('POL')) {
        this.hsChkParams.hscdPolErrorMessage = this.$t('msg.CSBK100.117')
        isOk = false
      }

      if (!await this.fnIranCheck('POD')) {
        this.hsChkParams.hscdPolErrorMessage = this.$t('msg.CSBK100.117')
        isOk = false
      }

      return isOk
    },
    checkValidationHsCd () {
      let isOk = true

      if (this.hsChkParams.polSuccYn === 'N' || this.hsChkParams.podSuccYn === 'N') {
        isOk = false
      }

      return isOk
    },
    checkCntrGrossWeightAndPackage (key) {
      const regParams = this.regParams
      let totalVal = 0

      if (key === 'meaCbm') {
        totalVal = Number(regParams.grsCbm)
      } else if (key === 'wt') {
        totalVal = Number(regParams.grsWt)
      } else {
        totalVal = Number(regParams.pkgQty)
      }
      let checkVal = 0
      let zeroIndex = -1

      let tmpWt = 0
      regParams.containerList.forEach((c, i) => {
        if (this.$ekmtcCommon.isNotEmpty(c[key])) {
          tmpWt += Number(c[key])
        }

        if (i > 0 && zeroIndex === -1 && tmpWt === 0) {
          zeroIndex = i
        }
      })

      if (totalVal !== 0) {
        totalVal = parseInt(totalVal * 1000) / 1000
      }

      if (tmpWt !== 0) {
        tmpWt = parseInt(tmpWt * 1000) / 1000
      }

      if (tmpWt === 0) {
        if (key === 'wt') {
          regParams.wtCmplsYn = 'N'
        }
        return 0
      } else if (tmpWt !== totalVal) {
        checkVal = tmpWt - totalVal

        if (checkVal >= 1 || checkVal <= -1) {
          return -1
        }
      } else {
        if (zeroIndex > -1) {
          return -2
        } else {
          if (key === 'wt') {
            regParams.wtCmplsYn = 'Y'
          }
          return 1
        }
      }
    },
    checkTotPkgWt () {
      const regParams = this.regParams
      const totalPkg = Number(regParams.pkgQty)
      const totalWt = Number(regParams.grsWt)
      const totalCbm = Number(regParams.grsCbm)
      const frm = document.querySelector('#frm_sr')

      let sumPkg = 0
      let sumWt = 0
      let pkgtgCd = ''
      let pkgtgCdCheck = ''
      let elCheck = 'N'

      regParams.exportLicenseList.forEach((vo, idx) => {
        sumPkg += Number(vo.pkgQty)
        sumWt = Math.round((sumWt + Number(vo.grsWt)) * 1000) / 1000

        if (vo.pkgtgCd === 'A') {
          pkgtgCdCheck = 'Y'
        }

        if (this.$ekmtcCommon.isNotEmpty(vo.elNo)) {
          elCheck = 'Y'
        }
      })

      if (sumPkg !== totalPkg && pkgtgCdCheck !== 'Y' && elCheck === 'Y') {
        this.$ekmtcCommon.alertDefault(this.$t('art.CMATK349'))
        regParams.exportLicenseList.forEach((vo, idx) => {
          this.$ekmtcCommon.showErrorTooltip(frm.querySelector('#lcsPkgQty_' + idx), this.$t('art.CMATK349'))
        })
        return false
      }

      if (sumWt !== totalWt && pkgtgCdCheck !== 'Y') {
        this.$ekmtcCommon.alertDefault(this.$t('art.CMATK350'))
        regParams.exportLicenseList.forEach((vo, idx) => {
          this.$ekmtcCommon.showErrorTooltip(frm.querySelector('#lcsGrsWt_' + idx), this.$t('art.CMATK350'))
        })
        return false
      }

      return true
    },
    async getRtnPhbtChr () {
      const regParams = this.regParams
      const etd = this.$ekmtcCommon.isNotEmpty(regParams.etdDt) ? regParams.etdDt.substring(0, 8) : ''
      const eta = this.$ekmtcCommon.isNotEmpty(regParams.etaDt) ? regParams.etaDt.substring(0, 8) : ''
      const params = {
        bkgNo: regParams.bkgNo,
        porCtrCd: regParams.porCtrCd,
        porPortCd: regParams.porPlcCd,
        polCtrCd: regParams.polCtrCd,
        polPortCd: regParams.polPortCd,
        podCtrCd: regParams.podCtrCd,
        podPortCd: regParams.podPortCd,
        podTrmlCd: regParams.podTrmlCd,
        dlyCtrCd: regParams.dlyCtrCd,
        dlyPortCd: regParams.dlyPlcCd,
        eta: eta,
        etd: etd,
        bkgDt: regParams.bkgDt,
        uiFlag: 'BL'
      }

      let blPhbtList = []
      await trans.getRtnPhbtChrChk(params).then(res => {
        if (res.headers.respcode === 'C0000') {
          blPhbtList = res.data.rtnPhbtList
        }
      })

      return blPhbtList
    },
    checkValidationNboErr (obj, flag, str, blPhbtList) {
      let rtnStr = str

      blPhbtList.forEach(vo => {
        if (vo.apclYn === 'A') {
          if (obj.indexOf(vo.phbtChr) > -1) {
            if (flag === 'DSCR') {
              this.regParams.descrErrorYn = 'Y'
              this.regParams.descrErrorAllYn = vo.tsYn === 'N' ? 'Y' : ''

              if (rtnStr.indexOf(vo.phbtChr) === -1) {
                rtnStr += vo.phbtChr + '\n'
              }
            } else {
              this.regParams.bytextErrorYn = 'Y'
              this.regParams.descrErrorAllYn = vo.tsYn === 'N' ? 'Y' : ''

              if (rtnStr.indexOf(vo.phbtChr) === -1) {
                rtnStr += vo.phbtChr + '\n'
              }
            }
          }
        }
      })

      return rtnStr
    },
    async checkValidationPhbtChrAll (blPhbtList) {
      let isOk = true
      const regParams = this.regParams

      blPhbtList.forEach(async vo => {
        if (vo.apclYn === 'Y' || vo.apclYn === 'A') {
          regParams.markDescList.forEach(async (m, idx) => {
            if (!await this.checkValidationPhbtChr('mrk', idx, vo)) {
              isOk = false
            }

            if (!await this.checkValidationPhbtChr('dscr', idx, vo)) {
              isOk = false
            }
          })
        }
      })

      return isOk
    },
    async checkValidationPhbtChr (flag, idx, phbtInfo) {
      const regParams = this.regParams
      const frm = document.querySelector('#frm_sr')
      const selector = frm.querySelector('#' + flag + '_' + idx)

      const cntrSzArr = []
      const cntrTypArr = []
      const cntrCgoArr = []

      regParams.containerList.forEach(c => {
        cntrSzArr.push(c.cntrSzCd)
        cntrTypArr.push(c.cntrTypCd)
        cntrCgoArr.push(c.cgoTypCd)
      })

      this.$ekmtcCommon.hideErrorTooltip(selector)

      let message = ''

      if (this.$ekmtcCommon.isNotEmpty(selector.value)) {
        if (selector.value.indexOf(phbtInfo.phbtChr) > -1) {
          if (this.$ekmtcCommon.isNotEmpty(phbtInfo.cntrSzCd) && this.$ekmtcCommon.isEmpty(phbtInfo.cntrTypCd) && this.$ekmtcCommon.isEmpty(phbtInfo.cgoTypCd)) {
            if (cntrSzArr.indexOf(phbtInfo.cntrSzCd) > -1) {
              message = phbtInfo.rmk
            }
          } else if (this.$ekmtcCommon.isNotEmpty(phbtInfo.cntrSzCd) && this.$ekmtcCommon.isNotEmpty(phbtInfo.cntrTypCd) && this.$ekmtcCommon.isEmpty(phbtInfo.cgoTypCd)) {
            if (cntrSzArr.indexOf(phbtInfo.cntrSzCd) > -1 && cntrTypArr.indexOf(phbtInfo.cntrTypCd) > -1) {
              message = phbtInfo.rmk
            }
          } else if (this.$ekmtcCommon.isNotEmpty(phbtInfo.cntrSzCd) && this.$ekmtcCommon.isNotEmpty(phbtInfo.cntrTypCd) && this.$ekmtcCommon.isNotEmpty(phbtInfo.cgoTypCd)) {
            if (cntrSzArr.indexOf(phbtInfo.cntrSzCd) > -1 && cntrTypArr.indexOf(phbtInfo.cntrTypCd) > -1 && cntrCgoArr.indexOf(phbtInfo.cgoTypCd) > -1) {
              message = phbtInfo.rmk
            }
          } else if (this.$ekmtcCommon.isEmpty(phbtInfo.cntrSzCd) && this.$ekmtcCommon.isNotEmpty(phbtInfo.cntrTypCd) && this.$ekmtcCommon.isEmpty(phbtInfo.cgoTypCd)) {
            if (cntrTypArr.indexOf(phbtInfo.cntrTypCd) > -1) {
              message = phbtInfo.rmk
            }
          } else if (this.$ekmtcCommon.isEmpty(phbtInfo.cntrSzCd) && this.$ekmtcCommon.isNotEmpty(phbtInfo.cntrTypCd) && this.$ekmtcCommon.isNotEmpty(phbtInfo.cgoTypCd)) {
            if (cntrTypArr.indexOf(phbtInfo.cntrTypCd) > -1 && cntrCgoArr.indexOf(phbtInfo.cgoTypCd) > -1) {
              message = phbtInfo.rmk
            }
          } else if (this.$ekmtcCommon.isEmpty(phbtInfo.cntrSzCd) && this.$ekmtcCommon.isEmpty(phbtInfo.cntrTypCd) && this.$ekmtcCommon.isNotEmpty(phbtInfo.cgoTypCd)) {
            if (cntrCgoArr.indexOf(phbtInfo.cgoTypCd) > -1) {
              message = phbtInfo.rmk
            }
          } else if (this.$ekmtcCommon.isNotEmpty(phbtInfo.cntrSzCd) && this.$ekmtcCommon.isEmpty(phbtInfo.cntrTypCd) && this.$ekmtcCommon.isNotEmpty(phbtInfo.cgoTypCd)) {
            if (cntrSzArr.indexOf(phbtInfo.cntrSzCd) > -1 && cntrCgoArr.indexOf(phbtInfo.cgoTypCd) > -1) {
              message = phbtInfo.rmk
            }
          } else {
            message = phbtInfo.rmk
          }
        }

        this.phbtAlertMsg.push(phbtInfo.rmk)
      }

      if (phbtInfo.apclYn === 'Y' && message !== '') {
        await this.$ekmtcCommon.asyncAlertMessage({ message: message })
        return false
      } else if (phbtInfo.apclYn === 'A' && message !== '' && this.phbtAlertMsg.indexOf(message) === -1) {
        if (await this.$ekmtcCommon.asyncAlertMessage({ message: message, useConfirmBtn: true })) {
          return false
        } else {
          return true
        }
      }

      return true
    },
    checkCAword (str) {
      if (this.$ekmtcCommon.isEmpty(str)) {
        return
      }

      if (str.indexOf('FREE') > -1 || str.indexOf('DET') > -1 || str.indexOf('DEM') > -1) {
        this.freeTime = 'Y'
      }
    },
    fnCommonBtnAction (type) {
      this.commonBLCallback = ''
      if (type === 'PBL' || type === 'NBL') {
        this.commonBLParams = {
          action: type,
          bkgNo: this.regParams.bkgNo,
          srRno: this.regParams.srNo,
          saveFlag: this.saveFlag
        }

        this.commonBLCallback = this.setAddSrNumberInfo
      } else if (type === 'GOLIST') {
        this.commonBLParams = {
          action: type
        }
      } else if (type === 'VGMPOP_SAVE' || type === 'VGMPOP_OPEN') {
        const vgmFlag = type.split('_')[1]
        this.commonBLParams = {
          action: 'VGMPOP',
          vgmFlag: vgmFlag,
          bkgNo: this.regParams.bkgNo,
          srRno: this.regParams.srNo,
          originCntrList: this.originCntrList,
          tareModifyYn: this.cstCatCd === 'Y' || this.regParams.shprVanYn === 'Y' ? 'Y' : 'N'
        }

        if (vgmFlag === 'SAVE') {
          this.refresh()
          if (this.requestedYn === 'Y') {
            this.commonBLCallback = this.refreshBL
          } else {
            this.commonBLCallback = this.refresh
          }
        }
      } else if (type === 'ERROR_CHECK') {
        this.commonBLParams = {
          action: 'ERROR_CHECK',
          srRno: this.regParams.srNo
        }
      } else if (type === 'JPCLP') {
        this.commonBLParams = {
          action: 'JPCLP',
          srRno: this.regParams.srNo
        }
      } else if (type === 'SHAMF') {
        this.commonBLParams = {
          action: 'SHAMF',
          srRno: this.regParams.srNo,
          originCntrList: this.originCntrList,
          grsWt: this.regParams.grsWt,
          pkgQty: this.regParams.pkgQty,
          grsCbm: this.regParams.grsCbm,
          polShtmCd: this.regParams.polShtmCd,
          containerList: this.regParams.containerList,
          polCtrCd: this.regParams.polCtrCd
        }

        this.commonBLCallback = this.setSHAMFReturn
      } else if (type === 'LOG') {
        this.commonBLParams = {
          action: 'LOG',
          srRno: this.regParams.srNo,
          bkgNo: this.regParams.bkgNo
        }
      } else if (type === 'TRD_SHIPPER') {
        this.commonBLParams = {
          action: 'TRD_SHIPPER',
          srRno: this.regParams.srNo,
          bkgNo: this.regParams.bkgNo
        }

        this.commonBLCallback = this.setShipperRegInfo
      } else if (type === 'TRD_SHIPPER_FILE') {
        this.commonBLParams = {
          action: 'TRD_SHIPPER_FILE',
          srRno: this.regParams.srNo,
          trdFileInfo: this.regParams.trdFileInfo
        }
      }

      if (this.commonBLComponent === null) {
        this.commonBLComponent = 'common-bl'
      } else {
        this.$nextTick(() => {
          this.$refs.commonBL.initPageFlag()
          this.$refs.commonBL.doAction()
        })
      }
    },
    setAddSrNumberInfo (nextBlNo) {
      this.regParams.srList.push({ srNo: nextBlNo, saveFlag: 'SR_ADD' })
      const TH = this

      this.$ekmtcCommon.alertCallback(this.$t('art.CMATK367'), async () => {
        TH.regParams.srNo = nextBlNo
        TH.saveFlag = 'SR_ADD'
        await TH.refresh()
      })
    },
    isIndonesia () {
      if (this.lang === 'ENG' && this.regParams.polCtrCd === 'ID' && this.usrCtrCd === 'ID') {
        return true
      } else {
        return false
      }
    },
    isExportLicense () {
      //if (!this.isIndonesia() && this.regParams.blEdiTypCd !== 'C' && this.polCtrCd !== 'KR' && this.lang !== 'JPN') {
      if (!this.isIndonesia() && this.regParams.blEdiTypCd !== 'C' && (this.lang === 'KOR' || this.regParams.polCtrCd === 'KR')) {
        return true
      } else {
        return false
      }
    },
    async fnDlyTrmlMsgChk () {
      let isOk = true
      const dlyPlcCd = this.regParams.dlyPlcCd
      const plcNm = this.regParams.dlyPlcNm

      const params = {
        kind: 'SR',
        blNo: this.regParams.srNo,
        dlyPlcNm: this.regParams.dlyPlcNm
      }
      await trans.findTrmlCompare(params).then(async res => {
        if (res.headers.respcode === 'C0000' && this.$ekmtcCommon.isNotEmpty(res.data)) {
          if (!await this.$ekmtcCommon.asyncAlertMessage({ message: res.data, useConfirmBtn: true })) {
            isOk = false
          }
        }
      })

      return isOk
    },
    changeTopMarkDscr (e, key) {
      const val = e.target.value
      const obj = this.regParams.markDescList[0]

      if (key === 'dscr') {
        document.querySelector('#dscr_0').value = val
        obj.dscr = val
        this.initDesc()
      } else {
        document.querySelector('#mrk_0').value = val
        obj.mrk = val
      }
    },
    makeConfirmPkgErrMessage (obj) {
      let message = ''

      message += this.$t('msg.CSBL200.259') + '<br><br>'
      message += '<table class="tbl_col">'
      message += '<colgroup>'
      message += '  <col width="20%"/>'
      message += '  <col width="5%"/>'
      message += '  <col width="40%"/>'
      message += '  <col width="35%"/>'
      message += '</colgroup>'
      message += '<thead>'
      message += '  <tr>'
      message += '    <th>Kind</th>'
      message += '    <th>Q\'ty</th>'
      message += '    <th>Unit</th>'
      message += '    <th>Gross Weight</th>'
      message += '  </tr>'
      message += '</thead>'
      message += '<tbody>'
      message += '  <tr>'
      message += '  <td>Total P\'kgs</td>'
      message += '  <td>' + obj.totPkgQty + '</td>'
      message += '  <td>' + obj.totPkgNm + '</td>'
      message += '  <td>' + obj.totGrsWt + '</td>'
      message += '  </tr>'

      message += '  <td>Description P\'kgs</td>'
      if (obj.totPkgQty !== obj.pkgErrQty) {
        message += '  <td><span class="color_red">' + obj.pkgErrQty + '</span></td>'
      } else {
        message += '  <td>' + obj.pkgErrQty + '</td>'
      }

      if (obj.totPkgNm !== obj.pkgErrNm) {
        message += '  <td><span class="color_red">' + obj.pkgErrNm + '</span></td>'
      } else {
        message += '  <td>' + obj.pkgErrNm + '</td>'
      }
      message += '  <td></td>'
      message += '  </tr>'

      message += '  <td>Container P\'kgs</td>'
      message += '  <td>' + obj.totPkgQty + '</td>'
      message += '  <td></td>'
      message += '  <td>' + obj.totGrsWt + '</td>'
      message += '  </tr>'
      message += '</tbody>'
      message += '</table>'

      return message
    },
    async refresh () {
      await this.initBlBkgInfo()
      this.$ekmtcCommon.hideErrorTooltipAll(document.querySelector('#frm_sr'))
      this.setFileInfo()
    },
    refreshBL () {
      const obj = {
        type: 'BL',
        blNo: this.regParams.srNo,
        srRno: this.regParams.srNo
      }

      this.$emit('goPageType', obj)
    },
    fnPackage (actionFlag) {
      const regParams = this.regParams
      const pkgQty = regParams.pkgQty
      const pkgCd = regParams.pkgCd

      const pkgInfo = this.commonCodes['01130'].filter(vo => vo.cd === pkgCd)[0]

      let retValue = ''
      let pkgNm = ''

      if (pkgInfo !== undefined) {
        pkgNm = pkgInfo.cdNm
      }

      let flagChange = 'N'
      let cc = ''
      let dd = 0
      let jj = ''
      let kk = ''

      const dscr = this.regParams.markDescList[0].dscr
      regParams.markDescList[0].dscr = dscr.replace(this.pkgInfo, '')

      if (this.$ekmtcCommon.isNotEmpty(pkgQty) && this.$ekmtcCommon.isNotEmpty(pkgCd)) {
        retValue = this.$ekmtcCommon.changeNumberFormat(pkgQty, { isComma: true }) + pkgNm
      }

      const arrDscr = dscr.split('\n')
      arrDscr.forEach((d, i) => {
        if ((d.indexOf('X20') !== -1 || d.indexOf('X40') !== -1 || d.indexOf('X45') !== -1)) {
          cc += d + '\n'
          ++dd
        } else {
          if (parseInt(d.substring(0, 5).charAt(0)) >= 0 && parseInt(d.substring(0, 5).charAt(0)) <= 9) {
            // Package 수량 체크는 2번째 라인까지만 확인
            if (i < 3 && flagChange === 'N') {
              regParams.markDescList[0].dscr = dscr.replace(d, retValue)
              flagChange = 'Y'
              /*
              if (d.indexOf('X20') === -1 && d.indexOf('X40') === -1 && d.indexOf('X45') === -1 && d !== this.pkgInfo) {
                jj += d.replace(this.pkgInfo, '')
              } */
            } else {
              jj += d
            }
          } else {
            jj += d
          }
          if (i !== (arrDscr.length - 1)) {
            jj += '\n'
          }
        }
      })

      if (arrDscr.length === dd) {
        kk = cc + retValue
      } else {
        kk = cc + retValue + jj
      }

      regParams.markDescList[0].dscr = kk

      if (regParams.exportLicenseList.length > 0) {
        regParams.exportLicenseList.forEach(e => {
          e.pkgCd = regParams.pkgCd
        })
      }

      if (actionFlag === 'CHANGE') {
        this.pkgInfo = retValue
        this.checkValidationInfo('pkgCd')
      }
    },
    fnSumValue (type) {
      const regParams = this.regParams
      if (regParams.blEdiTypCd === 'S' && this.memberDetail.userCtrCd === 'KR') {
        regParams.exportLicenseList.forEach(e => {
          if (this.$ekmtcCommon.isEmpty(e[type]) || String(e[type]) === '0') {
            e[type] = regParams[type]
          }
        })
      }
    },
    changeSrNo (e) {
      const srNo = e.target.value
      const srListInfo = this.regParams.srList.filter(vo => vo.srNo === srNo)

      let saveFlag = ''
      if (srListInfo !== undefined && srListInfo.length > 0) {
        saveFlag = this.$ekmtcCommon.isNotEmpty(srListInfo[0].saveFlag) ? srListInfo[0].saveFlag : 'SR_ADD'
        this.saveFlag = saveFlag
      }

      this.refresh()
    },
    showUploadClass (flag) {
      return (flag === 'Y' ? 'blue ' : '') + 'button sm2'
    },
    showSHAMFButton () {
      let isVisible = false
      const regParams = this.regParams

      const arrExceptPlc = ['NCH', 'AQG', 'CZH', 'TOL', 'YCH', 'JIN', 'JJG',
                             'JZH', 'JXN', 'CHZ', 'CKG', 'CSX', 'CTU', 'CGS',
                             'HGH', 'HSI', 'JIA', 'CZX', 'LUZ', 'NKG', 'NTG',
                             'KUS', 'SZH', 'TAZ', 'TCG', 'SHA', 'WUH', 'WUX',
                             'YZH', 'WHI', 'ZHE', 'ZJG', 'MSN', 'JIU']
      if (regParams.podShaYn === 'Y' && arrExceptPlc.indexOf(regParams.polPortCd) === -1) {
        isVisible = true
      }

      return isVisible
    },
    showSaveBtn () {
      let isVisible = false
      const regParams = this.regParams

      if (this.saveFlag === 'SR_ADD') {
        return false
      }

      if (regParams.blDclrYn === 'N') {
        isVisible = true
      }

      return isVisible
    },
    showSaveBtnSubmit () {
      let isVisible = false
      const regParams = this.regParams

      if (this.saveFlag === 'SR_ADD') {
        return false
      }

      if (regParams.blDclrYn === 'N') {
        isVisible = true
      }
      // EDI 로 생성된 SR은 제출 못하게...
      if (this.usrCtrCd === 'KR') {
        if (this.$ekmtcCommon.isEmpty(regParams.webUsid) && regParams.dtSeq !== '99999') {
          return false
        }
      }

      return isVisible
    },
    showButton () {
      let isVisible = false
      const regParams = this.regParams
      if (this.saveFlag === 'SR_EDIT' && regParams.blDclrYn === 'N' && regParams.blCntCfs === 'N') {
        isVisible = true
      }

      return isVisible
    },
    fnIranUploadPop (type) {
      this.popParams = {
        reqRno: this.regParams.bkgNo,
        count: 0,
        type: type
      }

      this.selectFunc = ''
      this.openPopup('iran-upload-pop')
    },
    setSHAMFReturn () {
      this.regParams.shaEmanYn = 'Y'
    },
    checkShpperLOI () {
      const regParams = this.regParams

      if (this.$ekmtcCommon.isNotEmpty(regParams.shprCstAddr)) {
        const ctrInfo = this.ctrCdList.filter(vo => vo.ctrCd === regParams.polCtrCd)[0]

        if (ctrInfo !== undefined) {
          const ctrNm = ctrInfo.ctrEnm.toUpperCase().replace(/ /g, '')
          const shprCstAddr = regParams.shprCstAddr.replace(/ /g, '')
          if (shprCstAddr.indexOf(ctrNm) === -1) {
            this.isShipperLOI = true
          } else {
            this.isShipperLOI = false
          }
        }
      } else {
        this.isShipperLOI = true
      }
    },
    async fnAttachPop () {
      await this.initAttachPopupData()

      this.popParams = {
        blNo: this.regParams.srNo,
        type: 'SR',
        podCtrCd: this.regParams.podCtrCd,
        uploadFileList: this.uploadFileList
      }

      this.selectFunc = this.setFileInfo
      this.openPopup('bl-attach-pop')
    },
    setFileInfo () {
      this.initAttachPopupData()
    },
    changeBlEdiTypCd (e) {
      const ediTypCd = e.target.value

      if (ediTypCd === 'C') {
        this.$set(this.regParams, 'exportLicenseList', [])
        this.addExportLicense()
      }
    },
    setShipperRegInfo (vo) {
      if (this.$ekmtcCommon.isNotEmpty(vo.reqRno)) {
        this.saveShipperLOI = 'Y'
        this.regParams.trdFileInfo = {} // 조회된 파일정보 Clear (재확인 하기 위해)
      }
    },
    print () {
      if (this.saveFlag !== 'SR_EDIT') {
        this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.245'))
        return
      }

      this.popParams = {
        items: [{
          blNo: this.regParams.srNo,
          type: 'SR'
        }]
      }

      this.selectFunc = ''
      this.openPopup('bl-copy-itext-pop')
    },
    templateSavePop () {
      this.popParams = {
        tplRno: this.regParams.srNo,
        pol: this.regParams.polPortCd,
        pod: this.regParams.podPortCd,
        tplCatCd: '02'
      }

      this.selectFunc = this.goTemplateList
      this.openPopup('template-save-pop')
    },
    checkErrorVisible (id) {
      const frm = document.querySelector('#frm_sr')
      const elem = frm.querySelector('#' + id)

      const parentElem = $(elem).parents('td, div').eq(0)
      const msgBox = parentElem.find('.tooltip_essen_box').eq(0)

      if (msgBox.length > 0 && msgBox.is(':visible') && this.$ekmtcCommon.isNotEmpty(elem.value)) {
        this.$ekmtcCommon.hideErrorTooltip(elem)
      }
    },
    changeStateCd (id, nmField) {
      const elem = document.querySelector('#frm_sr').querySelector('#' + id)
      let stateTxt = (elem.selectedOptions.length > 0 ? elem.selectedOptions[0].text : '')
      this.$set(this.regParams, nmField, stateTxt)
    },
    changeWstCgoYn (e) {
      let isOk = true
      const val = this.regParams.podHsCd
      if (!e.target.checked) {
        this.regParams.imLicNo = ''
        this.regParams.imBnkGurNo = ''

        if (val.length !== 4 && val.length !== 6 && val.length !== 8) {
          isOk = false
        }
      } else {
        if (val.length !== 8) {
          isOk = false
        }
      }

      if (!isOk) {
        this.$nextTick(() => {
          this.hsChkParams.hscdPodErrorMessage = this.$t('msg.CSBL200.175')
        })
        this.hsChkParams.podSuccYn = 'N'
      } else {
        this.$nextTick(() => {
          this.hsChkParams.hscdPodErrorMessage = ''
        })
        this.hsChkParams.podSuccYn = 'Y'
      }
    },
    showMessage () {
      if (this.$ekmtcCommon.isNotEmpty(this.showMsg)) {
        this.$ekmtcCommon.alertDefault(this.showMsg)
      }
    },
    async fnAfterAdd (srNo) { // sr 등록 후 탭 활성화
      await this.$emit('after', srNo)
    },
    changeBlTypCd (e) {
      let remark = this.$ekmtcCommon.isNotEmpty(this.regParams.rmk) ? this.regParams.rmk : ''
      let separator = remark !== '' ? '\n' : ''
      if (e.target.value === '02') {
        remark += separator + this.$t('msg.CSBL200.266')
      } else {
        remark = remark.replace(this.$t('msg.CSBL200.266'), '').trim()
      }

      this.regParams.rmk = remark
    },
    async fnRtnCHRCheck (blPhbtList, obj) {
      let value = this.$ekmtcCommon.isEmpty(obj) ? '' : obj

      const cntrSzArr = []
      const cntrTypArr = []
      const cntrCgoArr = []

      const confirmMsg = []

      this.regParams.containerList.forEach(c => {
        cntrSzArr.push(c.cntrSzCd)
        cntrTypArr.push(c.cntrTypCd)
        cntrCgoArr.push(c.cgoTypCd)
      })

      let message = ''
      let message2 = ''
      for (let i = 0; i < blPhbtList.length; i++) {
        const chr = blPhbtList[i]
        if (chr.apclYn === 'Y' && chr.srCmplsSbmtYn === 'N') {
          if (value.indexOf(chr.phbtChr) > -1) {
            if (this.$ekmtcCommon.isNotEmpty(chr.cntrSzCd) &&
                    this.$ekmtcCommon.isEmpty(chr.cntrTypCd) &&
                    this.$ekmtcCommon.isEmpty(chr.cgoTypCd)) {
              if (value.indexOf(chr.cntrSzCd) > -1) {
                message = chr.rmk
                break
              }
            } else if (this.$ekmtcCommon.isNotEmpty(chr.cntrSzCd) &&
                    this.$ekmtcCommon.isNotEmpty(chr.cntrTypCd) &&
                    this.$ekmtcCommon.isEmpty(chr.cgoTypCd)) {
              if (cntrSzArr.indexOf(chr.cntrSzCd) > -1 && cntrTypArr.indexOf(chr.cntrTypCd) > -1) {
                message = chr.rmk
                break
              }
            } else if (this.$ekmtcCommon.isNotEmpty(chr.cntrSzCd) &&
                      this.$ekmtcCommon.isNotEmpty(chr.cntrTypCd) &&
                      this.$ekmtcCommon.isNotEmpty(chr.cgoTypCd)) {
              if (cntrSzArr.indexOf(chr.cntrSzCd) > -1 && cntrTypArr.indexOf(chr.cntrTypCd) > -1 && cntrCgoArr.indexOf(chr.cgoTypCd) > -1) {
                message = chr.rmk
                break
              }
            } else if (this.$ekmtcCommon.isEmpty(chr.cntrSzCd) &&
                      this.$ekmtcCommon.isNotEmpty(chr.cntrTypCd) &&
                      this.$ekmtcCommon.isEmpty(chr.cgoTypCd)) {
              if (cntrTypArr.indexOf(chr.cntrTypCd) > -1) {
                message = chr.rmk
                break
              }
            } else if (this.$ekmtcCommon.isEmpty(chr.cntrSzCd) &&
                      this.$ekmtcCommon.isNotEmpty(chr.cntrTypCd) &&
                      this.$ekmtcCommon.isNotEmpty(chr.cgoTypCd)) {
              if (cntrTypArr.indexOf(chr.cntrTypCd) > -1 && cntrCgoArr.indexOf(chr.cgoTypCd) > -1) {
                message = chr.rmk
                break
              }
            } else if (this.$ekmtcCommon.isEmpty(chr.cntrSzCd) &&
                      this.$ekmtcCommon.isEmpty(chr.cntrTypCd) &&
                      this.$ekmtcCommon.isNotEmpty(chr.cgoTypCd)) {
              if (cntrCgoArr.indexOf(chr.cgoTypCd) > -1) {
                message = chr.rmk
                break
              }
            } else if (this.$ekmtcCommon.isNotEmpty(chr.cntrSzCd) &&
                      this.$ekmtcCommon.isEmpty(chr.cntrTypCd) &&
                      this.$ekmtcCommon.isNotEmpty(chr.cgoTypCd)) {
              if (cntrTypArr.indexOf(chr.cntrTypCd) > -1) {
                message = chr.rmk
                break
              }
            } else {
              message = chr.rmk
              break
            }
          }
        } else if (chr.apclYn === 'A' && chr.srCmplsSbmtYn === 'N') {
          message2 = ''
          if (value.indexOf(chr.phbtChr) > -1) {
            if (this.$ekmtcCommon.isNotEmpty(chr.cntrSzCd) &&
                    this.$ekmtcCommon.isEmpty(chr.cntrTypCd) &&
                    this.$ekmtcCommon.isEmpty(chr.cgoTypCd)) {
              if (value.indexOf(chr.cntrSzCd) > -1) {
                message2 = chr.rmk
              }
            } else if (this.$ekmtcCommon.isNotEmpty(chr.cntrSzCd) &&
                    this.$ekmtcCommon.isNotEmpty(chr.cntrTypCd) &&
                    this.$ekmtcCommon.isEmpty(chr.cgoTypCd)) {
              if (cntrSzArr.indexOf(chr.cntrSzCd) > -1 && cntrTypArr.indexOf(chr.cntrTypCd) > -1) {
                message2 = chr.rmk
              }
            } else if (this.$ekmtcCommon.isNotEmpty(chr.cntrSzCd) &&
                      this.$ekmtcCommon.isNotEmpty(chr.cntrTypCd) &&
                      this.$ekmtcCommon.isNotEmpty(chr.cgoTypCd)) {
              if (cntrSzArr.indexOf(chr.cntrSzCd) > -1 && cntrTypArr.indexOf(chr.cntrTypCd) > -1 && cntrCgoArr.indexOf(chr.cgoTypCd) > -1) {
                message2 = chr.rmk
              }
            } else if (this.$ekmtcCommon.isEmpty(chr.cntrSzCd) &&
                      this.$ekmtcCommon.isNotEmpty(chr.cntrTypCd) &&
                      this.$ekmtcCommon.isEmpty(chr.cgoTypCd)) {
              if (cntrTypArr.indexOf(chr.cntrTypCd) > -1) {
                message2 = chr.rmk
              }
            } else if (this.$ekmtcCommon.isEmpty(chr.cntrSzCd) &&
                      this.$ekmtcCommon.isNotEmpty(chr.cntrTypCd) &&
                      this.$ekmtcCommon.isNotEmpty(chr.cgoTypCd)) {
              if (cntrTypArr.indexOf(chr.cntrTypCd) > -1 && cntrCgoArr.indexOf(chr.cgoTypCd) > -1) {
                message2 = chr.rmk
              }
            } else if (this.$ekmtcCommon.isEmpty(chr.cntrSzCd) &&
                      this.$ekmtcCommon.isEmpty(chr.cntrTypCd) &&
                      this.$ekmtcCommon.isNotEmpty(chr.cgoTypCd)) {
              if (cntrCgoArr.indexOf(chr.cgoTypCd) > -1) {
                message2 = chr.rmk
              }
            } else if (this.$ekmtcCommon.isNotEmpty(chr.cntrSzCd) &&
                      this.$ekmtcCommon.isEmpty(chr.cntrTypCd) &&
                      this.$ekmtcCommon.isNotEmpty(chr.cgoTypCd)) {
              if (cntrTypArr.indexOf(chr.cntrTypCd) > -1) {
                message2 = chr.rmk
              }
            } else {
              message2 = chr.rmk
            }

            if (this.phbtAlertMsg.indexOf(message2) === -1) {
              confirmMsg.push(message2)
            }

            this.phbtAlertMsg.push(chr.rmk)
          }
        }
      }

      if (this.$ekmtcCommon.isNotEmpty(message)) {
        if (await this.$ekmtcCommon.asyncAlertMessage({ message: message })) {
          return true
        }
      } else {
        let isOk = false
        for (const msg of confirmMsg) {
          if (!await this.$ekmtcCommon.asyncAlertMessage({ message: msg, useConfirmBtn: true })) {
            isOk = true
          }
        }

        return isOk
      }

      return false
    },
    setInputType () {
      if (this.$ekmtcCommon.isNotEmpty(this.regParams.porPlcCd)) {
        const porPlcList = this.plcEnmCodes[this.regParams.polPortCd] ? this.plcEnmCodes[this.regParams.porPlcCd].filter(vo => vo.plcEnm === this.regParams.porPlcNm) : ''
        if (porPlcList === undefined || porPlcList.length === 0) {
          this.porPlcNmType = 'input'
        }
      }

      if (this.$ekmtcCommon.isNotEmpty(this.regParams.polPortCd)) {
        const polPortList = this.plcEnmCodes[this.regParams.polPortCd] ? this.plcEnmCodes[this.regParams.polPortCd].filter(vo => vo.plcEnm === this.regParams.polPortNm) : ''
        if (polPortList === undefined || polPortList.length === 0) {
          this.polPortNmType = 'input'
        }
      }

      if (this.$ekmtcCommon.isNotEmpty(this.regParams.podPortCd)) {
        const podPortList = this.plcEnmCodes[this.regParams.podPortCd] ? this.plcEnmCodes[this.regParams.podPortCd].filter(vo => vo.plcEnm === this.regParams.podPortNm) : ''
        if (podPortList === undefined || podPortList.length === 0) {
          this.podPortNmType = 'input'
        }
      }

      if (this.$ekmtcCommon.isNotEmpty(this.regParams.dlyPlcCd)) {
        const dlyPlcList = this.plcEnmCodes[this.regParams.dlyPlcCd] ? this.plcEnmCodes[this.regParams.dlyPlcCd].filter(vo => vo.plcEnm === this.regParams.dlyPlcNm) : ''
        if (dlyPlcList === undefined || dlyPlcList.length === 0) {
          this.dlyPlcNmType = 'input'
        }
      }
    },
    checkValidationEmailAll () {
      let isOk = true
      const arrEl = ['actShprCstEmlAddr', 'cneCstEmlAddr', 'ntifCstEmlAddr', 'iecPicEmlAddr']

      arrEl.forEach(vo => {
        if (!this.checkValidationEmail(vo)) {
          isOk = false
        }
      })

      return isOk
    },
    checkValidationEmail (id) {
      const frm = document.querySelector('#frm_sr')
      const selector = frm.querySelector('#' + id)

      if (selector === null) {
        return true
      }

      let isOk = true
      if (selector !== undefined && selector !== null && this.$ekmtcCommon.isNotEmpty(selector.value)) {
        if (selector.value.indexOf(',') > -1) {
          if (!this.$ekmtcCommon.multiCheckEmail(selector.value, ',')) {
            isOk = false
          }
        } else {
          if (!this.$ekmtcCommon.checkEmail(selector.value)) {
            isOk = false
          }
        }
      }

      if (!isOk) {
        this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.VOSD100.043')) // 이메일 체크
      }

      return isOk
    },
    fnChangeTerm () {
      this.initDesc()
    },
    fnChangeCntrTerm (key) {
      let cyCount = 0
      let cfsCount = 0
      let cyNcfsCount = 0
      let count = 0
      let totCount = 0

      this.regParams.containerList.forEach(c => {
        if (c[key] === '01') {
          cyCount++
        } else if (c[key] === '02') {
          cfsCount++
        } else if (c[key] === '03') {
          cyNcfsCount++
        }
        count++
      })

      if (cyNcfsCount > 0) {
        this.regParams[key] = '03'
        this.initDesc()
        return
      }

      totCount = cyCount + cfsCount
      if (count === totCount) {
        if (cyCount === count) {
          this.regParams[key] = '01'
          this.initDesc()
        } else if (cfsCount === count) {
          this.regParams[key] = '02'
          this.initDesc()
        } else if (totCount === count) {
          this.regParams[key] = '03'
          this.initDesc()
        }
      }
    },
    isPolShtmListChange () {
      let change = false

      if (this.regParams.polShtmCd === '03' && this.auth.userId !== 'CKLINE') {
        change = true
      }

      return change
    },
    compareCheck () {
      let isChange = false
      const res = this.$ekmtcCommon.compareObject(this.regParams, this.originParams)

      if (res.length > 0) {
        isChange = true
      }

      return isChange
    },
    markDescEnterSet () {
      this.regParams.markDescList.forEach(m => {
        const mrk = this.$ekmtcCommon.isNotEmpty(m.mrk) ? m.mrk : ''
        const dscr = this.$ekmtcCommon.isNotEmpty(m.dscr) ? m.dscr : ''
        m.mrk = mrk.split('\r\n').join('\n')
        m.dscr = dscr.split('\r\n').join('\n')

        m.mrk = m.mrk.replace(/\r/g, '')
        m.dscr = m.dscr.replace(/\r/g, '')
      })
    },
    async initAttachPopupData () {
      this.uploadFileList = []

      const arrPromise = [
        this.getBLAttachList()
      ]

      if (this.regParams.podCtrCd === 'IR') {
        arrPromise.push(this.getIranUploadList())
      }

      await Promise.all(arrPromise)
    },
    // 파일 조회
    async getBLAttachList () {
      const params = {
        blNo: this.regParams.srNo,
        type: 'SR'
      }

      await blAttach.getBLAttachList(params).then(response => {
        if (response.headers.respcode === 'C0000') {
          let fileList = response.data.blAttachList
          // console.log(fileList)
            this.regParams.riderYn = 'N'
            this.regParams.dgcertiYn = 'N'
            this.regParams.vgmcertiYn = 'N'
            this.regParams.emanYn = 'N'
            this.regParams.etcYn = 'N'
          if (fileList !== undefined) {
            fileList.forEach(file => {
              this.uploadFileList.push(
                {
                  guid: file.blRecvDocKndCd + '_' + file.fileSeq,
                  originalName: file.fileNm,
                  size: file.fileSz,
                  fileId: 'http://trans.ekmtc:9000/trans/trans/file-download?blNo=' + this.regParams.srNo + '&blRecvDocKndCd=' + file.blRecvDocKndCd + '&fileSeq=' + file.fileSeq + '&type=BL_ATTACH',
                  category: file.blRecvDocKndCd,

                  blNo: this.regParams.srNo,
                  blRecvDocKndCd: file.blRecvDocKndCd,
                  fileSeq: file.fileSeq
                }
              )
              if (file.blRecvDocKndCd === '03') {
                this.regParams.riderYn = 'Y'
              } else if (file.blRecvDocKndCd === '06') {
                this.regParams.dgcertiYn = 'Y'
              } else if (file.blRecvDocKndCd === '20') {
                this.regParams.vgmcertiYn = 'Y'
              } else if (file.blRecvDocKndCd === '02') {
                this.regParams.emanYn = 'Y'
              } else if (file.blRecvDocKndCd === '04') {
                //code collection CS046 ETC가 04로 되어 있음
                this.regParams.etcYn = 'Y'
              }
            })
          }
        }
      })
    },
    // 이란 업로드 파일 조회
    async getIranUploadList () {
      const params = {
        reqRno: this.regParams.srNo
      }

      await trans.getIranUpload(params).then(response => {
        if (response.headers.respcode === 'C0000') {
          let fileList = response.data.iranUploadList

          if (fileList !== undefined) {
            fileList.forEach(file => {
              this.uploadFileList.push(
                {
                  guid: file.bkgNo + '_' + file.fileSeq,
                  originalName: file.fileNm,
                  size: file.fileSz,
                  fileId: 'http://trans.ekmtc:9000/trans/trans/file-download?reqRno=' + file.bkgNo + '&atchFileSeq=' + file.fileSeq + '&fileTypCd=' + file.fileTypCd + '&type=IRAN_UPLOAD',
                  category: 'iran_' + file.fileTypCd,

                  bkgNo: file.bkgNo,
                  fileSeq: file.fileSeq,
                  fileTypCd: file.fileTypCd
                }
              )
            })
          }
        }
      })
    },
    excelUploadPop () {
      const checkCntr = this.cstCatCd === 'Y' || this.regParams.shprVanYn === 'Y' ? 'N' : 'Y'
      this.popParams = {
        blNo: this.regParams.srNo,
        bkgNo: this.regParams.bkgNo,
        checkCntr: checkCntr,
        polPortCd: this.regParams.polPortCd,
        type: 'SR'

      }

      this.selectFunc = this.setUploadContainerList
      this.openPopup('container-excel-upload-pop')
    },
    setUploadContainerList (list) {
      let defObj = this.getAddBLContainerDefaultValue() // 신규 컨테이너의 초기값
      let cntrList = list.map(c => {
        return { ...defObj, ...c }
      })
      this.$set(this.regParams, 'containerList', cntrList)

      this.regParams.containerList.forEach(c => {
        if (this.$ekmtcCommon.isEmpty(c.sealList) || c.sealList.length === 0) {
          this.$set(c, 'sealList', [{ sealNo: '' }])
        }
      })

      this.fnChangeContSz() // Description 생성
    },
    handleChange (e) {
      clearTimeout(this.timer)

      this.timer = setTimeout(() => {
        this.regParams.rmk = e.target.value
      }, 300)
    },
    goTemplateList () {
     trans.getTemplateList(this.regParams).then((res) => {
        this.templateList = res.data
     })
    },
    deleteTemplate () {
      if (this.templateCd === 'B' && this.$ekmtcCommon.isNotEmpty(this.templateNo_2)) {
        this.$ekmtcCommon.confirmCallBack(this.$t('msg.CMBA660.013'), () => {
          this.regParams.tplRno = this.templateNo_2
          this.regParams.tplCatCd = '02'
          this.templateNo_2 = ''

          trans.deleteeTemplate(this.regParams).then(res => {
            if (res.data === 'SUCC') {
                this.$ekmtcCommon.alertDefault(this.$t('tem.CMAT012'))
                trans.getTemplateList(this.regParams).then((res) => {
                  this.templateList = res.data
                })
              } else {
                this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.078'))
              }
          }).catch(err => {
            console.log(err)
          })
        })
      } else {
        this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.078'))
      }
    },
    async saveSgBL (saveType) {
      if ((saveType === 'DCLR' || saveType === 'SAVE_THEN') && this.regParams.polCtrCd === 'SG') {
        const rsltWt = await this.checkCntrGrossWeightAndPackage('wt')
        if (rsltWt === -1) {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.097'))
          return
        } else if (rsltWt === -2) {
          this.$ekmtcCommon.alertDefault(this.$t('msg.CSBL200.098'))
          return
        }
        const vgmWt = await this.getVgmGrsWtChk()
        console.log('@@@@@@@@@@@@@@@vgmWt=>', vgmWt)
        if (this.$ekmtcCommon.isNotEmpty(vgmWt)) {
          await this.fnVGNNoticePop(vgmWt, saveType)
        } else {
          this.saveBL(saveType)
        }
      }
    },
    async getVgmGrsWtChk () {
      let result = ''

      const params = {
        flag: 'SR',
        blNo: this.$ekmtcCommon.isEmpty(this.regParams.blNo) ? this.regParams.srNo : this.regParams.blNo,
        bkgNo: this.regParams.bkgNo,
        grsWt: this.regParams.grsWt
      }
      const regParams = this.regParams
      await trans.findVgmGrsWtChk(regParams).then(res => {
        if (res.headers.respcode === 'C0000') {
          result = res.data
        }
      }).catch(() => {
        result = ''
      })

      return result
    },
    fnVGNNoticePop (vgmWt, saveType) {
      let popParams = {
        vgmWt: vgmWt,
        saveType: saveType
      }
      this.popParams = popParams
      this.selectFunc = this.callBackSgNotice
      this.customComponent = 'bl-vgm-noti-pop'
      this.$ekmtcCommon.layerOpen('.bl_area > .popup_dim')
    },
    callBackSgNotice (data) {
      this.closePopup()
      this.saveBL(data.saveType)
    },
    checkJPplcPopMsg (value, id) {
      const originParams = this.originParams
      const regParams = this.regParams
      const msg = 'POR/POL/POD/DLYは、BOOKING 時のPORTと異なるPORTの記載をすることはできません。異なるPORTを記載する場合は必ずBOOKING 内容の変更をお願いします。貨物はBOOKING 時に登録されているPORTにのみ手配が可能です。'
      let plcList = []
      let commPortCd = ''

      if (regParams.polCtrCd === 'JP') {
        switch (id) {
          case 'porPlcNm':
            commPortCd = regParams.porPlcCd
            break
          case 'polPortNm':
            commPortCd = regParams.polPortCd
            break
          case 'podPortNm':
            commPortCd = regParams.podPortCd
            break
          case 'dlyPlcNm':
            commPortCd = regParams.dlyPlcCd
            break
        }

        plcList = this.plcEnmCodes[commPortCd].map(item => {
          return item.plcEnm
        })

        if (!plcList.includes(value)) {
          this.$ekmtcCommon.alertDefault(msg)
        }
      }
    },
    addRequSentenceDescForVN () {
      let arr = [
        'SHIPPING LINE / SHIPPING LINE AGENTS ARE ELIG',
        'IBLE UNDER THE TERMS OF THIS B/L AS AGREED, T',
        'O COLLECT ANY CHARGES SUCH AS  DEPOSIT SAR 30',
        '00 PER DRY CNTR, SAR 7000 PER REEFER AND ANY ',
        'OTHER CHARGES OR FEES WHERE DEEMED NECESSARY ',
        'BY THE SHIPPING LINE / SHIPPING LINE AGENTS.L',
        'OLO(FASAH): SAR 50/CNTR, GATE PASS & HANDLING',
        ': SAR 120/CNTR, OCR: USD80/120 PER 20\'/40\'(NO',
        'N HAZ SHIPMENTS), USD 100/150 PER 20\'/40\'(HAZ',
        ' SHIPMENTS)'
      ]

      let isOk = true
      for (let desc of this.regParams.markDescList) {
        if (isOk) {
          if (desc.dscr.indexOf(arr[0]) > -1 && desc.dscr.indexOf(arr[1]) > -1 &&
            desc.dscr.indexOf(arr[2]) > -1 && desc.dscr.indexOf(arr[3]) > -1 &&
            desc.dscr.indexOf(arr[4]) > -1 && desc.dscr.indexOf(arr[5]) > -1 &&
            desc.dscr.indexOf(arr[6]) > -1 && desc.dscr.indexOf(arr[7]) > -1 &&
            desc.dscr.indexOf(arr[8]) > -1 && desc.dscr.indexOf(arr[9]) > -1) {
            isOk = false
          }
        } else {
          break
        }
      }

      if (isOk) {
        let lstDesc = this.regParams.markDescList[this.regParams.markDescList.length - 1]
        let lstDescArr = lstDesc.dscr.split('\n')
        if (lstDescArr.length > 5) {
          this.addMarkDesc()
          lstDesc = this.regParams.markDescList[this.regParams.markDescList.length - 1]
        }

        lstDesc.dscr = lstDesc.dscr + '\n' + arr.join('\n')
      } else {
        return false
      }
    },
    addTempClauseDescForVN () {
      //SCARGO IS STOWED IN REFRIGERATED CONTAINERS S
      //ET AT THE SHIPPER'S REQUESTED CARRYING TEMPER
      //ATURE OF … DEGREES OF CELSIUS.
      let arr = [
        'CARGO IS STOWED IN REFRIGERATED CONTAINERS ',
        'SET AT THE SHIPPER\'S REQUESTED CARRYING ',
        'TEMPERATURE OF … DEGREES OF CELSIUS.'
      ]

      let isOk = true
      for (let desc of this.regParams.markDescList) {
        if (isOk) {
          if (desc.dscr.indexOf(arr[0]) > -1 && desc.dscr.indexOf(arr[1]) > -1 &&
            desc.dscr.indexOf(arr[2]) > -1) {
            isOk = false
          }
        } else {
          break
        }
      }

      if (isOk) {
        let lstDesc = this.regParams.markDescList[this.regParams.markDescList.length - 1]
        let lstDescArr = lstDesc.dscr.split('\n')
        if (lstDescArr.length > 12) {
          this.addMarkDesc()
          lstDesc = this.regParams.markDescList[this.regParams.markDescList.length - 1]
        }

        lstDesc.dscr = lstDesc.dscr + '\n' + arr.join('\n')
      } else {
        return false
      }
    },
    setIndiaInfo () {
      if (this.regParams.polCtrCd === 'VN' && this.regParams.podCtrCd === 'IN') {
        let descStatic = ''
        const regParams = this.regParams

        let indiaInfoTxt = `\n----\nIEC NO. : ${regParams.iecNo || ''}\nPAN NO. : ${regParams.ntifPanNo || ''}\nGST NO. : ${regParams.gstNo || ''}\nHS CODE : ${regParams.podHsCd || ''}\n----`

        for (let desc of this.regParams.markDescList) {
          let dscr = desc.dscr
          let start = dscr.indexOf('\n----')
          let end = dscr.lastIndexOf('----')
          if (start > -1 && end > -1) {
            let sliceTxt = dscr.slice(start, end + 4)
            dscr = dscr.replaceAll(sliceTxt, '')

            desc.dscr = dscr
            break
          }
        }

        let lstDesc = this.regParams.markDescList[this.regParams.markDescList.length - 1]
        let lstDescArr = lstDesc.dscr.split('\n')
        if (lstDescArr.length > 10) {
          this.addMarkDesc()
          lstDesc = this.regParams.markDescList[this.regParams.markDescList.length - 1]
        }

        lstDesc.dscr = lstDesc.dscr + indiaInfoTxt
      }
    },
    checkValidationCntrAll () {
      let isOk = true
      let arrEl = ['cntrNo']
      const regParams = this.regParams

      if (this.saveType !== 'SAVE' && this.saveType !== 'UPD') {
        arrEl = ['cntrNo', 'cntrSzCd', 'cntrTypCd', 'cgoTypCd']
      }

      arrEl.forEach(el => {
        regParams.containerList.forEach((c, idx) => {
          if (!this.checkValidationCntr(el, idx)) {
            isOk = false
          }
        })
      })

      return isOk
    },
    checkValidationCntr (flag, idx) {
      let isOk = true
      const regParams = this.regParams
      const frm = document.querySelector('#frm_sr')
      const selector = frm.querySelector('#' + flag + '_' + idx)

      const cntrInfo = regParams.containerList[idx]
      const cgoTypList = regParams.cgoTypList

      this.$ekmtcCommon.hideErrorTooltip(selector)
      if (flag === 'cntrNo') {
        isOk = this.checkValidationCntrNo(idx)
      } else {
        if (regParams.polShtmCd === '02' && this.$ekmtcCommon.isEmpty(cntrInfo.cntrNo)) {
          return true
        }

        if (this.$ekmtcCommon.isNotEmpty(cntrInfo.cntrNo)) {
          if (flag === 'cntrSzCd' && this.$ekmtcCommon.isEmpty(selector.value)) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
            isOk = false
          } else if (flag === 'cntrTypCd' && this.$ekmtcCommon.isEmpty(selector.value)) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
            isOk = false
          }

          if (flag === 'cgoTypCd' && this.memberDetail.userCtrCd === 'CN') {
            cgoTypList.forEach((cgoVo, idx) => {
              if (cgoVo.cgoTypCd === '01' || cgoVo.cgoTypCd === '06') { // 부킹에서 입력한 컨테이너 cgotypecd 가 HZ 거나 FB 일때
                if (this.$ekmtcCommon.isEmpty(cntrInfo.cgoTypCd)) {
                  this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
                  isOk = false
                }
              }
            })
          }
        }
      }

      return isOk
    },
    shprVanValidation (vo) {
      console.log('shprVanValidation @@@@@ ')

      let isOk = true
      const TH = this
      const regParams = TH.regParams
      const cgoTypList = regParams.cgoTypList
      const containerList = regParams.containerList
      let checkList = []

      if (this.$ekmtcCommon.isNotEmpty(vo)) {
        cgoTypList.forEach((cgoVo, idx) => {
          if (cgoVo.cntrSzCd + cgoVo.cntrTypCd === vo.cntrSzCd + vo.cntrTypCd) {
            if (cgoVo.shprVanYn !== vo.shprVanYn) {
              isOk = false
            }
          }
        })

        if (!isOk) {
          this.$ekmtcCommon.alertDefault('BookingのShipper’s Van情報と相違があります。まずはBooking情報を確認してください')
        }
      } else {
        containerList.forEach(cvo => {
          cgoTypList.forEach((cgoVo, idx) => {
              if (cgoVo.cntrSzCd + cgoVo.cntrTypCd === cvo.cntrSzCd + cvo.cntrTypCd) {
                if (cgoVo.shprVanYn !== cvo.shprVanYn) {
                  isOk = false
                }
              }
          })
        })
      }

      return isOk
    },
    changeLstDestPlcNm () {
      let isCgoTypeO1 = false
      if (this.regParams.cgoTypList.length > 0) {
        this.regParams.cgoTypList.forEach(c => {
          if (c.cgoTypCd === '01') { isCgoTypeO1 = true }
        })

        if (isCgoTypeO1 && this.regParams.podPortCd === 'SGN' && (this.regParams.dlyPlcCd === 'TNT' || this.regParams.dlyPlcCd === 'TCP')) {
          this.regParams.lstDestPlcNm = this.regParams.dlyPlcNm
        }
      }
    }
  }
}

</script>
<style scoped>
  .div_tooltip {width:230px !important; right:0; top:24px;}
  .tooltip_wrap {width:150px; right:0; top:24px; display:none;}
  .tooltip_wrap .cont {padding: 20px;}
  .txt_desc {background: inherit; position: relative;}
  .txt_desc:before {content:'※'; position: absolute; top: 0; left: 0;}
  .minus {background-position: -50px -50px;}
  .div_seal+.div_seal {margin-top: 5px;}
  .tbl_mark td{vertical-align: top;}
  .txt_desc1 {min-height: inherit; height: 45px; overflow: hidden; font-family: 'Courier New'; padding-top: 2px;}
  .txt_underline {font-weight: 500; text-decoration: underline; color: inherit; cursor: pointer;}
  .td_noline {border-left: none; border-bottom: none;}
  .tbl_btm_none {border-bottom: none !important;}
  .line_bottom {border-bottom: 1px solid #e1e3eb;}
  .no_cursor {cursor: default;}
  .no_cursor:hover { color:#5e75bf !important; border: solid 1px #a2aed2 !important;}
  .deck_select {
    border: solid 0px #cecece !important;
    color: black;
    background: white !important;
    padding: 0 0 0 0;
  }
</style>
